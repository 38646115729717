import ControlImg from './assets/ControlImg.png';
import GoalsImg from './assets/GoalsImg.png';
import InsightImg from './assets/InsightImg.png';
import CardHeader from './CardHeader';

const GoalsAndPerformance = () => {
  return (
    <section className="lg:pr-[108px] pr-4 lg:py-20 py-14 flex flex-col lg:gap-20 gap-10">
      <div className="flex w-full justify-between lg:gap-14 gap-6 items-center lg:flex-row flex-col">
        <div className="lg:w-[40%] w-full flex flex-col gap-4">
          <CardHeader
            title="Track and achieve your performance goals"
            header="EMPLOYEE MANAGEMENT"
            color="black"
          />
          <p className=" text-tertiary-text text-lg-text font-normal w-[90%]">
            Employees can easily fill timesheets with intuitive UX and Managers
            will be alert for approval
          </p>
        </div>
        <div className="lg:w-[60%] w-full lg:pr-20">
          <img src={GoalsImg} alt="Goals img" />{' '}
        </div>
      </div>
      <div className="flex justify-between lg:gap-14 gap-6 flex-col lg:flex-row">
        <div className="lg:w-[60%] w-full">
          <img src={InsightImg} alt="InsightImg" className=" rounded-lg" />
        </div>
        <div className="lg:w-[32%] w-full">
          <img src={ControlImg} alt="ControlImg" className=" rounded-lg" />
        </div>
      </div>
    </section>
  );
};

export default GoalsAndPerformance;
