import { CommonLayout } from '../components/Layouts/CommonLayout';
import ProjectManagementProduct from '../components/Products/ProjectManagementProduct';

const ProjectManagement = () => {
  return (
    <CommonLayout>
      <ProjectManagementProduct />
    </CommonLayout>
  );
};

export default ProjectManagement;
