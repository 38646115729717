import { CommonLayout } from '../components/Layouts/CommonLayout';
import FinanaceManagementProduct from '../components/Products/FinanaceManagementProduct';

const FinancialManagement = () => {
  return (
    <CommonLayout>
      <FinanaceManagementProduct />
    </CommonLayout>
  );
};

export default FinancialManagement;
