const SkillsSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M2.73145 6.09212C2.73145 4.57333 3.96266 3.34212 5.48145 3.34212H19.9814C21.5002 3.34212 22.7314 4.57333 22.7314 6.09212V15.5921C22.7314 17.1109 21.5002 18.3421 19.9814 18.3421H10.7314V17.3426C10.8514 17.1829 10.9621 17.0158 11.0625 16.8421H19.9814C20.6718 16.8421 21.2314 16.2825 21.2314 15.5921V6.09212C21.2314 5.40176 20.6718 4.84212 19.9814 4.84212H5.48145C4.79109 4.84212 4.23145 5.40176 4.23145 6.09212V10.011C3.64608 10.3496 3.13574 10.8035 2.73145 11.3416V6.09212ZM7.48145 7.34212C7.06723 7.34212 6.73145 7.6779 6.73145 8.09212C6.73145 8.50633 7.06723 8.84212 7.48145 8.84212H17.9814C18.3957 8.84212 18.7314 8.50633 18.7314 8.09212C18.7314 7.6779 18.3957 7.34212 17.9814 7.34212H7.48145ZM13.4814 12.3421C13.0672 12.3421 12.7314 12.6779 12.7314 13.0921C12.7314 13.5063 13.0672 13.8421 13.4814 13.8421H17.9814C18.3957 13.8421 18.7314 13.5063 18.7314 13.0921C18.7314 12.6779 18.3957 12.3421 17.9814 12.3421H13.4814ZM6.73111 10.3421C4.52162 10.3421 2.73047 12.1333 2.73047 14.3428C2.73047 16.5523 4.52162 18.3434 6.73111 18.3434C8.94061 18.3434 10.7318 16.5523 10.7318 14.3428C10.7318 12.1333 8.94061 10.3421 6.73111 10.3421ZM9.7322 18.3431C8.89631 18.9712 7.85718 19.3434 6.73111 19.3434C5.60594 19.3434 4.56757 18.9718 3.73202 18.3446L3.73155 21.5908C3.73155 22.1616 4.33604 22.5103 4.82163 22.2597L4.91111 22.2056L6.73147 20.9333L8.55111 22.2056C9.01901 22.5325 9.65103 22.2367 9.72377 21.6951L9.73068 21.5908L9.7322 18.3431Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};

export default SkillsSvg;
