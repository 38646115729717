import { DefaultButton } from '@fluentui/react';

const SecondaryButton = ({
  text,
  handleClick,
  color = 'white',
  expanded = false,
}) => {
  return (
    <DefaultButton
      text={text}
      onClick={() => handleClick()}
      className={`border border-[#FF9900] hover:bg-[#FF9900] ${color === 'white' ? 'hover:text-primary-text text-white' : ' text-primary-text hover:text-primary-text'} ${expanded ? 'px-[14px] py-6' : ' px-3 py-4'}  ease-in-out duration-300 text-h4-text font-medium`}
    ></DefaultButton>
  );
};

export default SecondaryButton;
