import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .test('email', 'Email cannot contain white spaces.', (value) => {
      return value ? !/\s/.test(value) : true;
    })
    .test(
      'is-business-email',
      'Personal email addresses like Gmail and Yahoo are not allowed. Please use a business email.',
      (value) => {
        if (!value) return true;
        // Block common personal email domains
        const blockedDomains = [
          'gmail.com',
          'yahoo.com',
          'yahoo.co.uk',
          'googlemail.com',
          'hotmail.com',
          'outlook.com',
          'aol.com',
        ];
        const domain = value.split('@')[1]?.toLowerCase();
        return !blockedDomains.includes(domain);
      },
    )
    .label('Email')
    .required('Email is required'),
  phone_no: yup
    .string()
    .nullable()
    .required('Phone number is required')
    .matches(/^\d{10}$/, 'Enter a valid phone number')
    .transform((value) => (value === '' ? null : value)),
});

export const figmaDemoSchema = yupResolver(schema);
