import { FC } from 'react';

interface ContactHeaderProps {
  title: string;
  description: string;
}

const ContactHeader: FC<ContactHeaderProps> = ({ title, description }) => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-h2-text text-white">{title}</h2>
      <p className="text-lg-text font-normal text-text-p4">{description}</p>
    </div>
  );
};

export default ContactHeader;
