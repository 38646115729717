import { useRef, useState } from 'react';
import FeatureHeader from '../components/Feature/FeatureHeader';
import { HeroSectionCommon } from '../components/Feature/HeroSection';
import ScrollComponent from '../components/Feature/ScrollComponent';
import { sections } from '../components/Feature/Utils';
import InsightVideo from '../components/Feature/assets/InsightVideo.mov';
import { CommonLayout } from '../components/Layouts/CommonLayout';

const Feature = () => {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [lastClickedIndex, setLastClickedIndex] = useState<number | null>(null);

  const scrollToSection = (index: number) => {
    if (sectionRefs.current[index] && headerRef.current) {
      const element = sectionRefs.current[index];
      const headerHeight = headerRef.current.offsetHeight;

      if (element) {
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const offsetPosition = absoluteElementTop - headerHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });

        setLastClickedIndex(index);
      }
    }
  };

  return (
    <CommonLayout>
      <div className="relative">
        <HeroSectionCommon
          header="SQUAD FEATURES"
          title="Strategic Insights for informed decision making"
          description="Resource utilization, project deviations, skill deviations and
                much more."
          video={InsightVideo}
        />
        <FeatureHeader
          ref={headerRef}
          scrollToSection={scrollToSection}
          sectionTitles={sections?.map((section) => section.header ?? '')}
        />
        <section>
          <ScrollComponent sections={sections} sectionRefs={sectionRefs} />
        </section>
      </div>
    </CommonLayout>
  );
};
export default Feature;
