const GoalSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.7296 2.34003C13.9333 2.34003 15.0874 2.55265 16.1562 2.9424L14.9624 4.13625C14.2509 3.9431 13.5023 3.84003 12.7296 3.84003C8.03406 3.84003 4.22754 7.64655 4.22754 12.3421C4.22754 17.0377 8.03406 20.8442 12.7296 20.8442C17.4252 20.8442 21.2317 17.0377 21.2317 12.3421C21.2317 11.5705 21.1289 10.8229 20.9363 10.1122L22.1303 8.91821C22.5195 9.98632 22.7317 11.1394 22.7317 12.3421C22.7317 17.8661 18.2536 22.3442 12.7296 22.3442C7.20563 22.3442 2.72754 17.8661 2.72754 12.3421C2.72754 6.81812 7.20563 2.34003 12.7296 2.34003ZM12.7296 6.34213C13.2482 6.34213 13.7515 6.40793 14.2315 6.53164L14.2315 8.09887C13.7618 7.93261 13.2563 7.84213 12.7296 7.84213C10.2443 7.84213 8.22961 9.85685 8.22961 12.3421C8.22961 14.8274 10.2443 16.8421 12.7296 16.8421C15.2149 16.8421 17.2296 14.8274 17.2296 12.3421C17.2296 11.8162 17.1394 11.3113 16.9736 10.8421L18.5406 10.8421C18.664 11.3216 18.7296 11.8242 18.7296 12.3421C18.7296 15.6558 16.0433 18.3421 12.7296 18.3421C9.41591 18.3421 6.72961 15.6558 6.72961 12.3421C6.72961 9.02842 9.41591 6.34213 12.7296 6.34213ZM14.7296 12.3421C14.7296 13.4467 13.8342 14.3421 12.7296 14.3421C11.625 14.3421 10.7296 13.4467 10.7296 12.3421C10.7296 11.2376 11.625 10.3421 12.7296 10.3421C13.0072 10.3421 13.2717 10.3987 13.512 10.5009L15.2316 8.78137L15.2314 5.59217C15.2314 5.39326 15.3105 5.20249 15.4511 5.06183L17.951 2.56176C18.1655 2.34726 18.4881 2.28308 18.7684 2.39917C19.0487 2.51525 19.2314 2.78873 19.2314 3.09208V5.8421L21.9816 5.84215C22.2849 5.84216 22.5584 6.02489 22.6745 6.30515C22.7906 6.58541 22.7264 6.90799 22.5119 7.12249L20.0118 9.62248C19.8712 9.76313 19.6804 9.84214 19.4815 9.84214L16.2921 9.84215L14.5719 11.5624C14.6734 11.802 14.7296 12.0655 14.7296 12.3421ZM19.1709 8.34215L20.1709 7.34212L18.4814 7.34209C18.0672 7.34208 17.7314 7.0063 17.7314 6.59209V4.9028L16.7315 5.90278L16.7315 8.28319C16.7418 8.2924 16.7519 8.30194 16.7618 8.31181C16.7717 8.32172 16.7813 8.33184 16.7905 8.34215L19.1709 8.34215Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};

export default GoalSvg;
