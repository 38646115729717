import {
  BookTemplateFilled,
  EditSettingsFilled,
  FlowFilled,
} from '@fluentui/react-icons';
import { FC } from 'react';
import { CommonDataType } from '../../../types/types';
import SectionHeader from '../../CommonComponent/SectionHeader';

interface ProductivitySectionProps {
  data: CommonDataType[];
  title: string;
  description: string;
  textCenter?: boolean;
}

const ProductivitySection: FC<ProductivitySectionProps> = ({
  data,
  textCenter = false,
  title,
  description,
}) => {
  return (
    <div className="lg:px-44 lg:py-20 py-14 px-4 bg-white flex lg:gap-14 gap-6 lg:flex-row flex-col items-center w-full border border-t-primary">
      <div className="lg:w-[32%] w-full">
        <SectionHeader
          title={title}
          description={description}
          textCenter={textCenter}
        />
      </div>

      {/* card section */}
      <div className="lg:w-[68%] grid lg:grid-cols-2 grid-cols-1 w-full gap-x-6 gap-y-6">
        {/* card */}
        {data?.map((val) => (
          <div className="flex flex-col gap-9 pt-9 pb-12 px-7 rounded-sm bg-[#F9F9F9]">
            {val?.id === 1 ? (
              <FlowFilled fontSize={32} className="px-1 self-end justify-end" />
            ) : val?.id === 2 ? (
              <EditSettingsFilled
                fontSize={32}
                className="px-1 self-end justify-end"
              />
            ) : (
              <BookTemplateFilled
                fontSize={32}
                className="px-1 self-end justify-end"
              />
            )}

            <div className="flex flex-col gap-4">
              <h5 className=" text-lg-text text-primary-text">{val?.title}</h5>
              <p className=" text-secondary-text text-h3-text">
                {val?.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductivitySection;
