// import { forwardRef } from 'react';

// interface FeatureHeaderProps {
//   scrollToSection: (index: number) => void;
//   sectionTitles: string[];
// }

// const FeatureHeader = forwardRef<HTMLDivElement, FeatureHeaderProps>(
//   ({ scrollToSection, sectionTitles }, ref) => {
//     return (
//       <div className="sticky z-50 bg-white top-[-36px] lg:block hidden" ref={ref}>
//         <div>
//           <div className="flex flex-col items-center mt-6">
//             <h2 className="text-h2-text font-medium text-center">
//               Drive Productivity & Engagement
//             </h2>
//             <p className="border border-[#FF9900] text-center w-fit p-2 bg-[#FDF9F3] m-4 rounded-md">
//               Boost Productivity: Simplify your workflow by managing and
//               visualizing everything in one place.
//             </p>
//           </div>
//           <div className="flex gap-3 text-base font-normal justify-center items-center mt-4 shadow-2xl p-3 w-full flex-wrap">
//             {sectionTitles.map((title, index) => (
//               <div
//                 className="flex items-center justify-between gap-4 cursor-pointe w-fullr"
//                 key={index}
//                 onClick={() => scrollToSection(index)}
//               >
//                 <p
//                   className="relative inline-block text-center text-h4-text text-primary-text cursor-pointer
//    after:absolute after:left-0 after:bottom-[-2px] after:w-0 after:h-[2px] after:bg-[#FF9900]
//    hover:after:w-full after:transition-all after:duration-300 after:ease-in-out hover:font-semibold"
//                 >
//                   {title !== '' && title}
//                 </p>

//                 {index !== sectionTitles.length - 1 && title !== '' && (
//                   <div className="w-[1.5px] h-7 bg-gray-200"></div>
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     );
//   }
// );

// export default FeatureHeader;

import { forwardRef } from 'react';

interface FeatureHeaderProps {
  scrollToSection: (index: number) => void;
  sectionTitles: string[];
}

const FeatureHeader = forwardRef<HTMLDivElement, FeatureHeaderProps>(
  ({ scrollToSection, sectionTitles }, ref) => {
    // Filter out empty titles
    const filteredTitles = sectionTitles.filter((title) => title !== '');

    return (
      <div
        className="sticky z-50 bg-white top-[-36px] lg:block hidden"
        ref={ref}
      >
        <div>
          <div className="flex flex-col items-center mt-6">
            <h2 className="text-h2-text font-medium text-center">
              Drive Productivity & Engagement
            </h2>
            <p className="border border-[#FF9900] text-center w-fit p-2 bg-[#FDF9F3] m-4 rounded-md">
              Boost Productivity: Simplify your workflow by managing and
              visualizing everything in one place.
            </p>
          </div>
          <div className="flex gap-3 text-base font-normal justify-center items-center mt-4 shadow-2xl p-3 w-full">
            {filteredTitles.map((title, index) => (
              <>
                <p
                  key={`title-${index}`}
                  className="relative inline-block text-center text-h4-text text-primary-text cursor-pointer 
                    after:absolute after:left-0 after:bottom-[-2px] after:w-0 after:h-[2px] after:bg-[#FF9900] 
                    hover:after:w-full after:transition-all after:duration-300 after:ease-in-out hover:font-semibold"
                  onClick={() => scrollToSection(sectionTitles.indexOf(title))}
                >
                  {title}
                </p>

                {/* Only add divider if it's not the last item */}
                {index < filteredTitles.length - 1 && (
                  <div
                    key={`divider-${index}`}
                    className="w-[1.5px] h-7 bg-gray-200"
                  ></div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    );
  },
);

export default FeatureHeader;
