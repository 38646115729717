import { CommonLayout } from '../components/Layouts/CommonLayout';
import ResourceManagementProduct from '../components/Products/ResourceManagementProduct';

const ResourceManagement = () => {
  return (
    <CommonLayout>
      <ResourceManagementProduct />
    </CommonLayout>
  );
};

export default ResourceManagement;
