import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import JoinAsPartner from '../components/CommonComponent/JoinAsPartner';
import { TryUs } from '../components/CommonComponent/TryUs';
import AIPrediction from '../components/Home/AIPrediction';
import AIProjectManagement from '../components/Home/AIProjectManagement';
import CustomersFeedback from '../components/Home/CustomersFeedback';
import FAQ from '../components/Home/FAQ';
import FigmaEmbed from '../components/Home/FigmaEmbed';
import { ProductivityNExperiencePlatform } from '../components/Home/ProductivityNExperiencePlatform';
import ServiceLifecycle from '../components/Home/ServiceLifecycle';
import { Starter } from '../components/Home/Starter';
import { CommonLayout } from '../components/Layouts/CommonLayout';

const Home = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  return (
    <CommonLayout>
      <div className="relative">
        <Starter />
        <AIPrediction />
        <ServiceLifecycle />
        <FigmaEmbed />
        <ProductivityNExperiencePlatform />
        <AIProjectManagement />
        <CustomersFeedback />
        <FAQ />
        <JoinAsPartner />
        <TryUs />
      </div>
    </CommonLayout>
  );
};

export default Home;
