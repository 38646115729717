import { ProjectManagementCardsDataType } from '../../types/types';
import CXOTile1 from './assets/CXOTile1';
import CXOTile2 from './assets/CXOTile2';
import CXOTile3 from './assets/CXOTile3';
import ProjectMangImg from './assets/ProjectMangImg.png';
import Task1 from './assets/Task1';
import Task2 from './assets/Task2';
import WorkflowSvg from './assets/WorkflowSvg';
import CardHeader from './CardHeader';
import { ProjectManagementCardsData } from './Utils';
const ProjectManagements = () => {
  return (
    <section className="lg:pr-[108px] pr-4 lg:py-20 py-14 flex flex-col lg:gap-32 gap-10">
      <div className="flex lg:gap-14 gap-6 lg:flex-row flex-col">
        <div className="lg:w-[40%] w-full flex flex-col gap-8">
          <CardHeader
            title="Effortless project management for a productive day"
            header="PROJECT MANAGEMENT"
            color="white"
          />
          <p className=" text-text-p4 text-lg-text font-normal w-[90%]">
            Manage projects, tasks, sprints, gantt chart <br /> schedules and
            measure task productivity of people and project deviation
          </p>
        </div>
        <div className="lg:w-[60%] w-full">
          <img src={ProjectMangImg} alt="ProjectMng" />
        </div>
      </div>

      {/* cards */}
      <div className="flex gap-6 w-full pb-12 lg:flex-row flex-col">
        {ProjectManagementCardsData?.map(
          (val: ProjectManagementCardsDataType) => (
            <div
              className="flex flex-col px-9 pt-6 gap-12 bg-background-dark-card lg:w-1/3 w-full"
              key={val?.id}
            >
              <div className="flex flex-col gap-4 justify-center">
                <h5 className="text-white text-lg-text font-medium">
                  {val?.title}
                </h5>
                <p className=" text-text-p4 text-h4-text">{val?.description}</p>
              </div>
              {/* images*/}
              <div className="flex">
                {val?.id === 1 ? (
                  <div className="flex gap-4 items-start lg:flex-row flex-col">
                    <Task1 />
                    <Task2 />
                  </div>
                ) : val?.id === 2 ? (
                  <WorkflowSvg />
                ) : (
                  <div className="flex gap-2 items-start">
                    <CXOTile1 />
                    <CXOTile2 />
                    <CXOTile3 />
                  </div>
                )}
              </div>
            </div>
          ),
        )}
      </div>
    </section>
  );
};

export default ProjectManagements;
