import { FC } from 'react';
import PrimaryButton from '../Button/PrimaryButton';

interface ErrorCardProps {
  error: string | null;
}

const ErrorCard: FC<ErrorCardProps> = ({ error }) => {
  return (
    <section className="px-20 py-16 bg-white">
      <div className="text-center">
        <h2 className="text-lg-text text-red-600 mb-4">
          Error loading content
        </h2>
        <p className="mb-6">{error}</p>
        <PrimaryButton
          text="Retry"
          handleClick={() => window.location.reload()}
          expanded
        />
      </div>
    </section>
  );
};

export default ErrorCard;
