import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import SquadIcon from '../Feature/assets/SquadIcon';
import TickIcon from '../Feature/assets/TickIcon';
import GradientBorderSectionTitle from '../SquadPricingPlans/GradientBorderSectionTitle';
import AIASSISTANTVIDEO from './assets/AIASSISTANT.mp4';
import CAPACITYVIDEO from './assets/CAPACITY.mp4';
import CHATBOTVIDEO from './assets/CHATBOT.mp4';
import CHECKLISTVIDEO from './assets/CHECKLIST.mp4';
import COLLABORATEVIDEO from './assets/COLLABORATE.mp4';
import CRMVIDEO from './assets/CRM.mp4';
import FEEDBACKVIDEO from './assets/FEEDBACK LOOP.mp4';
import GAINNSIGHTSVIDEO from './assets/GAIN INSIGHTS.mp4';
import PORTALVIDEO from './assets/PORTAL.mp4';
import SETUPFINANCIALSVIDEO from './assets/SETUP FINANCIALS.mp4';
import WORKFLOWVIDEO from './assets/WORKFLOW.mp4';

const sections = [
  {
    heading: 'CRM DEAL WON',
    title: 'New project created',
    description:
      'Squad integrates with your CRM tool to create a project as soon as the win probability reaches 90%',

    video: CRMVIDEO,
  },
  {
    heading: 'DEDICATED CUSTOMER PORTAL',
    title: 'Onboard client to client suite',
    description:
      'Clients get their own dedicated portal, to access all project related details and manage day to day collaboration with your inhouse team. Got external consultants on your team!? Let’s get them a separate portal too.',
    video: PORTALVIDEO,
  },
  {
    heading: 'AI ASSISTANT',
    title: 'Generate tasks with SquadAI',
    description:
      'With inbuilt SquadAI, you can move at an unmatched pace. Just let SquadAI know a little about your project, duration and how detailed you want the tasks to be. Then, just sit back and let SquadAI do the job for you.',
    video: AIASSISTANTVIDEO,
  },
  {
    heading: 'COLLABORATE ',
    title: 'Say Hi! to the team',
    description:
      'Chat, call, meet or collaborate in groups, you can do all that and much more with Squad’s inbuilt collaboration tool call Live Office.',
    video: COLLABORATEVIDEO,
  },
  {
    heading: 'ASSIGN, TRACK, CUSTOMISE',
    title: 'Create tasks, sprints, sections, milestones',
    description:
      'Work in sprints or without. Add tasks, assign and get the ball rolling. Squad is adaptable to your needs with its comprehensive set of features.',
    video: CRMVIDEO,
  },

  {
    heading: 'WORKFLOWS & NOTIFICATIONS',
    title: 'Automate & get notifications',
    description:
      'Define automations for your project, set notification settings, and get notified on mail or Squad.',
    video: WORKFLOWVIDEO,
  },
  {
    heading: 'CHECKLISTS',
    title: 'Manage quality with checklists',
    description: 'Checklists are a great way to ',
    video: CHECKLISTVIDEO,
  },

  {
    heading: 'CAPACITY PLANNING',
    title: 'Optimise your resources to the fullest',
    description: 'Capacity planning and efficient resource utilisation is ',
    video: CAPACITYVIDEO,
  },
  {
    heading: 'CHATBOT',
    title: 'Your own personal assistant',
    description:
      'Missed a meeting or want to see how you did last week, AI partner works with you to assist when required.',
    video: CHATBOTVIDEO,
  },
  {
    heading: 'SETUP FINANCIALS',
    title: 'Setup financials with ease',
    description:
      'With integrated timesheets, employee cost rates and sell rates, Squad gives you the most realistic picture of your project’s financial health.',
    video: SETUPFINANCIALSVIDEO,
  },
  {
    heading: 'GAIN INSIGHTS',
    title: 'Get insights into your project performance',
    description:
      'Project performance can be monitored inside the overview tab, which gives you a brief of the overall project health. In case you want a deep dive, you can also use the insights module.',
    video: GAINNSIGHTSVIDEO,
  },
  {
    heading: 'FEEDBACK LOOP',
    title: 'Benchmarks',
    description:
      'Define benchmarks with your client and review the performance periodically, such as on completion of each milestone. This helps keep the client in connected and whole team in sync.',
    video: FEEDBACKVIDEO,
  },
];

const ScrollComponent = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sectionRefs = useRef<HTMLDivElement[] | []>([]);
  const [viewedSections, setViewedSections] = useState(new Set());
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;
      const containerRect = containerRef.current.getBoundingClientRect();
      const fixedThreshold = 100;
      setIsFixed(containerRect.top <= fixedThreshold);

      const newSet = new Set(viewedSections);
      sectionRefs.current.forEach((section, index) => {
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top <= fixedThreshold + 50) {
            newSet.add(index);
          }
        }
      });

      setViewedSections(newSet);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [viewedSections]);

  return (
    <div className="relative w-full flex flex-col">
      <h1 className="text-h1-text text-primary-text-black pt-7 pb-5 lg:px-[108px] px-4">
        Unify your service lifecycle
      </h1>
      {/* scroll cards */}
      <div className="realtive w-full" ref={containerRef}>
        {sections.map((section, index) => (
          <div className="sticky lg:top-16 top-24 w-full bg-background-light flex lg:pl-36 pl-8">
            {index === 0 && <div className="h-24" />}
            <div
              key={index}
              ref={(el) => (sectionRefs.current[index] = el as HTMLDivElement)}
              className="w-full bg-background-light  transition-all duration-500 border-l-2 border-dashed border-gray-300"
            >
              <div className="w-full flex lg:flex-row flex-col justify-between gap-8 lg:py-20 py-[60px] lg:pl-16 pl-8 lg:pr-[108px] pr-4">
                {/* Left Section - Takes 1 column */}
                <div className="lg:w-[34%] w-full flex flex-col gap-2 ">
                  <div className="w-fit">
                    <GradientBorderSectionTitle title={section?.heading} />
                  </div>
                  <h2 className="text-h2-text text-primary-text-black pt-2">
                    {section?.title}
                  </h2>
                  <p className=" text-tertiary-text text-h3-text">
                    {section?.description}
                  </p>
                </div>

                {/* Right Section (Video) - Takes 2 columns */}
                <div className="lg:w-[66%] w-full flex justify-center border border-gray-300 rounded-md">
                  <video
                    className="w-full object-cover"
                    src={section?.video}
                    autoPlay
                    muted
                    preload="auto"
                    loop
                  ></video>
                </div>
              </div>
              <div className="absolute lg:top-[90px] top-[70px] lg:left-[120px] left-2 transform -translate-y-1/2 flex items-center justify-center w-[52px] h-[52px] text-white rounded-full">
                <AnimatePresence>
                  {viewedSections.has(index) ? (
                    <motion.div
                      key="tick"
                      className="relative w-16 h-16 flex items-center justify-center"
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.5, opacity: 0 }}
                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                      <TickIcon />
                    </motion.div>
                  ) : (
                    <motion.svg
                      key="arrow"
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.5, opacity: 0 }}
                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                      <SquadIcon />
                    </motion.svg>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollComponent;
