import CTAImage from '../../../assets/CTA.jpg';
import PrimaryButton from '../Button/PrimaryButton';
import { BookADemo } from './Utils';
const BoostYourProductivity = () => {
  return (
    <div
      className="flex flex-col gap-9 justify-center items-center py-24 lg:px-[108px] px-4 max-lg:min-w-[300px]"
      style={{
        backgroundImage: `url(${CTAImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
      }}
    >
      <div className="text-h2-text text-center text-white font-poppins">
        Simplify your workflow with Squad, today
      </div>
      <PrimaryButton
        text="Book A Demo"
        handleClick={BookADemo}
        expanded
        width={'300px'}
      />
    </div>
  );
};

export default BoostYourProductivity;
