import SectionHeader from '../CommonComponent/SectionHeader';
import { CustomersFeedbackCardDetails } from './Utils';

const CustomersFeedback = () => {
  return (
    <div className="lg:py-20 py-14 lg:px-[108px] px-4 bg-[#F7F7F7]">
      <SectionHeader
        title="Trusted by businesses to elevate productivity"
        description="See how Worx Squad has empowered teams to streamline their operations and achieve remarkable results."
        textCenter
        mobileTextCenter
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:mt-8 mt-12">
        {CustomersFeedbackCardDetails?.map((item: any, index: number) => (
          <div key={index} className="p-6 bg-[#F5F5F5] rounded w-full h-full">
            <div className="pb-2 text-h3-text text-primary-text">
              {item.feedback}
            </div>
            <div>{item.customerImage}</div>
            <div className="flex items-center gap-4 mt-4">
              <img
                alt="customer_img"
                className="h-[50px] w-[50px] rounded-full border"
                src={item?.image}
              />
              <div>
                <div className="font-medium text-h3-text text-secondary-text">
                  {item.name}
                </div>
                <div className="text-[12px] text-tertiary-text">
                  {item.role}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomersFeedback;
