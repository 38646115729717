import { DefaultButton } from '@fluentui/react';
import {
  ChevronLeft20Filled,
  ChevronRight20Filled,
  DismissFilled,
} from '@fluentui/react-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../Button/PrimaryButton';
import { BookADemo } from '../../Home/Utils';
import BlackLinkSvg from '../../Home/assets/BlackLinkSvg';
import HambergIcon from '../../Home/assets/HambergIcon';
import WhiteLogoSvg from '../../Home/assets/WhiteLogoSvg';
import { ProductPages, ResourcePages } from '../utils';

interface MenuItem {
  title: string;
  url?: string;
  subMenu?: MenuItem[];
  icon?: ReactElement;
}

const menuData: MenuItem[] = [
  {
    title: 'Products',
    subMenu: ProductPages,
  },
  {
    title: 'Features',
    url: '/feature',
  },
  {
    title: 'How it works',
    url: '/how-it-works',
  },
  {
    title: 'Pricing',
    url: '/pricing',
  },
  {
    title: 'Resources',
    subMenu: ResourcePages,
  },
  {
    title: 'About Worxwide',
    url: 'https://worxwide.com/',
  },
  {
    title: 'Contact us',
    url: '/contact-us',
  },
];

export default function MobileMenuSidebar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="overflow-hidden">
      {/* Hamburger Menu Button */}
      <button onClick={() => setIsMenuOpen(true)} className="transition-colors">
        <HambergIcon />
      </button>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && <MobileMenu onClose={() => setIsMenuOpen(false)} />}
      </AnimatePresence>
    </div>
  );
}

function MobileMenu({ onClose }: { onClose: () => void }) {
  const navigate = useNavigate();
  const [history, setHistory] = useState<MenuItem[][]>([menuData]);

  const openSubMenu = (item: MenuItem) => {
    if (item.subMenu && item.subMenu.length > 0) {
      setHistory((prev) => [...prev, item.subMenu!]);
    }
  };

  const goBack = () => {
    if (history.length > 1) {
      setHistory((prev) => {
        const newHistory = prev.slice(0, -1);
        return newHistory.length > 0 ? newHistory : [menuData];
      });
    }
  };

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      className="fixed inset-0 bg-background-light z-50 w-full h-full p-5 flex flex-col overflow-hidden"
    >
      <AnimatePresence exitBeforeEnter>
        {history.map((currentMenu, index) =>
          index === history.length - 1 ? (
            <motion.div
              key={index}
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              className="absolute inset-0 bg-white px-7 py-5 flex flex-col w-full h-full"
            >
              {/* Header */}
              <div className="flex justify-between items-center mb-8">
                {history.length > 1 ? (
                  <button onClick={goBack} className="flex items-center gap-1">
                    <ChevronLeft20Filled strokeWidth={2} className="p-0 m-0" />
                    <span className="text-h2-text-subtitle font-semibold font-poppins">
                      Back
                    </span>
                  </button>
                ) : (
                  <Link to={'/'} className="text-lg font-semibold">
                    <WhiteLogoSvg />
                  </Link>
                )}
                <button onClick={onClose}>
                  <DismissFilled fontSize={30} />
                </button>
              </div>

              {/* Menu Items */}
              <ul className="space-y-4">
                {currentMenu.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => openSubMenu(item)}
                    className="cursor-pointer transition-colors"
                  >
                    <div className="flex items-center gap-3">
                      {!item?.subMenu && item?.title !== 'About Worxwide' ? (
                        <Link
                          to={item?.url ?? ''}
                          className={`text-h3-text-subtitle font-poppins py-2 pl-1.5`}
                        >
                          {item?.title}
                        </Link>
                      ) : item?.title === 'About Worxwide' ? (
                        <div className="text-h3-text-subtitle font-poppins py-2 flex justify-between items-center w-full pl-1.5">
                          <Link
                            to={item?.url ?? ''}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Worxwide"
                          >
                            {item?.title}
                          </Link>
                          <span className="pr-2">
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              className="transition-colors duration-300"
                            >
                              <path d="M8.25904 3.3162L1.80227 9.77297L0.741607 8.71231L7.19838 2.25554L1.25868 2.25554L1.27194 0.757359H9.75722V9.24264L8.25904 9.2559V3.3162Z" />
                            </svg>
                          </span>
                        </div>
                      ) : (
                        <div className="text-h3-text-subtitle font-poppins py-2 flex justify-between  pl-1.5 items-center w-full">
                          {' '}
                          <span>{item?.title}</span>
                          <ChevronRight20Filled fontSize={20} />
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>

              <div className="flex flex-col gap-4 mt-8">
                <DefaultButton
                  onClick={() => navigate('/login')}
                  className="border w-full border-[#FF9900] text-black px-3 py-6"
                >
                  <span className="flex gap-4 justify-center items-center">
                    <span className="text-[1rem] font-medium">
                      Sign Up/Sign In
                    </span>
                    <span>
                      <BlackLinkSvg />
                    </span>
                  </span>
                </DefaultButton>
                <PrimaryButton
                  expanded
                  text="Book A Demo"
                  handleClick={BookADemo}
                />
              </div>
            </motion.div>
          ) : null,
        )}
      </AnimatePresence>
    </motion.div>
  );
}
