// Function to share on Twitter
export const shareOnTwitter = ({
  blogTitle,
  blogUrl,
}: {
  blogTitle: string;
  blogUrl: string;
}) => {
  const text = encodeURIComponent(`Check out this article: ${blogTitle}`);
  const url = encodeURIComponent(blogUrl);
  window.open(
    `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
    '_blank',
  );
};

// Function to share on LinkedIn
export const shareOnLinkedIn = ({ blogUrl }: { blogUrl: string }) => {
  const url = encodeURIComponent(blogUrl);
  window.open(
    `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    '_blank',
  );
};
