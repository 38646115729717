import { FC } from 'react';
import SectionHeader from '../CommonComponent/SectionHeader';
import SubHeader from './SubHeader';
import { EmployeeManagementData } from './Utils';
import GradientImage2 from './assets/GradientImage2.png';
import GradientImage3 from './assets/GradientImage3.png';
import SVG from './assets/LifeCycle.svg';
import BottomVector from './assets/Vector bottom.svg';
import TopVector from './assets/Vector top.svg';
import GradientImage from './assets/gradientImage.png';
const ServiceLifecycle: FC = () => {
  return (
    <div className="lg:px-[108px] lg:py-20 px-4 py-14">
      {/* heading */}
      <div className="flex flex-col gap-12 text-center">
        <SectionHeader
          title="Your entire service lifecycle, fully automated"
          description="Know Every Interaction, Master Every Moment, 360° Customer Insights at Your Fingertips"
          mobileTextCenter
        />

        <div className="flex flex-col gap-1 w-full">
          {/* AI Prediction insights */}
          <div
            className="flex text-center justify-center py-16 px-6"
            style={{
              backgroundImage: `url(${GradientImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '207px',
              width: '100%',
              borderRadius: '8px',
            }}
          >
            <SubHeader
              title="AI Predictions & Insights"
              description="Unveil CXO level strategic insights on organisation health and employee level productivity trackers"
            />
          </div>

          {/* Life cycle */}
          <div
            className="flex flex-col gap-14 py-16 lg:px-[74px] px-6 w-full relative overflow-hidden"
            style={{
              backgroundImage: `url(${GradientImage2})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              borderRadius: '8px',
            }}
          >
            <img
              src={TopVector}
              alt="top-vector"
              className="absolute top-0 left-0 w-auto h-auto overflow-hidden"
            />

            <SubHeader
              title="Service Lifecycle Management"
              description="Lorem ipsum dolor sit amet consectetur. Tortor sem sed volutpat id tellus."
            />

            <img src={SVG} alt="life-cyle" className="w-full object-cover" />
            <img
              src={BottomVector}
              alt="bottom-vector"
              className="absolute right-0 bottom-0 w-auto h-auto overflow-hidden"
            />
          </div>

          {/* employee management */}
          <div
            className="flex px-6 py-16 flex-col gap-9 items-center justify-center"
            style={{
              backgroundImage: `url(${GradientImage3})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: '308px',
              width: '100%',
              borderRadius: '8px',
            }}
          >
            <SubHeader
              title="Employee Management"
              description="Track, plan, and manage your workforce with these tools."
            />

            {/* featureBox */}
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 gap-y-8 items-center justify-center">
              {EmployeeManagementData?.map((value, index) => (
                <div className="flex gap-4">
                  <div className="flex flex-col gap-4 justify-center  w-[126px] items-center">
                    <div className="flex items-center justify-center border rounded-full h-14 w-14 border-[#DADADA]">
                      {value?.icon}
                    </div>
                    <div className="text-h4-text-subtitle text-center text-[#F0F0F0]">
                      {value?.title}
                    </div>
                  </div>

                  {/* Vertical Stick - Show only between items */}
                  {((index % 2 === 0 &&
                    index !== EmployeeManagementData.length - 1) ||
                    (index === 1 && EmployeeManagementData.length > 2)) && (
                    <div
                      className={`bg-[#CCCCCC66] ${index === 1 ? 'w-[0.8px]' : 'w-[1px]'} h-[62px] self-center lg:block hidden`}
                    />
                  )}

                  {/* Mobile Stick  */}
                  {index % 2 === 0 &&
                    index !== EmployeeManagementData.length - 1 && (
                      <div className="bg-[#CCCCCC66] w-[1px] h-[62px] self-center lg:hidden" />
                    )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceLifecycle;
