import { WhySquad } from '../CommonComponent/WhySquad';
import Client_Management_Img from './Assets/Client_Management.jpg';
import CommonHeaderProducts from './CommonCompoenents/CommonHeaderProducts';
import CustomAnalysisSection from './CommonCompoenents/CustomAnalysisSection';
import CXOCommonSection from './CommonCompoenents/CXOCommonSection';
import ProductivitySection from './CommonCompoenents/ProductivitySection';
import SidebarWithImage from './CommonCompoenents/SidebarWithImage';
import {
  CLIENT_FEEDBACK_MATTERS,
  CLIENT_MANAGEMENT,
  COLLABORATE_WITH_TEAM,
  CONTROL_VISIBILITY,
  DEDICATED_CUSTOMER_PORTAL,
  PROJECT_MONITER,
  RESULT_COUNT_PROJECT_MANAGEMENT,
  WHY_SQUAD,
} from './utils';
const ClientManagementProduct = () => {
  return (
    <div>
      <CommonHeaderProducts
        ResultCounts={RESULT_COUNT_PROJECT_MANAGEMENT}
        Header={CLIENT_MANAGEMENT}
        image={Client_Management_Img}
      />
      <CXOCommonSection
        data={DEDICATED_CUSTOMER_PORTAL}
        title="Dedicated customer portal"
        description="A streamlined, transparent, and interactive project experience for your clients."
        background="white"
      />
      <CustomAnalysisSection
        data={CONTROL_VISIBILITY}
        title="Control visibility"
        description="Protect your data with enterprise-grade security measures"
      />
      <CXOCommonSection
        data={COLLABORATE_WITH_TEAM}
        title="Collaborate with the team"
        description="Give your clients tools for easy communication"
        background="white"
      />
      <SidebarWithImage
        title="Monitor project progress"
        description="Optimise resource allocation and utilization"
        data={PROJECT_MONITER}
      />
      <ProductivitySection
        data={CLIENT_FEEDBACK_MATTERS}
        title={'Client feedback matters'}
        description={'Adapt Squad to fit your unique project processes.'}
      />
      <WhySquad
        title="Why Squad?"
        description="Discover additional tools that make project management faster, smarter, and simpler."
        textCenter={false}
        data={WHY_SQUAD}
      />
    </div>
  );
};

export default ClientManagementProduct;
