export const AddressData = [
  {
    title: 'Address',
    description: `Ground Floor, Plot No. 32, Global Business Park, 
  Sector 44, Gurgaon, Haryana, 122001`,
    type: 'text',
  },
  {
    title: 'Say Hi',
    description: `consult@worxwide.com`,
    type: 'email',
  },
  {
    title: 'For more business. Check out our parent website',
    description: `www.worxwide.com`,
    type: 'url',
  },
];
