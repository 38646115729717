const ChatSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.7314 2.34212C18.2543 2.34212 22.7314 6.81927 22.7314 12.3421C22.7314 17.865 18.2543 22.3421 12.7314 22.3421C11.1132 22.3421 9.54927 21.9567 8.1443 21.2301L4.31849 22.2975C3.65357 22.4831 2.96403 22.0946 2.77835 21.4297C2.71691 21.2097 2.71694 20.977 2.77839 20.7572L3.84606 16.9343C3.11781 15.5281 2.73145 13.9624 2.73145 12.3421C2.73145 6.81927 7.2086 2.34212 12.7314 2.34212ZM12.7314 3.84212C8.03702 3.84212 4.23145 7.6477 4.23145 12.3421C4.23145 13.8117 4.60422 15.2255 5.30448 16.4796L5.45512 16.7494L4.3424 20.7335L8.329 19.6213L8.59853 19.7716C9.8515 20.4703 11.2636 20.8421 12.7314 20.8421C17.4259 20.8421 21.2314 17.0365 21.2314 12.3421C21.2314 7.6477 17.4259 3.84212 12.7314 3.84212ZM9.48145 13.3421H13.9797C14.3939 13.3421 14.7297 13.6779 14.7297 14.0921C14.7297 14.4718 14.4476 14.7856 14.0815 14.8353L13.9797 14.8421H9.48145C9.06723 14.8421 8.73145 14.5063 8.73145 14.0921C8.73145 13.7124 9.0136 13.3986 9.37967 13.349L9.48145 13.3421H13.9797H9.48145ZM9.48145 9.84212H15.9859C16.4001 9.84212 16.7359 10.1779 16.7359 10.5921C16.7359 10.9718 16.4538 11.2856 16.0877 11.3353L15.9859 11.3421H9.48145C9.06723 11.3421 8.73145 11.0063 8.73145 10.5921C8.73145 10.2124 9.0136 9.89863 9.37967 9.84896L9.48145 9.84212H15.9859H9.48145Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};

export default ChatSvg;
