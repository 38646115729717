import { Spinner } from '@fluentui/react';
import { ArrowDownloadFilled } from '@fluentui/react-icons';
import { FC } from 'react';
import './Button.css';

interface AnimatedButtonProps {
  loading?: boolean;
  onClick?: () => void;
}

const AnimatedButton: FC<AnimatedButtonProps> = ({
  loading = false,
  onClick,
}) => {
  return (
    <button className="ab-button-wrapper w-full h-11" onClick={onClick}>
      <div className="ab-download-btn text-primary-text text-h4-text font-medium secondary-font">
        <div className="ab-hover-bg"></div>
        {loading ? (
          <Spinner />
        ) : (
          <span className="ab-text items-center">
            Download Full Whitepaper
            <ArrowDownloadFilled />
          </span>
        )}
      </div>
    </button>
  );
};

export default AnimatedButton;
