import { WhySquad } from '../CommonComponent/WhySquad';
import { AIPoweredProjectManagement } from '../Home/Utils';
import EMPLOYEE_MANAGEMENT_IMG from './Assets/EMPLOYEE_MANAGEMENT.jpg';
import CapacitySection from './CommonCompoenents/CapacitySection';
import CommonHeaderProducts from './CommonCompoenents/CommonHeaderProducts';
import CustomAnalysisSection from './CommonCompoenents/CustomAnalysisSection';
import CXOCommonSection from './CommonCompoenents/CXOCommonSection';
import IntegrationCapability from './CommonCompoenents/IntegrationCapability';
import ProductivitySection from './CommonCompoenents/ProductivitySection';
import SidebarWithImage from './CommonCompoenents/SidebarWithImage';
import {
  COLLABORATE_WITH_OFFICE,
  CULTIVATE_ENGAGEMENT_MOMENTS_DATA,
  DRIVE_PERFORMANCE_OBJECTIVE_DATA,
  EMPLOYEE_MANAGEMENT,
  EMPLOYEE_MANAGEMENT_TOOLS,
  PERSONALIZED_LEARNING_DATA,
  RESULTCOUNTSAIINSIGHT,
  SEAMLESS_INTEGRATION_ACCESS_CONTROL_DATA,
  STEPS_DATA_EMPLOYEE_MANAGEMENT,
  TIMESHEET_AND_LEAVE_DATA,
  WHY_SQUAD,
} from './utils';

const EmployeeManagementProduct = () => {
  return (
    <div>
      <CommonHeaderProducts
        StepsData={STEPS_DATA_EMPLOYEE_MANAGEMENT}
        ResultCounts={RESULTCOUNTSAIINSIGHT}
        Header={EMPLOYEE_MANAGEMENT}
        image={EMPLOYEE_MANAGEMENT_IMG}
      />
      <CXOCommonSection
        data={EMPLOYEE_MANAGEMENT_TOOLS}
        title="Integrated tools to support, develop, and engage your employees"
        description="With tools that help manage business"
        background="light-white"
        hideImg
      />
      <CXOCommonSection
        data={COLLABORATE_WITH_OFFICE}
        title="Collaboration with live office"
        description="Connect, communicate, and collaborate in real time."
        background="white"
      />
      <SidebarWithImage
        title="Streamlined timesheet and leave management"
        description="Track time and manage leaves with ease."
        data={TIMESHEET_AND_LEAVE_DATA}
      />
      <ProductivitySection
        data={CULTIVATE_ENGAGEMENT_MOMENTS_DATA}
        title={'Cultivate Engagement with Wall of Moments'}
        description={'Celebrate and connect your team.'}
      />
      <CapacitySection
        data={AIPoweredProjectManagement}
        title="AI Integrated"
        description="Harness the Power of AI for Smarter Project Management"
      />
      <IntegrationCapability
        data={PERSONALIZED_LEARNING_DATA}
        title={'Personalized learning at scale'}
        description={'Invest in your team’s growth.'}
      />
      <SidebarWithImage
        title="Goal setting and performance reviews"
        description="Drive performance with clear objectives"
        data={DRIVE_PERFORMANCE_OBJECTIVE_DATA}
      />
      <CustomAnalysisSection
        data={SEAMLESS_INTEGRATION_ACCESS_CONTROL_DATA}
        title="Seamless integration and access control"
        description="Secure, scalable, and integrated."
        widthLeft="40%"
        widthRight="60%"
      />
      <WhySquad
        title="Why Squad?"
        description="Discover additional tools that make project management faster, smarter, and simpler."
        textCenter={false}
        data={WHY_SQUAD}
      />
    </div>
  );
};

export default EmployeeManagementProduct;
