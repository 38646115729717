import { WhySquad } from '../CommonComponent/WhySquad';
import Resource_Management_Img from './Assets/Resource_Management.jpg';
import CapacitySection from './CommonCompoenents/CapacitySection';
import CommonHeaderProducts from './CommonCompoenents/CommonHeaderProducts';
import CXOCommonSection from './CommonCompoenents/CXOCommonSection';
import ProductivitySection from './CommonCompoenents/ProductivitySection';
import SidebarWithImage from './CommonCompoenents/SidebarWithImage';
import {
  DYNAMIC_TASK_MANAGEMENT,
  EMPLOYEE_PROJECT_ALLOCATION,
  ENHANCED_RESOURCE_DATA,
  HOLISTIC_RESOURCE_DATA,
  RESOURCE_MANAGEMENT,
  RESULT_COUNT_PROJECT_MANAGEMENT,
  STEPS_DATA_RESOURCE_MANAGEMENT,
  TALENT_FINDER_TOOL,
  WHY_SQUAD,
} from './utils';
const ResourceManagementProduct = () => {
  return (
    <div>
      <CommonHeaderProducts
        StepsData={STEPS_DATA_RESOURCE_MANAGEMENT}
        ResultCounts={RESULT_COUNT_PROJECT_MANAGEMENT}
        Header={RESOURCE_MANAGEMENT}
        image={Resource_Management_Img}
      />
      <SidebarWithImage
        title="Holistic resource utilization metrics"
        description="Gain a comprehensive view of your resource allocation"
        data={HOLISTIC_RESOURCE_DATA}
      />
      <CXOCommonSection
        data={EMPLOYEE_PROJECT_ALLOCATION}
        title="Employee and project-specific allocation"
        description="Dive deeper into individual and project-level resource allocation."
        background="white"
      />
      <CapacitySection
        data={TALENT_FINDER_TOOL}
        title="Advanced Talent Finder Tool"
        description="Harness the Power of AI for Smarter Project Management"
        isImage
      />
      <CXOCommonSection
        data={DYNAMIC_TASK_MANAGEMENT}
        title="Dynamic scheduling and task management"
        description="Manage and adjust task assignments seamlessly."
        background="light-white"
      />
      <ProductivitySection
        data={ENHANCED_RESOURCE_DATA}
        title={'Enhanced resource management visibility'}
        description={
          'Empower your team with clear, actionable resource insights.'
        }
      />
      <WhySquad
        title="Why Squad?"
        description="Discover additional tools that make project management faster, smarter, and simpler."
        textCenter={false}
        data={WHY_SQUAD}
      />
    </div>
  );
};

export default ResourceManagementProduct;
