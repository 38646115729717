import { WhySquad } from '../CommonComponent/WhySquad';
import { HOW_SQUAD_STAND_OUT } from './Utils';

export const ProductivityNExperiencePlatform = () => {
  return (
    <div>
      <WhySquad
        title="How Squad stands out?"
        description="Explore what makes Squad the ultimate productivity platform"
        data={HOW_SQUAD_STAND_OUT}
        textCenter
      />
    </div>
  );
};
