import FinanceImg from './assets/FinanceImg.png';
import CardHeader from './CardHeader';

const FinancePlanner = () => {
  return (
    <section className="lg:pr-[108px] pr-4  lg:py-20 py-14 w-full">
      <div className="flex lg:gap-14 gap-6 justify-between w-full lg:flex-row flex-col">
        <div className="flex flex-col gap-4 lg:w-[40%] w-full">
          <CardHeader
            header="FINANCIAL MANAGEMENT"
            title="Track your financial metrics to boost bottom line"
            color="black"
          />
          <p className=" text-lg-text font-normal w-[70%] text-primary-text-black">
            Measure revenue per person, SG&A and other key metrics through
            permutation and combination. Sell rate, cost rate, actual work
            hours.
          </p>
        </div>
        <div className="lg:w-[50%] w-full">
          <img
            src={FinanceImg}
            alt="FinanceImg"
            className=" rounded-lg w-full h-full"
          />
        </div>
      </div>
    </section>
  );
};

export default FinancePlanner;
