import { ArrowLeft16Filled, ArrowRight16Filled } from '@fluentui/react-icons';
import { FC, useEffect, useState } from 'react';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  totalPages = 10,
  currentPage = 1,
  onPageChange,
}) => {
  const [pages, setPages] = useState<(number | string)[]>([]);

  useEffect(() => {
    generatePagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, totalPages]);

  const generatePagination = () => {
    let pagesArray: (number | string)[] = [];

    if (totalPages <= 7) {
      pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      pagesArray.push(1);
      if (currentPage <= 3) {
        pagesArray.push(2, 3, 4, '...', totalPages - 1, totalPages);
      } else if (currentPage >= totalPages - 2) {
        pagesArray.push(
          '...',
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        );
      } else {
        pagesArray.push(
          '...',
          currentPage - 1,
          currentPage,
          currentPage + 1,
          '...',
          totalPages,
        );
      }
    }

    setPages(pagesArray);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="flex w-full justify-between items-center">
      <button
        className={`text-gray-500 text-h4-text font-medium flex  gap-1.5 items-center ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:text-gray-700'}`}
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        <span>
          <ArrowLeft16Filled fontSize={20} />
        </span>{' '}
        Previous
      </button>

      <div className="flex gap-2">
        {pages.map((page, index) =>
          page === '...' ? (
            <div key={`ellipsis-${index}`} className="flex items-center px-2">
              ...
            </div>
          ) : (
            <button
              key={`page-${page}`}
              className={`flex items-center justify-center rounded-lg h-10 w-10 ${
                currentPage === page
                  ? 'bg-[rgba(32,124,151,0.10)] text-gray-800 font-medium'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
              onClick={() => onPageChange(Number(page))}
            >
              {page}
            </button>
          ),
        )}
      </div>

      <button
        className={`text-gray-500 text-h4-text font-medium flex gap-1.5 items-center ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:text-gray-700'}`}
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        Next{' '}
        <span>
          <ArrowRight16Filled fontSize={20} />
        </span>
      </button>
    </div>
  );
};

export default Pagination;
