import { ApiResponse, Category } from '../types/types';
import axiosInstance from './axiosInstance';

// blog categories
export const fetchCategories = async ({
  signal,
}: {
  signal: AbortSignal;
}): Promise<ApiResponse<Category>> => {
  try {
    const { data } = await axiosInstance.get(
      '/api/worx-squad-blog-categories',
      { signal },
    );
    return data;
  } catch (error) {
    throw new Error(`Categories API Error: ${error}`);
  }
};

// whitePaper Categories
export const fetchWhitePaperCategories = async ({
  signal,
}: {
  signal: AbortSignal;
}): Promise<ApiResponse<Category>> => {
  try {
    const { data } = await axiosInstance.get(
      '/api/worx-squad-white-paper-categories',
      { signal },
    );
    return data;
  } catch (error) {
    throw new Error(`Categories API Error: ${error}`);
  }
};
