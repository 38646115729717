import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Blog } from '../../types/types';
import PageHeader from '../CommonComponent/PageHeader';
import ErrorCard from '../WhitePaper/ErrorCard';
import BlogCard from './NewBlogCard';
import NewBlogCategory from './NewBlogCategory';
import Pagination from './Pagination';
import { useBlog } from './hooks/useGetBlogs';

const BlogStarter: FC = () => {
  // custom hook to fetch data and categories
  const {
    blogs,
    categories,
    selectedCategory,
    setSelectedCategory,
    currentPage,
    setCurrentPage,
    loading,
    error,
    totalPages,
  } = useBlog();

  // handle page change
  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setCurrentPage],
  );

  //  error show while data fetching
  if (error) {
    return <ErrorCard error={error} />;
  }

  return (
    <section className="lg:px-[108px] px-4 bg-white lg:pb-20 pb-14">
      <div className="lg:py-16 py-8">
        <PageHeader
          title="Future of Work: The Digital Way"
          description="Explore how companies are leveraging digital for embracing productivity and employee experience."
        />
      </div>
      {/* blog category */}
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-6">
          <h2 className="text-h2-text text-primary-text text-start">
            Blog posts
          </h2>
          <NewBlogCategory
            selectedCategory={selectedCategory}
            setSelectedCategory={(category) => {
              setSelectedCategory(category);
              setCurrentPage(1);
            }}
            categories={categories}
            blogsEmpty={blogs?.length === 0}
          />
        </div>

        {/* blogs */}
        {loading ? (
          <div className="grid gap-x-8 gap-y-12 sm:grid-cols-1 lg:grid-cols-2 w-full">
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className="bg-gray-200 h-80 rounded-md animate-pulse"
                ></div>
              ))}
          </div>
        ) : blogs.length === 0 ? (
          <div className="py-12 text-center">
            <p className="text-lg-text text-secondary-text">
              No blog found{' '}
              {`${selectedCategory !== 'All' ? 'for this category' : ''} `}
            </p>
          </div>
        ) : (
          <div className="grid gap-x-8 gap-y-12 sm:grid-cols-1 lg:grid-cols-2">
            {blogs.map((blog: Blog) => (
              <Link to={`/blog/${blog?.slug}`} key={blog.id}>
                <BlogCard blog={blog} />
              </Link>
            ))}
          </div>
        )}

        {/* pagination */}
        {blogs.length > 0 && totalPages > 1 && (
          <div className="pt-9">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default React.memo(BlogStarter);
