import { motion } from 'framer-motion';
import { FC } from 'react';
import SecondaryButton from '../../Button/SecondaryButton';
import DownloadApp from '../../Home/DownloadApp';
import { ProductPages } from '../utils';
import { CommonCard } from './CommonCard';

interface ProductsNavigationProps {
  setProductDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: () => void;
  hideModal: () => void;
  isModalOpen: boolean;
}

const ProductsNavigation: FC<ProductsNavigationProps> = ({
  setProductDropdown,
  showModal,
  hideModal,
  isModalOpen,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -200 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.6, ease: 'easeInOut' }}
      className="absolute left-0 right-0 top-[90px] bg-white text-black shadow-lg w-full"
      onMouseEnter={() => setProductDropdown(true)}
      onMouseLeave={() => setProductDropdown(false)}
    >
      <div className="flex justify-between gap-[72px]">
        <div className="w-[62%] flex flex-col gap-12 pt-14 pb-[78px] pl-[78px]">
          <h3 className="text-h3-text-subtitle text-secondary-text">
            Product Features
          </h3>
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 max-w-full">
            {/* card */}
            {ProductPages?.map((page) => <CommonCard page={page} />)}
          </div>
        </div>
        <div className="w-[38%] bg-background-light py-14 px-20">
          <div className="flex flex-col gap-12">
            <h4 className="text-h3-text-subtitle text-secondary-text">
              Try Squad for free
            </h4>
            <div className="drop-shadow-lg">
              <iframe
                width={390}
                height={190}
                src="https://www.youtube.com/embed/NjTZsfbdMdk?rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          {/* button section */}
          <div className="flex flex-col gap-5 pt-4">
            <div className="flex flex-col gap-1">
              <h4 className="text-h4-text-subtitle text-secondary-text">
                Platform Overview
              </h4>
              <p className="text-h4-text text-tertiary-text w-[85%]">
                Watch our showcase to see how our all-in-one platform
                streamlines
              </p>
            </div>
            <div>
              <SecondaryButton
                text={'Download App'}
                handleClick={showModal}
                expanded={true}
                color="black"
              />
              <DownloadApp
                showModal={showModal}
                isModalOpen={isModalOpen}
                hideModal={hideModal}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProductsNavigation;
