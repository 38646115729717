import { ProductivityNExperiencePlatform } from '../components/Home/ProductivityNExperiencePlatform';
import ScrollComponent from '../components/HowItWorks/ScrollComponent';
import { Starter } from '../components/HowItWorks/Starter';
import { CommonLayout } from '../components/Layouts/CommonLayout';

const HowItWorks = () => {
  return (
    <CommonLayout>
      <div className="relative">
        <Starter />
        <ScrollComponent />
        <ProductivityNExperiencePlatform />
      </div>
    </CommonLayout>
  );
};

export default HowItWorks;
