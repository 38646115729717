import MagicPlanImg from './assets/MagicPlanImg.png';
const MagicPlanner = () => {
  return (
    <div className="flex lg:gap-14 gap-6 justify-between w-full items-center lg:flex-row flex-col">
      <div className="lg:w-[52%] w-full p-6 bg-white">
        <img src={MagicPlanImg} alt="magic planner" className="w-full h-full" />
      </div>
      <div className="lg:w-[48%] w-full flex flex-col gap-4">
        <h2 className="text-h2-text font-medium text-primary-text-black">
          Magic Planner acts as magic wand for your plans
        </h2>
        <p className="text-h3-text  text-tertiary-text">
          Magic Planner helps you optimise and re-arrange your project timelines
          with a click, ensuring smooth workload distribution and efficiency.
        </p>
      </div>
    </div>
  );
};

export default MagicPlanner;
