import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WhitePaperForm from './WhitePaperForm';
import WhitePaperSlider from './WhitePaperSlider';
import { useGetWhitepaperDetails } from './hooks/useGetWhitepaperDetails';

const WhitePaperMainArea = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const formRef = useRef<HTMLDivElement>(null);
  const [highlightForm, setHighlightForm] = useState(false);

  // Custom hook to fetch data
  const { whitePaper, loading, error } = useGetWhitepaperDetails(slug);

  // Function to highlight and shake the form
  const handleLastSlideAction = () => {
    setHighlightForm(true);

    // Reset the highlight after animation completes
    setTimeout(() => {
      setHighlightForm(false);
    }, 2000);

    // Scroll to form if needed
    if (formRef.current) {
      // For mobile view, scroll to form
      if (window.innerWidth < 768) {
        formRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  // Show error in wrong slug or if error occurs while fetching
  if (error || (!whitePaper && !loading))
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="text-center p-6 max-w-md bg-white rounded-lg shadow-sm">
          <div className="text-red-500 text-lg-text mb-2">
            No Content Found. Refresh or Retry Again
          </div>
          <p className="text-gray-700 mb-4">{error}</p>
          <button
            onClick={() => navigate('/whitepaper')}
            className="px-4 py-2 bg-primary-accent text-white rounded-md hover:bg-primary-accent/90 transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );

  const slides = whitePaper
    ? whitePaper?.whitePaperSlidesImg.map(
        (val) => `https://strapi.salesworx.ai${val?.url}`,
      )
    : [];

  return (
    <div className="flex lg:gap-16 lg:flex-row flex-col gap-12 justify-center lg:max-h-[792px] h-fit relative">
      <WhitePaperSlider
        slides={slides}
        onLastSlideAction={handleLastSlideAction}
      />

      <WhitePaperForm
        formRef={formRef}
        highlightForm={highlightForm}
        whitePaper={whitePaper}
      />
    </div>
  );
};

export default WhitePaperMainArea;
