import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SidebarDataType } from '../../../types/types';
import WhiteBgButton from '../../Button/WhiteBgButton';
import SectionHeader from '../../CommonComponent/SectionHeader';
import Image1 from '../../Home/assets/Image 1.png';

interface SidebarWithImageProps {
  data: SidebarDataType[];
  title: string;
  description: string;
}

const SidebarWithImage: FC<SidebarWithImageProps> = ({
  data,
  description,
  title,
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);
  const navigate = useNavigate();
  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="lg:px-[108px] lg:py-20 bg-background-light w-full px-4 py-14 border-b border-primary">
      <div className="flex flex-col lg:gap-20 gap-12 w-full">
        {/* heading/description */}
        <SectionHeader title={title} description={description} />
        {/* main component */}
        <div className="flex gap-8 w-full lg:flex-row flex-col">
          {/* side sidebar */}
          <div className="flex flex-col lg:w-[30%] w-full">
            {data?.map((item, index) => {
              const isOpen = openIndex === index;
              return (
                <div
                  key={item.id}
                  className="flex flex-col gap-4 py-4 cursor-pointer border-b"
                  onClick={() => handleToggle(index)}
                >
                  {/* title */}
                  <div
                    className={`text-h1-text-subtitle transition-colors duration-300 ${
                      isOpen
                        ? 'text-primary-text text-normal'
                        : 'text-tertiary-text hover:text-primary-text'
                    }`}
                  >
                    {item.title}
                  </div>

                  {/* description (collapsible) */}
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                        className="flex flex-col gap-6 overflow-hidden"
                      >
                        <div className="text-secondary-text text-h3-text">
                          {item.description}
                        </div>
                        {item?.url && (
                          <WhiteBgButton
                            text="Learn More"
                            handleClick={() => navigate(`${item?.url}`)}
                            border
                          />
                        )}

                        <motion.div
                          key={openIndex}
                          initial={{ opacity: 0, scale: 0.95 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.6, ease: 'easeOut' }}
                          className="p-6 rounded-lg w-full bg-white lg:hidden flex"
                        >
                          <img
                            src={
                              openIndex !== null
                                ? data[openIndex]?.image
                                : Image1
                            }
                            alt="ai-prediction"
                            className="object-cover"
                          />
                        </motion.div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              );
            })}
          </div>

          {/*  side image */}
          <motion.div
            key={openIndex}
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 100, opacity: 0 }}
            transition={{ duration: 0.6, ease: 'easeOut' }}
            className="p-6 rounded-lg lg:block hidden w-[70%] bg-white"
          >
            <img
              src={openIndex !== null ? data[openIndex]?.image : Image1}
              alt="ai-prediction"
              className="object-cover w-full h-full"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SidebarWithImage;
