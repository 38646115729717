import { CommonLayout } from '../components/Layouts/CommonLayout';
import ClientManagementProduct from '../components/Products/ClientManagementProduct';

const ClientManagement = () => {
  return (
    <CommonLayout>
      <ClientManagementProduct />
    </CommonLayout>
  );
};

export default ClientManagement;
