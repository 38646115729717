import { AIPoweredProjectManagement } from '../Home/Utils';
import FINANCIAL_MANAGEMENT_IMG from './Assets/FINANCIAL MANAGEMENT.png';
import CapacitySection from './CommonCompoenents/CapacitySection';
import CommonHeaderProducts from './CommonCompoenents/CommonHeaderProducts';
import CustomAnalysisSection from './CommonCompoenents/CustomAnalysisSection';
import CXOCommonSection from './CommonCompoenents/CXOCommonSection';
import ProductivitySection from './CommonCompoenents/ProductivitySection';
import SidebarWithImage from './CommonCompoenents/SidebarWithImage';
import {
  FINACE_MODELS_DATA,
  FINANCIAL_MANAGEMENT,
  FINANCIAL_PLANING_AND_TRACKING,
  REAL_TIME_MARGIN_TRACKING_DATA,
  RESULTCOUNTSAIINSIGHT,
  STEPS_DATA_FINANCIAL_MANAGEMENT,
  TRANSPARENT_TRACKING_DATA,
} from './utils';
const FinanaceManagementProduct = () => {
  return (
    <div>
      <CommonHeaderProducts
        StepsData={STEPS_DATA_FINANCIAL_MANAGEMENT}
        ResultCounts={RESULTCOUNTSAIINSIGHT}
        Header={FINANCIAL_MANAGEMENT}
        image={FINANCIAL_MANAGEMENT_IMG}
      />
      <ProductivitySection
        data={FINACE_MODELS_DATA}
        title={'Flexible finance models for every project'}
        description={'Tailor financial management to fit your project’s needs.'}
      />
      <SidebarWithImage
        title="Real time margin tracking"
        description="Stay on top of your project’s profitability."
        data={REAL_TIME_MARGIN_TRACKING_DATA}
      />
      <CXOCommonSection
        data={FINANCIAL_PLANING_AND_TRACKING}
        title="Optimize financial planning and tracking"
        description="Empower your team with accurate, real-time financial data."
        background="white"
      />
      <CapacitySection
        data={AIPoweredProjectManagement}
        title="Advanced talent finder tool"
        description="Find the right talent for your projects effortlessly."
      />
      <CustomAnalysisSection
        data={TRANSPARENT_TRACKING_DATA}
        title="Transparent change log tracking"
        description="Ensure financial accountability and transparency."
        widthLeft="40%"
        widthRight="60%"
      />
    </div>
  );
};

export default FinanaceManagementProduct;
