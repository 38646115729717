import axios from 'axios';
import { useEffect, useState } from 'react';
import { fetchBlogs } from '../../../api/blogApi';
import { fetchCategories } from '../../../api/categoryApi';
import { Blog, Category } from '../../../types/types';

export const useBlog = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [categories, setCategories] = useState<Category[]>([]);
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    Promise.all([
      fetchCategories({ signal: controller.signal }),
      fetchBlogs(
        selectedCategory === 'All' ? '' : selectedCategory,
        currentPage,
        { signal: controller.signal },
      ),
    ])
      .then(([categoriesResponse, blogsResponse]) => {
        // Only update state if data is different
        if (
          JSON.stringify(categories) !==
          JSON.stringify(categoriesResponse?.data)
        ) {
          setCategories(categoriesResponse?.data || []);
        }

        if (JSON.stringify(blogs) !== JSON.stringify(blogsResponse?.data)) {
          setBlogs(blogsResponse?.data || []);
        }

        const newTotalPages = blogsResponse?.meta?.pagination?.pageCount ?? 1;
        if (totalPages !== newTotalPages) {
          setTotalPages(newTotalPages);
        }

        setError(null);
      })
      .catch((err) => {
        if (axios.isAxiosError(err)) {
          setError(err.response?.data?.message || 'Server error');
        } else {
          setError((err as Error)?.message || 'Unknown error');
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => controller.abort();
  }, [selectedCategory, currentPage]);

  return {
    selectedCategory,
    setSelectedCategory,
    currentPage,
    setCurrentPage,
    categories,
    blogs,
    loading,
    error,
    totalPages,
  };
};
