import * as dates from 'date-arithmetic';
import { floor } from 'lodash';
import moment from 'moment';

// function to check odd or even
export function isEven(num: number): boolean {
  return num % 2 === 0;
}

export const getCols = (num: number) => {
  if (num === 1) return 1;
  else if (num >= 2 && num <= 4) return 2;
  else if (num >= 5 && num <= 9) return 3;
  else if (num >= 10 && num <= 12) return 4;
  return 1;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// functions to get array of dates between two dates
export const getDates = (
  startDate: Date | undefined,
  endDate: Date | undefined,
) => {
  const dates = [];
  if (startDate && endDate && startDate <= endDate) {
    let currentDate = startDate;
    while (
      moment(currentDate).toDate() < moment(endDate).add(1, 'day').toDate()
    ) {
      dates.push(new Date(currentDate));
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
  } else {
    dates.push(startDate);
  }
  startDate = undefined;
  endDate = undefined;
  return dates;
};

const toTwoDigit = (digit: number) => `${digit < 10 ? '0' : ''}${digit}`;

export const toHourFormated = (seconds: number) => {
  if (seconds === 0) return '00:00';

  const secondsDuration = moment.duration(seconds, 'seconds');

  return `${toTwoDigit(floor(secondsDuration.asHours()))}:${toTwoDigit(
    secondsDuration.minutes(),
  )}`;
};

export const toHourFormatedInType = (
  input: number,
  inputType: moment.unitOfTime.DurationConstructor,
) => {
  if (input === 0) return '00:00';

  const secondsDuration = moment.duration(input, inputType);

  return `${toTwoDigit(floor(secondsDuration.asHours()))}:${toTwoDigit(
    secondsDuration.minutes(),
  )}`;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

// extract extension from mime type
export const getExtension = (mimeType: string) => {
  if (!mimeType) {
    return;
  }
  const mime = mimeType.split('/');
  return mime[mime.length - 1];
};

export const getNewWindowUrl = (url: string) => {
  return `${window.location.origin}${window.location.pathname}/#${url}`;
};

export function rangeFunc(start: any, end: any, unit: any = 'day') {
  const current = start;
  const days = [];
  days.push(current);
  return days;
}

export function inRange(e: any, start: any, end: any, accessors: any) {
  const eStart = dates.startOf(accessors.start(e), 'day');
  const eEnd = accessors.end(e);
  const startsBeforeEnd = dates.lte(eStart, end, 'day');
  const endsAfterStart = !dates.eq(eStart, eEnd, 'minutes')
    ? dates.gt(eEnd, start, 'minutes')
    : dates.gte(eEnd, start, 'minutes');
  return startsBeforeEnd && endsAfterStart;
}

export const TextTrim = ({
  text,
  Textlength,
}: {
  text: string;
  Textlength: number;
}) => {
  return text?.length > Textlength ? `${text?.slice(0, Textlength)}...` : text;
};

// date format
export const formatDate = ({ blogDate }: { blogDate: string }) => {
  const date = new Date(blogDate);
  return `${date.getDate()} ${date.toLocaleString('en', { month: 'short' })} ${date.getFullYear()}`;
};

// split name
export function splitName({ fullName }: { fullName: string }) {
  fullName = fullName.trim();
  const nameParts = fullName.split(/\s+/);
  let firstName = '';
  let lastName = '';
  if (nameParts.length === 1) {
    firstName = nameParts[0];
  } else if (nameParts.length > 1) {
    lastName = nameParts?.pop() ?? '';
    firstName = nameParts.join(' ');
  }
  return {
    firstName: firstName,
    lastName: lastName,
  };
}
