import { FC } from 'react';

interface TwitterIconProps {
  variant?: 'white' | 'black';
}

const Twitter: FC<TwitterIconProps> = ({ variant = 'black' }) => {
  return (
    <div>
      {variant === 'black' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_6090_15982)">
            <path
              d="M12.8034 11.5913L18.2043 19.3165H15.9878L11.5806 13.0127V13.0123L10.9335 12.087L5.78516 4.72266H8.00168L12.1564 10.6659L12.8034 11.5913Z"
              fill="white"
            />
            <path
              d="M21.4067 0H2.59325C1.16108 0 0 1.16108 0 2.59325V21.4067C0 22.8389 1.16108 24 2.59325 24H21.4067C22.8389 24 24 22.8389 24 21.4067V2.59325C24 1.16108 22.8389 0 21.4067 0ZM15.308 20.3525L10.8481 13.8617L5.26435 20.3525H3.82122L10.2073 12.9295L3.82122 3.63521H8.69203L12.9152 9.78152L18.2026 3.63521H19.6458L13.5562 10.7139H13.5558L20.1788 20.3525H15.308Z"
              fill="#252B37"
            />
          </g>
          <defs>
            <clipPath id="clip0_6090_15982">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_6090_16046)">
            <path
              d="M12.8034 11.5913L18.2043 19.3165H15.9878L11.5806 13.0127V13.0123L10.9335 12.087L5.78516 4.72266H8.00168L12.1564 10.6659L12.8034 11.5913Z"
              fill="white"
            />
            <path
              d="M21.4067 0H2.59325C1.16108 0 0 1.16108 0 2.59325V21.4067C0 22.8389 1.16108 24 2.59325 24H21.4067C22.8389 24 24 22.8389 24 21.4067V2.59325C24 1.16108 22.8389 0 21.4067 0ZM15.308 20.3525L10.8481 13.8617L5.26435 20.3525H3.82122L10.2073 12.9295L3.82122 3.63521H8.69203L12.9152 9.78152L18.2026 3.63521H19.6458L13.5562 10.7139H13.5558L20.1788 20.3525H15.308Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_6090_16046">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default Twitter;
