import { Icon, TooltipHost } from '@fluentui/react';
import CommonButton from './CommonButton';
import { calculatePrice, planDetails } from './Utils';
import patternBg from './svg/Pattern.svg';
const PlanDetails = (option: any) => {
  return (
    <div className="relative w-full">
      {/* Background container */}
      <div
        className="absolute inset-0 w-[98.6dvw] bg-cover bg-center bg-no-repeat h-[810px]"
        style={{
          backgroundImage: `url(${patternBg})`,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />

      <div className="flex lg:gap-8 justify-center relative z-30 lg:flex-row flex-col">
        {planDetails.map((item) => (
          <div
            key={item.planName}
            className={`relative px-6 py-8 lg:w-[32% w-full] h-fit my-8 cursor-pointer bg-white rounded-[4px] border ${
              item.planName === 'Productivity Plus'
                ? 'border-[#FF9900]'
                : 'border-[#E0E0E0]'
            }`}
          >
            {/* Label */}
            {item.label && (
              <span className="text-[#FF9900] bg-[#FF99001A] p-2 rounded-sm">
                {item.label}
              </span>
            )}

            {/* Plan Name */}
            <div
              className={`text-2xl text-primary-text font-bold py-2 mt-2 ${item.label === '' && 'mt-7'}`}
            >
              {item.planName}
            </div>

            {/* Plan Description */}
            <div className="text-base font-normal text-[#404040] py-2">
              {item.planDes}
            </div>

            {/* Pricing */}
            <div className="py-2">
              <span className="text-[28px] text-[#1A1A1A] font-bold">
                {item.planType === 'Free'
                  ? 'Free'
                  : `$${calculatePrice(item.planType, option?.option)}`}
              </span>
              {item.planType !== 'Free' && (
                <span className="text-[#404040] text-xl px-2">
                  per user &nbsp;/
                </span>
              )}
              <span className="text-[#404040] text-xl">
                {item.planType !== 'Free' &&
                  (option?.option === 'Monthly'
                    ? 'month'
                    : option?.option === 'Yearly'
                      ? 'year'
                      : 'lifetime')}
              </span>
            </div>

            {/* Get Started Button */}
            <CommonButton highlight={item.planName === 'Productivity Plus'} />

            {/* Plan Tag */}
            <div className="text-lg text-[#1A1A1A] font-semibold py-2">
              {item.planTag}
            </div>

            {/* Features */}
            {item.planFeature.map((feat, index) => (
              <div key={index} className="py-2 flex justify-between">
                <div className="text-[#1B1B1B] text-base flex gap-3">
                  <Icon
                    iconName="Accept"
                    aria-label="Accept"
                    className="AcceptIcon font-semibold"
                  />
                  {feat.feature}
                </div>
                <div className="text-[#7A7A7A]">
                  <TooltipHost content={feat.featureDes}>
                    <Icon
                      iconName="Info"
                      aria-label="Info"
                      className="InfoIcon font-semibold"
                    />
                  </TooltipHost>
                </div>
              </div>
            ))}

            {/* Compare Plans Link */}
            <div className="flex items-center gap-3 text-[#1A1A1A] ">
              <Icon
                iconName="Checklist"
                aria-label="Checklist"
                className="ChecklistIcon font-semibold"
              />
              <span className="text-base font-bold text-[#1A1A1A] underline">
                Compare plans
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanDetails;
