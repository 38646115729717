import {
  Eye20Regular,
  Heart20Regular,
  ShareAndroid20Regular,
} from '@fluentui/react-icons';
import { FC } from 'react';

const BlogViewShare: FC = () => {
  return (
    <div className="flex items-center justify-between bg-white border-b border-t lg:py-3  py-5 w-full">
      <div className="flex gap-8">
        {/* Likes Section */}
        <div className="text-gray-600 flex gap-1 items-center">
          <Heart20Regular fontSize={16} />
          <span className="text-h4-text font-medium secondary-font">3.6K</span>
        </div>

        {/* Views Section */}
        <p className="ext-gray-600 flex gap-1 items-center">
          <Eye20Regular fontSize={16} />
          <span className="text-h4-text font-medium secondary-font">59</span>
        </p>
      </div>

      <button className="flex items-center gap-2 text-gray-600 hover:text-gray-800">
        <ShareAndroid20Regular fontSize={16} />
        <span className=" text-h4-text font-medium">Share</span>
      </button>
    </div>
  );
};

export default BlogViewShare;
