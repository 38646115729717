import { Link } from '@fluentui/react';
import AddressHeader from './AddressHeader';
import { AddressData } from './utils';

const Address = () => {
  return (
    <div className="flex flex-col justify-between bg-custom-gradient lg:px-[108px] px-4 pt-9 w-full">
      <div className="flex lg:gap-10 lg:flex-row flex-col gap-6">
        {AddressData?.map((val) => (
          <AddressHeader
            title={val?.title}
            description={val?.description}
            type={val?.type}
          />
        ))}
      </div>

      <div className="flex lg:flex-row flex-col gap-10 w-[90%]">
        {/* social icons */}

        <div>
          <div className="flex gap-4 pt-10">
            <Link
              className="h-[25px] w-[25px] cursor-pointer"
              href="https://www.facebook.com/Worxwide"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Worxwide"
            >
              <img
                src="../../../assets/Facebook.png"
                alt="facebook"
                className=" gray-gradient"
              />
            </Link>
            <Link
              className="h-[25px] w-[25px] cursor-pointer"
              href="https://www.instagram.com/worxwide/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Worxwide"
            >
              <img src="../../../assets/Instagram.png" alt="instagram" />
            </Link>
            <Link
              className="h-[25px] w-[25px] cursor-pointer"
              href="https://www.linkedin.com/company/worxwide/mycompany/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Worxwide"
            >
              <img src="../../../assets/LinkedIn.png" alt="linkedIn" />
            </Link>
            <Link
              className="h-[25px] w-[25px] cursor-pointer"
              href="https://twitter.com/worxwide"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Worxwide"
            >
              <img src="../../../assets/Twiter.png" alt="twiter" />
            </Link>
          </div>
        </div>

        {/* banner image */}
        <div className=" flex self-center justify-center items-center">
          <img
            src="../../../assets/ContactBanner.png"
            alt="banner"
            className="lg:w-[40%] w-full pt-8"
          />
        </div>
      </div>
    </div>
  );
};

export default Address;
