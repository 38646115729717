import { MessageBarType } from '@fluentui/react';
import { FormType } from '@worx.squad/app-sdk/base-types';
import {
  Form,
  STextFormFieldStyleEmailNPhoneDark,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../Button/PrimaryButton';
import { useContactUsMutation } from '../Home/home.generated';
import { tryUsSchema } from '../Home/tryUs.schema';
import ContactHeader from './ContactHeader';

export const TryUs = () => {
  const [, contactUs] = useContactUsMutation();
  const { notification } = useNotification();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const tryUsForm = useForm({
    mode: 'all',
    resolver: tryUsSchema,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '',
    },
  });
  const { handleSubmit } = tryUsForm;

  const onSubmit = async (data: any) => {
    setLoader(true);
    try {
      const res = await contactUs({
        object: {
          formType: FormType.ContactUsType,
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone_no,
        },
      });
      if (res.data) {
        notification({
          message:
            'Thank you for contacting us. We will get back to you shortly.',
          type: MessageBarType.success,
        });
        setLoader(false);
        navigate('/thank-you');
        tryUsForm.reset();
      } else {
        notification({
          message: res.error?.message + '',
          type: MessageBarType.error,
        });
      }
    } catch (e) {
      notification({
        message: e + '',
        type: MessageBarType.error,
      });
    }
  };

  const DynamicForm = [
    {
      col: [
        {
          label: 'Email Address',
          name: 'email',
          type: 'email',
          placeholder: 'name@yourcompany.com',
        },
        {
          label: 'Phone Number ( Optional )',
          name: 'phone_no',
          type: 'phone',
          placeholder: '1234567892',
        },
      ],
      row: [
        {
          label: 'First Name',
          name: 'first_name',
          type: 'text',
          placeholder: 'Type here...',
        },
        {
          label: 'Last Name',
          name: 'last_name',
          type: 'text',
          placeholder: 'Type here...',
        },
      ],
    },
  ];

  return (
    <div className="w-full flex flex-col gap-12">
      {/* Title */}
      <ContactHeader
        title="Contact Us"
        description="Feel free to contact us about your digital projects."
      />
      {/* Form Area */}
      <Form
        formHook={tryUsForm}
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-12"
      >
        <div>
          {DynamicForm.map((section, index) => (
            <div key={index} className="flex flex-col gap-6">
              {/* Column Layout */}
              <div className="flex flex-col gap-6">
                {section?.col?.map((field, fieldIndex) => (
                  <div key={fieldIndex} className="flex flex-col gap-1">
                    <label className="text-h3-text text-text-p4">
                      {field.label}
                    </label>
                    <TextField
                      formHook={tryUsForm}
                      name={field.name}
                      styles={STextFormFieldStyleEmailNPhoneDark}
                      placeholder={field.placeholder}
                      className="w-full"
                      borderless
                    />
                  </div>
                ))}
              </div>

              {/* Row Layout */}
              <div className="flex flex-row gap-6">
                {section?.row?.map((field, fieldIndex) => (
                  <div key={fieldIndex} className={`flex flex-col gap-1 w-1/2`}>
                    <label className="text-h3-text text-text-p4">
                      {field.label}
                    </label>
                    <TextField
                      formHook={tryUsForm}
                      name={field.name}
                      styles={STextFormFieldStyleEmailNPhoneDark}
                      placeholder={field.placeholder}
                      borderless
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Submit Button */}
        <div className="w-fit">
          <PrimaryButton
            text="Submit"
            expanded
            type="submit"
            loading={loader}
          />
        </div>
      </Form>
    </div>
  );
};
