import Accordion from './Accordion';
import CardHeader from './CardHeader';
import CxoInsight from './CxoInsight';
import { AiInsightAccordionData } from './Utils';
import EmplooyeeImg from './assets/Employee Report 1.png';
const AiInsights = () => {
  return (
    <section className="flex flex-col lg:gap-20 gap-10 lg:py-20 py-14 lg:pr-[108px] pr-4 h-full">
      {/* insight section */}
      <div className="flex lg:gap-8 lg:flex-row flex-col gap-6 justify-between w-full items-start h-full">
        {/* left side area */}
        <div className="lg:w-[30%] w-full flex flex-col gap-6 justify-start h-full">
          {/* header with title */}
          <CardHeader
            header="AI PREDICTIONS AND INSIGHTS"
            title=" Productivity score cards"
            color="black"
          />
          {/*  accordion*/}
          <div className="w-full">
            <Accordion items={AiInsightAccordionData} customGap="gap-8" />
          </div>
        </div>

        {/* right side area */}
        <div className="p-6 h-full lg:w-[70%] w-full bg-white flex items-center">
          <img
            src={EmplooyeeImg}
            alt="Employee Report 1"
            className="rounded w-full h-full object-fill"
          />
        </div>
      </div>

      {/* cxo insights */}
      <CxoInsight />
    </section>
  );
};

export default AiInsights;
