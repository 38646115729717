import BlogDetailContent from '../components/Blog/BlogDetailsContent';
import { CommonLayout } from '../components/Layouts/CommonLayout';

const BlogDetails = () => {
  return (
    <CommonLayout calendlyWidget={false}>
      <BlogDetailContent />
    </CommonLayout>
  );
};

export default BlogDetails;
