const GanttChartSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M2.33337 7.24951C2.33337 5.45459 3.78845 3.99951 5.58337 3.99951H8.58337V6.99951H6.33337C5.2288 6.99951 4.33337 7.89494 4.33337 8.99951C4.33337 10.1041 5.2288 10.9995 6.33337 10.9995H8.58337V19.9995H5.58337C3.78845 19.9995 2.33337 18.5444 2.33337 16.7495V7.24951ZM9.58337 19.9995V10.9995H10.3334V11.9995C10.3334 13.1041 11.2288 13.9995 12.3334 13.9995H13.3334V14.9995C13.3334 16.0194 14.0968 16.861 15.0834 16.984V19.9995H9.58337ZM12.3334 9.99951H15.0834V3.99951H9.58337V6.99951H10.3334C11.4379 6.99951 12.3334 7.89494 12.3334 8.99951V9.99951ZM18.3334 16.9995H16.0834V19.9995H19.0834C20.8783 19.9995 22.3334 18.5444 22.3334 16.7495V7.24951C22.3334 5.45459 20.8783 3.99951 19.0834 3.99951H16.0834V9.99951H16.3334C17.4379 9.99951 18.3334 10.8949 18.3334 11.9995V12.9995C19.4379 12.9995 20.3334 13.8949 20.3334 14.9995C20.3334 16.1041 19.4379 16.9995 18.3334 16.9995ZM6.33337 7.99951C5.78109 7.99951 5.33337 8.44723 5.33337 8.99951C5.33337 9.5518 5.78109 9.99951 6.33337 9.99951H10.3334C10.8857 9.99951 11.3334 9.5518 11.3334 8.99951C11.3334 8.44723 10.8857 7.99951 10.3334 7.99951H6.33337ZM11.3334 11.9995C11.3334 11.4472 11.7811 10.9995 12.3334 10.9995H16.3334C16.8857 10.9995 17.3334 11.4472 17.3334 11.9995C17.3334 12.5518 16.8857 12.9995 16.3334 12.9995H12.3334C11.7811 12.9995 11.3334 12.5518 11.3334 11.9995ZM15.3334 13.9995C14.7811 13.9995 14.3334 14.4472 14.3334 14.9995C14.3334 15.5518 14.7811 15.9995 15.3334 15.9995H18.3334C18.8857 15.9995 19.3334 15.5518 19.3334 14.9995C19.3334 14.4472 18.8857 13.9995 18.3334 13.9995H15.3334Z"
        fill="#242424"
      />
    </svg>
  );
};

export default GanttChartSvg;
