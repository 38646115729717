import { DefaultButton, MessageBarType, Spinner } from '@fluentui/react';
import { FormType } from '@worx.squad/app-sdk/base-types';
import {
  Form,
  STextFormFieldStyleEmailNPhone,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import AutoScrolling from '../AutoScrolling/AutoScrolling';
import { useContactUsMutation } from '../Home/home.generated';
import { tryUsSchema } from '../Home/tryUs.schema';

export const TryUs = () => {
  const [, contactUs] = useContactUsMutation();
  const { notification } = useNotification();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;
  const tryUsForm = useForm({
    mode: 'all',
    resolver: tryUsSchema,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '',
    },
  });
  const { handleSubmit } = tryUsForm;
  const onSubmit = async (data: any) => {
    setLoader(true);
    try {
      const res = await contactUs({
        object: {
          formType: FormType.ContactUsType,
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone_no,
        },
      });
      if (res.data) {
        notification({
          message:
            'Thank you for contacting us. We will get back to you shortly.',
          type: MessageBarType.success,
        });
        setLoader(false);
        navigate('/thank-you');
        tryUsForm.reset();
      } else {
        notification({
          message: res.error?.message + '',
          type: MessageBarType.error,
        });
      }
    } catch (e) {
      notification({
        message: e + '',
        type: MessageBarType.error,
      });
    }
  };
  return (
    <div className="w-full bg-white flex justify-between items-start overflow-hidden h-full">
      <div className="lg:w-[50%] lg:py-24 lg:px-28 py-14 px-4">
        <h2 className=" text-primary-text text-h2-text  pb-10 lg:px-10 text-start">
          Trusted by <span className=" font-bold">20+</span> leaders, <br />
          Contact Us to learn more
        </h2>
        <Form
          className="flex lg:px-10 px-4 flex-col w-full lg:w-full gap-5"
          formHook={tryUsForm}
          onSubmit={() => handleSubmit(onSubmit)}
        >
          <div className="flex flex-col sm:flex-row gap-5 w-full">
            <label className=" text-secondary-grey2 font-poppins text-h3-text mt-[4px] w-full font">
              Email Address
              <TextField
                formHook={tryUsForm}
                name="email"
                styles={STextFormFieldStyleEmailNPhone}
                placeholder="name@yourcompnay.com"
                className="w-full"
                borderless
              />
            </label>
          </div>
          <div className="flex flex-col sm:flex-row gap-5 mt-[18px] w-full">
            <label className="  text-secondary-grey2 font-poppins text-h3-text w-full">
              Phone Number (Optional)
              <TextField
                formHook={tryUsForm}
                name="phone_no"
                styles={STextFormFieldStyleEmailNPhone}
                placeholder="1234567892"
                className="w-full"
                borderless
              />
            </label>
          </div>
          <div className="flex flex-row gap-5 mt-[18px] w-full">
            <label className="  text-secondary-grey2 font-poppins text-h3-text w-full">
              First Name
              <TextField
                formHook={tryUsForm}
                name="first_name"
                label=""
                styles={STextFormFieldStyleEmailNPhone}
                placeholder="Type here..."
                className="w-full"
                borderless
              />
            </label>
            <label className=" text-secondary-grey2 font-poppins text-h3-text w-full">
              Last Name
              <TextField
                formHook={tryUsForm}
                name="last_name"
                styles={STextFormFieldStyleEmailNPhone}
                placeholder="Type here..."
                className="w-full placeholder:text-[#B8BCCA]"
                borderless
              />
            </label>
          </div>
          <div className="w-auto mt-[24px]">
            <DefaultButton
              onClick={handleSubmit(onSubmit)}
              className="bg-[#FF9900] w-auto border border-[#FF9900] text-white
               hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300 rounded-sm text-base font-medium"
            >
              {loader ? (
                <Spinner />
              ) : (
                <span className="text-[1rem] text-black">Submit</span>
              )}
            </DefaultButton>
          </div>
        </Form>
      </div>
      <div className="w-[calc(50vw-250px)] mt-[15rem] h-full justify-center items-center rotate-90 lg:flex hidden">
        <AutoScrolling />
      </div>
    </div>
  );
};
