import { FC } from 'react';

interface PageHeaderProps {
  title: string;
  description: string;
}

const PageHeader: FC<PageHeaderProps> = ({ title, description }) => {
  return (
    <div className="flex flex-col gap-5 text-start  lg:text-h1-text text-h2-text text-primary-text font-poppins">
      <h1>{title}</h1>
      <p className="text-secondary-text text-lg font-normal">{description}</p>
    </div>
  );
};

export default PageHeader;
