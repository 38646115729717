import { CommonLayout } from '../components/Layouts/CommonLayout';
import EmployeeManagementProduct from '../components/Products/EmployeeManagementProduct';

const EmployeeManagement = () => {
  return (
    <CommonLayout>
      <EmployeeManagementProduct />
    </CommonLayout>
  );
};

export default EmployeeManagement;
