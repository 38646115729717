import { FC } from 'react';

interface SectionHeaderProps {
  title: string;
  description: string;
  variant?: string;
  whiteDescription?: boolean;
  textCenter?: boolean;
  mobileTextCenter?: boolean;
}

const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  description,
  variant = 'black',
  whiteDescription = false,
  textCenter = true,
  mobileTextCenter = false,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <h2
        className={`text-h2-text lg:leading-[3.5rem] ${textCenter && mobileTextCenter ? 'text-center' : textCenter ? ' lg:text-center text-start ' : ' text-start'} ${variant === 'white' ? 'text-white' : 'text-primary-text'} font-poppins`}
      >
        {title}
      </h2>
      <p
        className={`${variant === 'white' ? (whiteDescription ? 'text-white' : 'text-[#D2D2D2]') : 'text-tertiary-text'} text-lg ${textCenter && mobileTextCenter ? 'text-center' : textCenter ? 'lg:text-center text-start' : 'text-start'} font-normal`}
      >
        {description}
      </p>
    </div>
  );
};

export default SectionHeader;
