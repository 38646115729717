import { FC } from 'react';
import { Category } from '../../types/types';

interface NewBlogCategoryProps {
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory: string;
  categories: Category[];
  blogsEmpty: boolean;
}

const NewBlogCategory: FC<NewBlogCategoryProps> = ({
  selectedCategory,
  setSelectedCategory,
  categories,
  blogsEmpty,
}) => {
  // common button
  const categoryButton = ({ category }: { category: Category }) => {
    return (
      <button
        key={category?.id}
        onClick={() => setSelectedCategory(category?.title)}
        className={`px-6 py-3 rounded border ${
          category?.title === selectedCategory
            ? 'bg-[#207C97] text-white'
            : 'border-[#CCC] text-tertiary-text hover:border-brandPrimary hover:text-primary hover:bg-primary-light'
        } transition duration-300 text-h4-tex capitalize`}
      >
        {category?.title}
      </button>
    );
  };

  return (
    <div className="flex-wrap flex gap-3">
      {/* Default "All" category button */}
      {categories?.length !== 0 &&
        categoryButton({
          category: {
            id: 112211,
            title: 'All',
          },
        })}

      {categories?.map((categoryVal: Category) =>
        categoryButton({
          category: categoryVal,
        }),
      )}
    </div>
  );
};

export default NewBlogCategory;
