import { ProductivityNExperiencePlatform } from '../Home/ProductivityNExperiencePlatform';
import { CommonLayout } from '../Layouts/CommonLayout';
import WhitePaperMainArea from './WhitePaperMainArea';

const WhitepaperDetail = () => {
  return (
    <CommonLayout calendlyWidget={false}>
      <div className="relative">
        <div className="lg:py-20 py-14 lg:px-28 p-4">
          <WhitePaperMainArea />
        </div>
        <ProductivityNExperiencePlatform />
      </div>
    </CommonLayout>
  );
};

export default WhitepaperDetail;
