import { Link, Stack, StackItem } from '@fluentui/react';
import { useBoolean } from 'ahooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PrimaryButton from '../Button/PrimaryButton';
import SecondaryButton from '../Button/SecondaryButton';
import { BookADemo } from '../Home/Utils';
import LogoSvg from '../Home/assets/LogoSvg';
import { ChevronIcon } from './Header/ChecvronIcon';
import MobileMenuSidebar from './Header/MobileMenu';
import ProductsNavigation from './Header/ProductsNavigation';
import ResourceNavigation from './Header/ResourceNavigation';

export function Header() {
  const navigate = useNavigate();
  const [productDropdown, setProductDropdown] = useState(false);
  const [resourcesDropdown, setResourcesDropdown] = useState(false);
  const location = useLocation();
  const path = location.pathname === '/thank-you';

  // Helper function to check if current path is under a specific section
  const isPathUnder = (basePath: string) => {
    return location.pathname.startsWith(basePath);
  };

  // Check if current path is related to resources
  const isResourcesActive = () => {
    return isPathUnder('/blog') || isPathUnder('/whitepaper');
  };

  // Check if current path is related to product
  const isProductActive = () => {
    return false;
  };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="sticky bg-primary-text top-0 z-[9999] shadow-lg">
      <StackItem className="px-[32px]  md:px-[20px] lg:px-[40px] max-sm:py-5">
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          className="flex justify-between 2xl:gap-14 gap-8"
        >
          <Link
            className="lg:w-[176px] w-[159px]"
            onClick={() => navigate('/')}
          >
            <LogoSvg />
          </Link>
          <StackItem className="text-h4-text gap-6 2xl:gap-8 flex-wrap lg:flex hidden items-center text-black">
            <Link
              className={`h-[90px] text-white  font-normal hover:text-[#FF9900] font-poppins hover:ease-in-out duration-300 hover:no-underline flex items-center ${isProductActive() ? 'text-[#FF9900]' : ''}`}
              onMouseEnter={() => setProductDropdown(true)}
              onMouseLeave={() => setProductDropdown(false)}
            >
              Product
              <ChevronIcon isOpen={productDropdown} />
            </Link>
            {productDropdown && (
              <ProductsNavigation
                showModal={showModal}
                setProductDropdown={setProductDropdown}
                isModalOpen={isModalOpen}
                hideModal={hideModal}
              />
            )}
            <Link
              className={`text-white  font-normal hover:text-[#FF9900] font-poppins hover:ease-in-out duration-300 hover:no-underline ${location.pathname === '/feature' ? 'text-[#FF9900]' : ''}`}
              onClick={() => navigate('/feature')}
            >
              Features
            </Link>
            <Link
              className={`text-white  font-normal hover:text-[#FF9900]  font-poppins hover:ease-in-out duration-300 hover:no-underline ${location.pathname === '/how-it-works' ? 'text-[#FF9900]' : ''}`}
              onClick={() => navigate('/how-it-works')}
            >
              How It Works
            </Link>
            <Link
              className={`text-white  font-normal hover:text-[#FF9900] font-poppins hover:ease-in-out duration-300 hover:no-underline ${location.pathname === '/pricing' ? 'text-[#FF9900]' : ''}`}
              onClick={() => navigate('/pricing')}
            >
              Pricing
            </Link>
            <Link
              className={`h-[90px] text-white  font-normal hover:text-[#FF9900] font-poppins hover:ease-in-out duration-300 hover:no-underline flex items-center ${isResourcesActive() ? 'text-[#FF9900]' : ''}`}
              onMouseEnter={() => setResourcesDropdown(true)}
              onMouseLeave={() => setResourcesDropdown(false)}
            >
              Resources
              <ChevronIcon isOpen={resourcesDropdown} />
              {resourcesDropdown && (
                <ResourceNavigation
                  showModal={showModal}
                  hideModal={hideModal}
                  isModalOpen={isModalOpen}
                  setResourcesDropdown={setResourcesDropdown}
                />
              )}
            </Link>
            <Link
              className="text-white  font-normal hover:text-[#FF9900] font-poppins hover:ease-in-out duration-300 hover:no-underline"
              href="https://www.worxwide.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Worxwide"
            >
              <span className="flex gap-1 items-center ">
                About Worxwide
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className="transition-colors duration-300"
                >
                  <path d="M8.25904 3.3162L1.80227 9.77297L0.741607 8.71231L7.19838 2.25554L1.25868 2.25554L1.27194 0.757359H9.75722V9.24264L8.25904 9.2559V3.3162Z" />
                </svg>
              </span>
            </Link>

            <Link
              className={`text-white font-normal hover:text-[#FF9900] font-poppins hover:ease-in-out duration-300 hover:no-underline ${location.pathname === '/contact-us' ? 'text-[#FF9900]' : ''}`}
              onClick={() => navigate('/contact-us')}
            >
              Contact Us
            </Link>
            <div className="flex gap-3">
              <SecondaryButton
                text={'Sign Up/Sign In'}
                handleClick={() => navigate('/login')}
              />
              <PrimaryButton text="Book A Demo" handleClick={BookADemo} />
            </div>
          </StackItem>
          <div className="block lg:hidden">
            <MobileMenuSidebar />
          </div>
        </Stack>
      </StackItem>
    </div>
  );
}
