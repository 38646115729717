import { FC } from 'react';

import CapacitySection from '../Products/CommonCompoenents/CapacitySection';
import { AIPoweredProjectManagement } from './Utils';

const AIProjectManagement: FC = () => {
  return (
    <CapacitySection
      data={AIPoweredProjectManagement}
      title="AI-Powered Project Management"
      description="Harness the Power of AI for Smarter Project Management"
    />
  );
};

export default AIProjectManagement;
