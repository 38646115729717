import { FC } from 'react';

interface GradientBorderSectionTitleProps {
  title: string;
}

const GradientBorderSectionTitle: FC<GradientBorderSectionTitleProps> = ({
  title,
}) => {
  return (
    <span className="relative text-h4-text text-[#FF9900] py-2 px-4 before:absolute before:left-0 before:top-0 before:w-full before:h-[1.8px] before:bg-gradient-to-r before:from-transparent before:via-[#4AAACA] before:to-transparent after:absolute after:left-0 after:bottom-0 after:w-full after:h-[1.5px] after:bg-gradient-to-r after:from-transparent after:via-[#4AAACA] after:to-transparent">
      {title}
    </span>
  );
};

export default GradientBorderSectionTitle;
