import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FC } from 'react';
import DownloadApp from '../Home/DownloadApp';
import LinkSvg from '../Home/assets/LinkSvg';
import GradientBorderSectionTitle from '../SquadPricingPlans/GradientBorderSectionTitle';

interface HeroSectionCommonProps {
  header: string;
  title: string;
  description: string;
  video: string;
}

export const HeroSectionCommon: FC<HeroSectionCommonProps> = ({
  header,
  title,
  description,
  video,
}) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  return (
    <div className="text-lg lg:h-screen lg:px-[108px] lg:pt-[56px] lg:pb-[122px] px-4 pt-[60px] pb-[60px] bg-[#1B1B1B] w-full">
      <div className="flex flex-col gap-14  w-full">
        <div className="w-fit self-center">
          <GradientBorderSectionTitle title={header} />
        </div>
        <div className="flex lg:flex-row flex-col w-full justify-between gap-8 items-center">
          {/* title */}
          <div className="flex flex-col gap-12 lg:w-[40%] w-full">
            <div className="flex flex-col gap-4">
              <h1 className="lg:text-h1-text text-h2-text text-[#FBFBFB]">
                {title}
              </h1>
              <p className="text-[#FBFBFB] text-xl-text">{description}</p>
            </div>
            {/* download button */}
            <div className="flex flex-col sm:flex-row gap-4">
              <div>
                <DefaultButton
                  onClick={showModal}
                  className="lg:w-full w-auto border border-[#FF9900] text-primary-text bg-[#FF9900] hover:bg-[#e19a30] hover:text-primary-text px-6 py-6 ease-in-out duration-300"
                >
                  <span className="flex gap-4 justify-center items-center">
                    <span className=" text-h3-text font-semibold">
                      Download Squad For Free
                    </span>
                    <span>
                      <LinkSvg black />
                    </span>
                  </span>
                </DefaultButton>
                <DownloadApp
                  showModal={showModal}
                  isModalOpen={isModalOpen}
                  hideModal={hideModal}
                />
              </div>
            </div>
          </div>

          {/*  video */}
          <div className="lg:w-[60%] w-full max-lg:mt-8">
            <video
              width="100%"
              autoPlay
              loop
              muted
              playsInline
              height={445}
              preload="auto"
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};
