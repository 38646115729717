import { AnimatePresence, motion } from 'framer-motion';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import SquadIcon from './assets/SquadIcon';
import TickIcon from './assets/TickIcon';

interface Section {
  component: React.ReactNode;
  color: string;
}

const ScrollComponent: React.FC<{
  sections?: Section[];
  sectionRefs: MutableRefObject<(HTMLDivElement | null)[]>;
}> = ({ sections = [], sectionRefs }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [viewedSections, setViewedSections] = useState<Set<number>>(new Set());
  const [isFixed, setIsFixed] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;
      const containerRect = containerRef.current.getBoundingClientRect();
      const fixedThreshold = 100;
      setIsFixed(containerRect.top <= fixedThreshold);

      const newSet = new Set(viewedSections);

      // Find the currently visible section
      sectionRefs.current.forEach((section, index) => {
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top <= fixedThreshold + 50) {
            newSet.add(index);
          }
        }
      });

      // Set active index to the latest viewed section
      let latestViewedIndex = -1;
      Array.from(newSet).forEach((index) => {
        if (index > latestViewedIndex) {
          latestViewedIndex = index;
        }
      });

      if (latestViewedIndex >= 0) {
        setActiveIndex(latestViewedIndex);
      }

      setViewedSections(newSet);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [viewedSections]);

  return (
    <div className="relative w-full flex flex-col items-center space-y-10 ">
      <div className="relative w-full" ref={containerRef}>
        {sections.map((section, index) => (
          <div
            key={index}
            className={`sticky flex justify-start lg:pl-16 pl-8 
          ${section.color === 'dark' ? 'bg-background-dark' : section.color === 'secondary-white' ? 'bg-background-light' : 'bg-[#FBFBFB]'}`}
          >
            <div
              ref={(el) => (sectionRefs.current[index] = el)}
              className="scroll-section transition-all duration-500 border-l-2 border-dashed border-gray-300 lg:pl-10 pl-8"
            >
              <div className="w-full flex justify-between ">
                {/* Left Section - Takes 1 column */}
                <div className=" flex flex-col justify-start items-start">
                  {/* Render the component */}
                  <div
                    className={` ${section.color === 'dark' ? 'bg-background-dark' : section.color === 'secondary-white' ? 'bg-background-light' : 'bg-[#FBFBFB]'}`}
                  >
                    {section.component}
                  </div>
                </div>
              </div>

              <div
                className={`absolute ${index === 1 ? 'lg:top-[170px] top-[66px]' : index === 6 ? 'lg:top-[130px] top-[66px]' : 'lg:top-[90px] top-[66px]'} lg:left-10 left-2  transform -translate-y-1/2 flex items-center justify-center w-[52px] h-[52px] text-white rounded-full border-2 border-dashed border-gray-300 `}
              >
                <AnimatePresence>
                  {viewedSections.has(index) ? (
                    <motion.div
                      key="tick"
                      className="relative w-16 h-16 flex items-center justify-center"
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.5, opacity: 0 }}
                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                      {/* Green Circle */}
                      <TickIcon />
                    </motion.div>
                  ) : (
                    <motion.div
                      key={'arrow'}
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.5, opacity: 0 }}
                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                      {/* squad icon */}
                      <SquadIcon />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollComponent;
