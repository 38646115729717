import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import BlogCard from '../Blog/NewBlogCard';
import NewBlogCategory from '../Blog/NewBlogCategory';
import Pagination from '../Blog/Pagination';
import PageHeader from '../CommonComponent/PageHeader';
import ErrorCard from './ErrorCard';
import { useWhitePaper } from './hooks/useWhitePaper';

const Starter: FC = () => {
  // custom hook to fetch data and categories
  const {
    whitePapers,
    categories,
    selectedCategory,
    setSelectedCategory,
    currentPage,
    setCurrentPage,
    loading,
    error,
    totalPages,
  } = useWhitePaper();

  // handle page change
  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setCurrentPage],
  );

  //  error show while data fetching
  if (error) {
    return <ErrorCard error={error} />;
  }

  return (
    <section className="lg:px-[108px] bg-white lg:pb-20 pb-14 px-4">
      <div className="lg:py-16 py-8">
        <PageHeader
          title="Industry leading research"
          description="Explore how companies are leveraging digital for embracing productivity and employee experience."
        />
      </div>
      {/* blog category */}
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-6">
          <h2 className="text-h2-text text-primary-text text-start">
            Whitepapers
          </h2>
          <NewBlogCategory
            selectedCategory={selectedCategory}
            setSelectedCategory={(category) => {
              setSelectedCategory(category);
              setCurrentPage(1);
            }}
            categories={categories}
            blogsEmpty={whitePapers?.length === 0}
          />
        </div>

        {/* blogs */}
        {loading ? (
          <div className="grid gap-x-8 gap-y-12 sm:grid-cols-1 lg:grid-cols-2 w-full">
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className="bg-gray-200 h-80 rounded-md animate-pulse"
                ></div>
              ))}
          </div>
        ) : whitePapers?.length === 0 ? (
          <div className="py-12 text-center">
            <p className="text-lg-text text-secondary-text">
              No whitepaers found{' '}
              {`${selectedCategory !== 'All' ? 'for this category' : ''} `}
            </p>
          </div>
        ) : (
          <div className="grid gap-x-8 gap-y-12 sm:grid-cols-1 lg:grid-cols-2">
            {whitePapers?.map((whitepaper) => (
              <Link to={`/whitepaper/${whitepaper?.slug}`}>
                <BlogCard key={whitepaper?.id} blog={whitepaper} />
              </Link>
            ))}
          </div>
        )}

        {/* pagination */}
        {whitePapers?.length > 0 && totalPages > 1 && (
          <div className="pt-9">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default React.memo(Starter);
