import AiAssistanceImg1 from './assets/AiAssistant1.png';
import AiAssistanceImg2 from './assets/AiAssitant2.png';
import CardHeader from './CardHeader';
import MagicPlanner from './MagicPlanner';

const AiAssistance = () => {
  return (
    <section className="lg:py-20 py-14 lg:pr-[108px] pr-4  flex flex-col lg:gap-20 gap-10">
      {/* Ai assistant */}
      <div className="flex lg:gap-14 gap-6 justify-between items-center lg:flex-row flex-col">
        <div className="flex lg:w-[50%] w-full flex-col gap-4">
          <CardHeader
            header="AI PREDICTIONS AND INSIGHTS"
            title="AI assistant to ease out your work & tracking"
            color="black"
          />
          <p className="text-h3-text text-tertiary-text">
            Missed a meeting or want to see how you did last week, AI partner
            works with you to assist when required.
          </p>
        </div>
        <div className="lg:w-[50%] w-full p-6 bg-white flex flex-col gap-2.5 justify-start">
          <img
            src={AiAssistanceImg1}
            alt="AI Assistance1"
            className="rounded shadow-md h-[172px] object-contain self-start w-[70%] "
          />
          <img
            src={AiAssistanceImg2}
            alt="AI Assistance2"
            className="rounded shadow-md h-[135px] object-contain self-start w-[65%]"
          />
        </div>
      </div>

      {/*  magic planner */}
      <MagicPlanner />
    </section>
  );
};

export default AiAssistance;
