import { FC } from 'react';
import { CommonCardDataType } from '../../../types/types';
import WhiteBgButton from '../../Button/WhiteBgButton';
import SectionHeader from '../../CommonComponent/SectionHeader';
import { BookADemo } from '../../Home/Utils';

interface IntegrationCapabilityProps {
  title: string;
  description: string;
  data: CommonCardDataType[];
}

const IntegrationCapability: FC<IntegrationCapabilityProps> = ({
  title,
  description,
  data,
}) => {
  return (
    <div className="lg:py-20 py-14 lg:px-[108px] px-4 items-center justify-center flex flex-col gap-14 bg-white border border-b-primary">
      <SectionHeader title={title} description={description} />

      {/* cards */}
      <div className="flex lg:gap-6 gap-12 lg:flex-row flex-col">
        {data?.map((val) => (
          <div className="lg:p-8 rounded flex flex-col gap-8" key={val?.id}>
            <div className="flex flex-col gap-3">
              <h4 className=" text-primary-text text-lg-text">{val?.title}</h4>
              <p className=" text-secondary-text  text-h3-text">
                {val?.description}
              </p>
            </div>
            {/* image */}
            <img
              src={val?.image}
              alt={`${val?.title}_img`}
              className="mt-auto"
            />
          </div>
        ))}
      </div>
      {/* learn more */}
      <WhiteBgButton
        text="Get started"
        expanded
        border
        handleClick={BookADemo}
      />
    </div>
  );
};

export default IntegrationCapability;
