import InsightVideo from '../Feature/assets/InsightVideo.mov';
import { HeroSectionCommon } from '../Feature/HeroSection';
export const Starter = () => {
  return (
    <div>
      <HeroSectionCommon
        header="PSA LIFECYCLE WITH SQUAD"
        title="Unified service lifecycle"
        description="Resource utilization, project deviations,  skill deviations and much more."
        video={InsightVideo}
      />
    </div>
  );
};
