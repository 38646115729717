// dynamic form fields
export const fields = [
  { label: 'Full Name', name: 'fullName', placeholder: 'John Doe' },
  {
    label: 'Designation',
    name: 'designation',
    placeholder: 'Your Designation',
  },
  {
    label: 'Email Address',
    name: 'email',
    placeholder: 'name@yourcompany.com',
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    placeholder: 'IN +91 1234567890 ',
  },
];
