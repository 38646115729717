import { CommonLayout } from '../components/Layouts/CommonLayout';
import AIPredictionAndInsights from '../components/Products/AIPredictionAndInsights';

export const AiInsights = () => {
  return (
    <CommonLayout>
      <AIPredictionAndInsights />
    </CommonLayout>
  );
};
