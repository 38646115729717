import { FC } from 'react';
import { formatDate } from '../helpers/functions';

interface HeroSectionProps {
  bannerMedia: string;
  bannerTitle: string;
  date: string;
  ReadMinutes: string;
}

const HeroSection: FC<HeroSectionProps> = ({
  bannerMedia,
  bannerTitle,
  date,
  ReadMinutes,
}) => {
  return (
    <div className="h-[462px] md:mt-16 mt-6 rounded-xl overflow-hidden relative">
      {/* Background Image */}
      <img
        src={bannerMedia}
        alt={`banner-img-${bannerTitle}`}
        className="absolute inset-0 w-full h-full object-fill"
      />
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-blog-card-gradient"></div>

      {/* Centered Content */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center px-8 flex flex-col gap-5 max-w-6xl">
          <p className="text-white text-h3-text">
            {formatDate({ blogDate: date })} | {ReadMinutes} mins read
          </p>
          <h1 className="text-white text-h2-text">{bannerTitle}</h1>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
