import { FC } from 'react';
import SidebarWithImage from '../Products/CommonCompoenents/SidebarWithImage';
import { items } from './Utils';

const AIPrediction: FC = () => {
  return (
    <SidebarWithImage
      data={items}
      title="Productivity focused and feature packed"
      description="Discover the features that supercharge your productivity and elevate experience"
    />
  );
};

export default AIPrediction;
