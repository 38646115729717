import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetProjectsByOrganizationIdQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  projectTitle?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetProjectsByOrganizationIdQuery = {
  __typename?: 'query_root';
  project: Array<{
    __typename?: 'project';
    id: any;
    title: string;
    description?: string | null;
    is_billable: boolean;
    project_client?: {
      __typename?: 'project_client';
      id: any;
      client_name: string;
    } | null;
  }>;
  project_aggregate: {
    __typename?: 'project_aggregate';
    aggregate?: {
      __typename?: 'project_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetClientsByOrganizationIdQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid'];
  clientName?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetClientsByOrganizationIdQuery = {
  __typename?: 'query_root';
  project_client: Array<{
    __typename?: 'project_client';
    id: any;
    client_name: string;
  }>;
  project_client_aggregate: {
    __typename?: 'project_client_aggregate';
    aggregate?: {
      __typename?: 'project_client_aggregate_fields';
      count: number;
    } | null;
  };
};

export type CreateProjectByAdminMutationVariables = Types.Exact<{
  client: Types.ClientInput;
  project: Types.ProjectInput;
}>;

export type CreateProjectByAdminMutation = {
  __typename?: 'mutation_root';
  createProject?: { __typename?: 'CreateProjectOutput'; projectId: any } | null;
};

export type CreateClientMutationVariables = Types.Exact<{
  object: Types.Project_Client_Insert_Input;
}>;

export type CreateClientMutation = {
  __typename?: 'mutation_root';
  insert_project_client_one?: { __typename?: 'project_client'; id: any } | null;
};

export type UpdateProjectMutationVariables = Types.Exact<{
  projectId: Types.Scalars['uuid'];
  data: Types.Project_Set_Input;
}>;

export type UpdateProjectMutation = {
  __typename?: 'mutation_root';
  update_project_by_pk?: { __typename?: 'project'; id: any } | null;
};

export type DeleteProjectMutationVariables = Types.Exact<{
  projectId: Types.Scalars['uuid'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'mutation_root';
  deleteProject?: { __typename?: 'deleteProjectOutput'; status: string } | null;
};

export type UpdateClientMutationVariables = Types.Exact<{
  clientId: Types.Scalars['uuid'];
  data: Types.Project_Client_Set_Input;
}>;

export type UpdateClientMutation = {
  __typename?: 'mutation_root';
  update_project_client_by_pk?: {
    __typename?: 'project_client';
    id: any;
  } | null;
};

export type DeleteClientMutationVariables = Types.Exact<{
  clientId: Types.Scalars['uuid'];
}>;

export type DeleteClientMutation = {
  __typename?: 'mutation_root';
  delete_project_client_by_pk?: {
    __typename?: 'project_client';
    id: any;
  } | null;
};

export type DeleteClientSoftMutationVariables = Types.Exact<{
  clientId: Types.Scalars['uuid'];
}>;

export type DeleteClientSoftMutation = {
  __typename?: 'mutation_root';
  update_project_client_by_pk?: {
    __typename?: 'project_client';
    id: any;
  } | null;
};

export function useGetProjectsByOrganizationIdQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetProjectsByOrganizationIdQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetProjectsByOrganizationIdQuery,
    Operations.GetProjectsByOrganizationIdQueryVariables
  >({ query: Operations.GetProjectsByOrganizationIdDocument, ...options });
}

export function useGetClientsByOrganizationIdQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetClientsByOrganizationIdQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetClientsByOrganizationIdQuery,
    Operations.GetClientsByOrganizationIdQueryVariables
  >({ query: Operations.GetClientsByOrganizationIdDocument, ...options });
}

export function useCreateProjectByAdminMutation() {
  return Urql.useMutation<
    Operations.CreateProjectByAdminMutation,
    Operations.CreateProjectByAdminMutationVariables
  >(Operations.CreateProjectByAdminDocument);
}

export function useCreateClientMutation() {
  return Urql.useMutation<
    Operations.CreateClientMutation,
    Operations.CreateClientMutationVariables
  >(Operations.CreateClientDocument);
}

export function useUpdateProjectMutation() {
  return Urql.useMutation<
    Operations.UpdateProjectMutation,
    Operations.UpdateProjectMutationVariables
  >(Operations.UpdateProjectDocument);
}

export function useDeleteProjectMutation() {
  return Urql.useMutation<
    Operations.DeleteProjectMutation,
    Operations.DeleteProjectMutationVariables
  >(Operations.DeleteProjectDocument);
}

export function useUpdateClientMutation() {
  return Urql.useMutation<
    Operations.UpdateClientMutation,
    Operations.UpdateClientMutationVariables
  >(Operations.UpdateClientDocument);
}

export function useDeleteClientMutation() {
  return Urql.useMutation<
    Operations.DeleteClientMutation,
    Operations.DeleteClientMutationVariables
  >(Operations.DeleteClientDocument);
}

export function useDeleteClientSoftMutation() {
  return Urql.useMutation<
    Operations.DeleteClientSoftMutation,
    Operations.DeleteClientSoftMutationVariables
  >(Operations.DeleteClientSoftDocument);
}
