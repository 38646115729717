const LightBulbPerson = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M16.0889 19.4995L15.914 20.257C15.6907 21.2203 14.8679 21.9179 13.8952 21.9925L13.722 21.9991H11.6103C10.6209 21.9991 9.75513 21.3542 9.46341 20.4227L9.41798 20.2554L9.24337 19.4995H16.0889ZM16.4364 17.9995L16.6452 17.0982C15.6122 16.108 14.2105 15.4995 12.6666 15.4995C11.1229 15.4995 9.72122 16.1079 8.68832 17.0979L8.89653 17.9995H16.4364ZM12.6666 13.9995C11.0313 13.9995 9.52248 14.5409 8.30957 15.4543L8.25463 15.2159C8.24365 15.1683 8.21897 15.125 8.18364 15.0913C6.35279 13.3438 5.41663 11.387 5.41663 9.25049C5.41663 5.24642 8.66256 2.00049 12.6666 2.00049C16.6707 2.00049 19.9166 5.24642 19.9166 9.25049C19.9166 11.3871 18.981 13.3438 17.1513 15.0912C17.1159 15.1249 17.0913 15.1682 17.0803 15.2157L17.025 15.4552C15.8118 14.5413 14.3025 13.9995 12.6666 13.9995ZM10.9166 9.24951C10.9166 8.28301 11.7001 7.49951 12.6666 7.49951C13.6331 7.49951 14.4166 8.28301 14.4166 9.24951C14.4166 10.216 13.6331 10.9995 12.6666 10.9995C11.7001 10.9995 10.9166 10.216 10.9166 9.24951ZM12.6666 5.99951C10.8717 5.99951 9.41663 7.45459 9.41663 9.24951C9.41663 11.0444 10.8717 12.4995 12.6666 12.4995C14.4616 12.4995 15.9166 11.0444 15.9166 9.24951C15.9166 7.45459 14.4616 5.99951 12.6666 5.99951Z"
        fill="#242424"
      />
    </svg>
  );
};

export default LightBulbPerson;
