import { FC } from 'react';

interface AddressHeaderProps {
  title: string;
  description: string;
  type: string;
}

const AddressHeader: FC<AddressHeaderProps> = ({
  title,
  description,
  type,
}) => {
  return (
    <div className={`flex flex-col lg:gap-3 gap-1.5 lg:w-1/3 w-full`}>
      <h3 className="text-2xl-text text-primary-light">{title}</h3>
      <p className=" text-h3-text text-text-p4">{description}</p>
    </div>
  );
};

export default AddressHeader;
