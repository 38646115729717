import { FC } from 'react';
import VectorSvg2 from './Assets/BgVector2.svg';
import Linkedin from './Assets/Linkedin';
import Twitter from './Assets/Twitter';
import { shareOnLinkedIn, shareOnTwitter } from './Utils';

interface BottomShareAreaProps {
  blogTitle?: string;
  blogUrl?: string;
}

const BottomShareArea: FC<BottomShareAreaProps> = ({
  blogTitle = '',
  blogUrl = '',
}) => {
  // trigger social function
  const handleClick = (social: string) => {
    if (social === 'twitter') {
      shareOnTwitter({ blogTitle, blogUrl });
    } else {
      shareOnLinkedIn({ blogUrl });
    }
  };

  return (
    <div className="flex flex-col gap-6 w-full mt-11">
      <hr className="bg-[#6C7880] h-[2px]" />
      <div
        className="bg-primary rounded-sm px-5 py-6 flex justify-between gap-6"
        style={{
          backgroundImage: `url(${VectorSvg2})`,
          objectFit: 'cover',
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <h4 className="text-h4-text-subtitle text-white secondary-font">
          Like what you see? Share with a friend.
        </h4>
        {/* share icons */}
        <div className="flex gap-4">
          <div
            onClick={() => handleClick('twitter')}
            className="cursor-pointer"
          >
            <Twitter variant="white" />
          </div>
          <div
            onClick={() => handleClick('linkedin')}
            className="cursor-pointer"
          >
            <Linkedin variant="white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomShareArea;
