import { FC, useEffect, useRef, useState } from 'react';
import { CommonDataType } from '../../../types/types';
import GanttChartSvg from '../../Home/assets/GanttChart';
import LightBulbPerson from '../../Home/assets/LightBulbPerson';
import TabDesktopSparkleSvg from '../../Home/assets/TabDesktopSparkleSvg';

interface RobustCardProps {
  data: CommonDataType[];
  index: number;
  value: CommonDataType;
}

const RobustCard: FC<RobustCardProps> = ({ data, index, value }) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const headingRefs = useRef<(HTMLHeadingElement | null)[]>([]);
  useEffect(() => {
    headingRefs.current = headingRefs?.current?.slice(0, data?.length);
  }, [data]);

  useEffect(() => {
    const calculateMaxHeight = () => {
      const heights = headingRefs.current.map((ref) => ref?.offsetHeight || 0);
      const max = Math.max(...heights);
      setMaxHeight(max > 0 ? max : 0);
    };
    calculateMaxHeight();
    window.addEventListener('resize', calculateMaxHeight);
    return () => window.removeEventListener('resize', calculateMaxHeight);
  }, []);

  return (
    <div
      key={index}
      className="p-8 flex flex-col bg-white rounded w-full h-full"
    >
      <div className="mb-8">
        {index === 0 ? (
          <TabDesktopSparkleSvg />
        ) : index === 1 ? (
          <GanttChartSvg />
        ) : (
          <LightBulbPerson />
        )}
      </div>
      <div className="flex flex-col flex-grow">
        <div
          style={{ minHeight: maxHeight > 0 ? `${maxHeight}px` : 'auto' }}
          className="mb-4"
        >
          <h2
            ref={(el) => (headingRefs.current[index] = el)}
            className="text-2xl-text font-semibold text-primary-text"
          >
            {value?.title}
          </h2>
        </div>
        <p className="text-h3-text text-primary-text">{value?.description}</p>
      </div>
    </div>
  );
};

export default RobustCard;
