import { ArrowRight16Regular } from '@fluentui/react-icons';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CommonCardDataType } from '../../../types/types';

interface CXOCardsProps {
  data: CommonCardDataType;
  length: number;
  hideImg?: boolean;
}

const CXOCards: FC<CXOCardsProps> = ({ data, length, hideImg = false }) => {
  return (
    <div
      className={`rounded border border-primary flex flex-col  gap-8 
                ${data?.imgAbove ? 'lg:w-1/2 w-full' : length > 2 && length < 4 ? 'lg:w-1/3 w-full' : length > 3 ? 'w-full' : 'lg:w-1/2 w-full'}`}
    >
      {/* above image  */}
      {data?.imgAbove && !hideImg && (
        <div className="bg-card-bg-gradient p-8">
          <img
            src={data?.image}
            alt={`${data?.title}_img`}
            className="h-[283px] rounded w-full object-cover"
          />
        </div>
      )}

      {/* Text */}
      <div className="flex flex-col flex-grow min-h-[150px] gap-4 p-8">
        <h4 className="text-lg-text text-primary-text font-semibold">
          {data?.title}
        </h4>
        <p className="text-tertiary-text text-h3-text">{data?.description}</p>
        {/* Learn More Button */}
        <div className="mt-auto flex">
          <Link
            to=""
            className="text-h3-text text-black hover:text-primary-accent flex gap-1 items-center ease-in-out duration-300"
          >
            <span> Learn More</span>
            <span>
              <ArrowRight16Regular />
            </span>
          </Link>
        </div>
      </div>

      {/* Image*/}
      {!data?.imgAbove && !hideImg && (
        <div className="w-full  ">
          <img
            src={data?.image}
            alt={`${data?.title}_img`}
            className={`w-full h-[283px] rounded p-8 object-cover`}
          />
        </div>
      )}
    </div>
  );
};

export default CXOCards;
