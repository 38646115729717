import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import Contact from '../components/Contact/Contact';
import { CommonLayout } from '../components/Layouts/CommonLayout';

const ContactUs = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  return (
    <CommonLayout calendlyWidget={false} productivity={false}>
      <div className="relative">
        <Contact />
      </div>
    </CommonLayout>
  );
};

export default ContactUs;
