import {
  FontWeights,
  getTheme,
  mergeStyleSets,
  MessageBarType,
  Modal,
  Spinner,
} from '@fluentui/react';
import { DismissFilled } from '@fluentui/react-icons';
import {
  DefaultButton,
  IButtonStyles,
  IconButton,
} from '@fluentui/react/lib/Button';
import { FormType } from '@worx.squad/app-sdk/base-types';
import {
  Form,
  STextFormFieldStyleEmailNPhone,
  STextFormFieldStyleMobileView,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useContactUsMutation } from '../Contact/home.generated';
import LinkSvg from '../Home/assets/LinkSvg';
import { partnerSchema } from './partnerSchema';

interface Props {
  isModalOpenOne: boolean;
  hideModalOne: () => void;
  showModalOne: () => void;
}
export default function JoinAsPartnerModal({
  isModalOpenOne,
  hideModalOne,
}: Props) {
  const [, contactUs] = useContactUsMutation();
  const { notification } = useNotification();
  const navigate = useNavigate();
  const theme = getTheme();
  const [loader, setLoader] = useState(false);
  const isMobile = window.innerWidth < 768;
  const tryUsForm = useForm({
    mode: 'all',
    resolver: partnerSchema,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '',
      description: '',
    },
  });
  const { handleSubmit } = tryUsForm;

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      padding: '0px 12px',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 16px',
      },
    ],
    heading: {
      color: theme.palette.neutralPrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      flex: '4 4 auto',
      padding: '12px 16px',
      overflowY: 'hidden',
      width: '100%',
    },
  });
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  const onSubmit = async (data: any) => {
    console.log('form type:', data);
    setLoader(true);
    try {
      const res = await contactUs({
        object: {
          formType: FormType.PartnerType,
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone_no,
          description: data?.description,
        },
      });
      if (res.data) {
        notification({
          message:
            'Thank you for contacting us. We will get back to you shortly.',
          type: MessageBarType.success,
        });
        setLoader(false);
        navigate('/thank-you');
        tryUsForm.reset();
      } else {
        notification({
          message: res.error?.message + '',
          type: MessageBarType.error,
        });
      }
    } catch (e) {
      notification({
        message: e + '',
        type: MessageBarType.error,
      });
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpenOne}
        onDismiss={hideModalOne}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <div className="text-[#000000] text-4xl font-semibold leading-[2.5rem] sm:leading-[3.5rem] flex items-center flex-wrap sm:flex-wrap gap-4">
            Join as a Partner
          </div>

          <IconButton
            styles={iconButtonStyles}
            onRenderIcon={() => <DismissFilled />}
            ariaLabel="Close popup modal"
            onClick={hideModalOne}
          />
        </div>
        <div className={contentStyles.body}>
          <Form
            className="flex px-2 flex-col w-full sm:w-full gap-5 max-w-xl"
            formHook={tryUsForm}
            onSubmit={() => handleSubmit(onSubmit)}
          >
            <div className="flex flex-col sm:flex-row gap-5">
              <label className=" text-tertiary-text font-poppins mt-[4px] w-full">
                Email Address
                <TextField
                  formHook={tryUsForm}
                  name="email"
                  styles={
                    isMobile
                      ? STextFormFieldStyleMobileView
                      : STextFormFieldStyleEmailNPhone
                  }
                  placeholder="name@yourcompnay.com"
                  className="w-full"
                  borderless
                />
              </label>
            </div>
            <div className="flex flex-col sm:flex-row gap-5 mt-[18px] w-full">
              <label className=" text-tertiary-text font-poppins w-full">
                Phone Number (Optional)
                <TextField
                  formHook={tryUsForm}
                  name="phone_no"
                  styles={
                    isMobile
                      ? STextFormFieldStyleMobileView
                      : STextFormFieldStyleEmailNPhone
                  }
                  placeholder="1234567892"
                  className="w-full"
                  borderless
                />
              </label>
            </div>

            <div className="flex flex-col sm:flex-row gap-5 mt-[18px]">
              <label className=" text-tertiary-text font-poppins w-full">
                First Name
                <TextField
                  formHook={tryUsForm}
                  name="first_name"
                  label=""
                  styles={
                    isMobile
                      ? STextFormFieldStyleMobileView
                      : STextFormFieldStyleEmailNPhone
                  }
                  placeholder="Type here..."
                  className="w-full"
                  borderless
                />
              </label>
              <label className=" text-tertiary-text font-poppins w-full">
                Last Name
                <TextField
                  formHook={tryUsForm}
                  name="last_name"
                  styles={
                    isMobile
                      ? STextFormFieldStyleMobileView
                      : STextFormFieldStyleEmailNPhone
                  }
                  placeholder="Type here..."
                  className="w-full"
                  borderless
                />
              </label>
            </div>
            <div className="w-full">
              <label className=" text-tertiary-text font-poppins mb-2">
                Description
              </label>
              <TextField
                formHook={tryUsForm}
                name="description"
                multiline
                rows={4}
                className="mt-4"
              />
            </div>
            <div className="w-full my-4">
              <DefaultButton
                onClick={handleSubmit(onSubmit)}
                className="bg-[#FF9900] w-full sm:w-auto border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-4 py-4 ease-in-out duration-300"
              >
                {loader ? (
                  <Spinner />
                ) : (
                  <span className="flex justify-center items-center gap-4">
                    <span className="text-[1rem]">Join</span>
                    <span>
                      <LinkSvg />
                    </span>
                  </span>
                )}
              </DefaultButton>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
