import OpEfficiencySvg from '../Home/assets/OpEfficiencySvg';
import AiAssistance from './AiAssistance';
import AiInsights from './AiInsights';
import EngagementSvg from './assets/EngagementSvg';
import TalentSvg from './assets/TalentSvg';
import EmployeeWellness from './EmployeeWellness';
import FeatureProductivityTwo from './FeatureProductivityTwo';
import FinancePlanner from './FinancePlanner';
import GoalsAndPerformance from './GoalsAndPerformance';
import ProjectManagements from './ProjectManagement';
import ResourceManagement from './ResourceManagement';
import TimeSheetAndLeave from './TimeSheetAndLeave';
import UpSkillAndAssessment from './UpSkillAndAssessment';

export const productivityItems = [
  {
    title: 'Employee Report Cards',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval.',
  },
  {
    title: 'Manage Employee Productivity',
    content:
      'Monitor real-time progress of quarterly or annual goals and KPIs on dashboards.',
  },
  {
    title: 'Track Goals & Employee Performance',
    content:
      'Analyse time spent grid behaviours like active time, burnout time, meetings time, tasks time, social time.',
  },
  {
    title: 'Manage Activity & Employee Capacity',
    content:
      'Analyse skill deviations for a role through assessments and suggest trainings to upskill. Remember, less skills equals less productivity.',
  },
];

export const productivityItemsTwo = [
  {
    id: '1',
    title: 'Chat, Call, Meet, Broadcast',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval.',
  },
  {
    id: '2',
    title: 'Custom Emoji’s & Status',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval.',
  },
  {
    id: '3',
    title: 'Group Calls & Meetings',
    content:
      'Monitor real-time progress of quarterly or annual goals and KPIs on dashboards.',
  },
  {
    id: '4',
    title: 'Guest Access',
    content:
      'Analyse time spent grid behaviours like active time, burnout time, meetings time, tasks time, social time.',
  },
];

export const cxoInsightList = [
  {
    title: 'Operational Efficiency',
    content: 'Manage employee activity with active and away hours ',
    icon: <OpEfficiencySvg />,
  },
  {
    title: 'Talent Outlook',
    content: 'Content Needed',
    icon: <TalentSvg />,
  },
  {
    title: 'Employee Engagement',
    content: 'Content Needed ',
    icon: <EngagementSvg />,
  },
];

// AiInsightAccordionData
export const AiInsightAccordionData = [
  {
    id: '1',
    title: 'Employee Report Cards',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval',
  },
  {
    id: '2',
    title: 'Billability & Project Performance',
    content:
      'Track and analyze project performance metrics and billability rates across teams and departments.',
  },
  {
    id: '3',
    title: 'Track Goals & Employee Performance',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval',
  },
  {
    id: '4',
    title: 'Manage Activity & Employee Capacity',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval',
  },
];

// ProjectManagementCardsData
export const ProjectManagementCardsData = [
  {
    id: 1,
    title: 'Tasks, Sprints, Milestones',
    description:
      'Work the way you like with sprints, sections, timeline, list and board views',
  },
  {
    id: 2,
    title: 'Workflows, Quality Management',
    description:
      'Define custom workflows and benchmarks to automate and track quality',
  },
  {
    id: 3,
    title: 'Project Insights and Updates',
    description:
      'Get project insights and updates to monitor progress and keep everyone informed.',
  },
];

export const sections = [
  {
    header: 'AI Predictions and Insights',
    component: <AiInsights />,
    color: 'secondary-white',
  },
  {
    component: <AiAssistance />,
    color: 'secondary-white',
  },
  {
    header: 'Project Management',
    component: <ProjectManagements />,
    color: 'dark',
  },
  {
    header: 'Financial Management',
    component: <FinancePlanner />,
    color: 'white',
  },
  {
    header: 'Resource Management',
    component: <ResourceManagement />,
    color: 'dark',
  },
  {
    header: 'Employee Management',
    component: <UpSkillAndAssessment />,
    color: 'white',
  },
  {
    component: <GoalsAndPerformance />,
    color: 'secondary-white',
  },
  {
    component: <FeatureProductivityTwo />,
    color: 'secondary-white',
  },
  {
    component: <TimeSheetAndLeave />,
    color: 'dark',
  },
  {
    component: <EmployeeWellness />,
    color: 'white',
  },
];
