import EmployeeWellnessImg from './assets/EmployeeWellnessImg.png';
import KeyStress from './assets/KeyStress.png';
import ScreenTime from './assets/ScreenTime.png';
import Wellness from './assets/Wellness.png';
import CardHeader from './CardHeader';

const EmployeeWellness = () => {
  return (
    <section className="lg:pr-[108px] pr-4 lg:py-20 py-14 flex flex-col lg:gap-20 gap-10">
      <div className="flex justify-between lg:gap-14 gap-6 w-full lg:flex-row flex-col">
        <div className="flex gap-8 flex-col">
          <CardHeader
            header="EMPLOYEE WELLNESS"
            title="Employee Wellness & Stress Assessmen"
            color="black"
          />
          <p className=" text-lg-text font-normal text-primary-text-black">
            Manage employee wellness with our built in wellness tools like
            stress surveys, screen time reminders etc.
          </p>
        </div>
        <div className="lg:w-[60%] w-full">
          <img
            src={EmployeeWellnessImg}
            alt="EmployeeWellness"
            className=" rounded"
          />
        </div>
      </div>
      <div className="flex justify-between gap-9 lg:flex-row flex-col">
        <div className="lg:w-1/3 w-full bg-white rounded">
          <img src={ScreenTime} alt="ScreenTime" className=" rounded" />
        </div>
        <div className="lg:w-1/3 w-full">
          <img src={KeyStress} alt="KeyStress" className=" rounded" />
        </div>
        <div className="lg:w-1/3 w-full">
          <img src={Wellness} alt="Wellness" className=" rounded" />
        </div>
      </div>
    </section>
  );
};

export default EmployeeWellness;
