import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export const CommonCard = ({
  page,
}: {
  page: { title: string; description: string; url: string; icon: ReactElement };
}) => {
  const navigate = useNavigate();
  return (
    <div
      key={page.title}
      className="px-4 py-3 flex gap-4 hover:bg-light-Background-1-hover group items-start cursor-pointer font-poppins"
      onClick={() => navigate(page?.url)}
    >
      {/* Gradient Border on Hover */}
      <div className="relative flex items-center justify-center">
        <div className="w-11 h-11 p-[1px] rounded-full bg-white border border-[#E0E0E0] group-hover:bg-gradient-to-r group-hover:from-primary-brand  group-hover:to-[#185D741A] transition-all duration-300">
          <div className="w-full h-full flex items-center group-hover:scale-100 scale-90 justify-center rounded-full bg-white group-hover:text-primary-brand duration-300">
            {page.icon}
          </div>
        </div>
      </div>

      <div className="flex flex-col overflow-hidden gap-1">
        <h4 className="text-h4-text-subtitle group-hover:text-primary-text truncate text-secondary-text font-poppins">
          {page?.title}
        </h4>
        <p className="text-h4-text text-secondary-text line-clamp-2 font-poppins">
          {page?.description}
        </p>
      </div>
    </div>
  );
};
