import CardHeader from './CardHeader';
import Approve from './assets/Approve.png';
import Calender from './assets/Calender.png';
import TimeSheet from './assets/TimeSheet.png';
import TimelyAlert from './assets/TimelyAlert.png';

const TimeSheetAndLeave = () => {
  return (
    <section className="lg:pr-[108px] pr-4 lg:py-20 py-14 flex flex-col lg:gap-20 gap-10">
      <div className="flex justify-between lg:gap-14 gap-6 w-full lg:pr-10 flex-col lg:flex-row">
        <div className="flex flex-col gap-8 lg:w-[40%] w-full">
          <CardHeader
            header="TIMESHEET MANAGEMENT"
            title="Smart leave and timesheet management"
            color="white"
          />
          <div className="text-4xl font-medium mt-2"></div>
          <p className="text-lg-text font-normal text-[#FBFBFB]">
            Employees can apply for leaves or timesheets on the platform and
            managers can approve or reject.
          </p>
        </div>
        <div className="lg:w-[60%] w-full">
          <img src={TimeSheet} alt="TimeSheet" />
        </div>
      </div>
      <div className="flex justify-between gap-10 w-full lg:pr-10 lg:flex-row flex-col">
        <div className="lg:w-1/3 w-full">
          <img src={Approve} alt="Approve" className=" rounded-sm" />
        </div>
        <div className="lg:w-1/3 w-full">
          <img src={Calender} alt="Calender" className=" rounded-sm" />
        </div>
        <div className="lg:w-1/3 w-full">
          <img src={TimelyAlert} alt="TimelyAlert" className=" rounded-sm" />
        </div>
      </div>
    </section>
  );
};

export default TimeSheetAndLeave;
