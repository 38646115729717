import axios from 'axios';
import { useEffect, useState } from 'react';
import { fetchWhitePaperCategories } from '../../../api/categoryApi';
import { fetchWhitePapers } from '../../../api/whitePaperApi';
import { Category, WhitePaper } from '../../../types/types';

export const useWhitePaper = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [categories, setCategories] = useState<Category[]>([]);
  const [whitePapers, setWhitePapers] = useState<WhitePaper[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    Promise.all([
      fetchWhitePaperCategories({ signal: controller.signal }),
      fetchWhitePapers(
        selectedCategory === 'All' ? '' : selectedCategory,
        currentPage,
        { signal: controller.signal },
      ),
    ])
      .then(([categoriesResponse, WhitePaperResponse]) => {
        // Only update state if data is different
        if (
          JSON.stringify(categories) !==
          JSON.stringify(categoriesResponse?.data)
        ) {
          setCategories(categoriesResponse?.data || []);
        }

        if (
          JSON.stringify(whitePapers) !==
          JSON.stringify(WhitePaperResponse?.data)
        ) {
          setWhitePapers(WhitePaperResponse?.data || []);
        }

        const newTotalPages =
          WhitePaperResponse?.meta?.pagination?.pageCount ?? 1;
        if (totalPages !== newTotalPages) {
          setTotalPages(newTotalPages);
        }

        setError(null);
      })
      .catch((err) => {
        if (axios.isAxiosError(err)) {
          setError(err.response?.data?.message || 'Server error');
        } else {
          setError((err as Error)?.message || 'Unknown error');
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => controller.abort();
  }, [selectedCategory, currentPage]);

  return {
    selectedCategory,
    setSelectedCategory,
    currentPage,
    setCurrentPage,
    categories,
    whitePapers,
    loading,
    error,
    totalPages,
  };
};
