import { ApiResponse, Blog } from '../types/types';
import axiosInstance from './axiosInstance';

// Fetch blogs with optional category filter
export const fetchBlogs = async (
  category = '',
  page = 1,
  { signal }: { signal?: AbortSignal } = {},
): Promise<ApiResponse<Blog>> => {
  let url = `/api/blogs?populate=*&pagination[page]=${page}&pagination[pageSize]=6`;

  if (category && category !== 'All') {
    const encodedCategory = encodeURIComponent(category);
    url += `&filters[worx_squad_blog_category][title][$containsi]=${encodedCategory}`;
  }

  try {
    const { data } = await axiosInstance.get(url, { signal });
    return data;
  } catch (error) {
    throw new Error(`Blogs API Error: ${error}`);
  }
};

// Fetch a single blog by slug
export const fetchBlogBySlug = async (
  slug: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<ApiResponse<Blog>> => {
  try {
    const { data } = await axiosInstance.get(
      `/api/blogs?filters[slug][$eq]=${slug}&populate=*`,
      { signal },
    );
    return data;
  } catch (error) {
    throw new Error(`Blogs API Error: ${error}`);
  }
};
