import { FC } from 'react';
import { CommonDataType } from '../../types/types';
import SectionHeader from '../CommonComponent/SectionHeader';
import DeepDiv from '../Home/assets/DeepDiv';
import DeploymentFriendly from '../Home/assets/DeploymentFriendly';
import FullEcosystem from '../Home/assets/FullEcosystem';
import HyperPersonalization from '../Home/assets/HyperPersonalization';
import ImmersiveExperience from '../Home/assets/ImmersiveExperience';
import Interoperability from '../Home/assets/Interoperability';

interface WhySquadProps {
  title: string;
  description: string;
  variant?: string;
  data: CommonDataType[];
  textCenter?: boolean;
}

export const WhySquad: FC<WhySquadProps> = ({
  title,
  description,
  variant = 'white',
  data,
  textCenter = true,
}) => {
  return (
    <div
      className={`py-20 lg:px-[124px] px-6 bg-[#1B1B1B] flex flex-col ${data?.length < 4 ? 'gap-0 lg:gap-14' : 'gap-14'}`}
    >
      <SectionHeader
        title={title}
        description={description}
        variant={variant}
        textCenter={textCenter}
        mobileTextCenter
      />
      <div className="grid grid-cols-1 lg:grid-cols-3">
        {/* card */}
        {data?.map((val) => (
          <div
            className={`${!textCenter && val?.id === 1 ? 'lg:pl-5 lg:pr-12 ' : 'lg:px-12'} ${data?.length < 4 && 'lg:py-4 py-16'} flex flex-col gap-8 ${textCenter ? ' items-center justify-center' : ' items-start justify-start'}   ${(val?.id === 2 || val?.id === 5) && 'lg:border-l lg:border-r border-[#242424]'} ${data?.length > 3 && val.id < 6 ? 'pb-24' : 'pb-0'} ${val?.id > 3 && 'lg:border-t border-[#242424] lg:pt-14'}`}
            key={val?.id}
          >
            {val?.id === 1 ? (
              <FullEcosystem />
            ) : val?.id === 2 ? (
              <ImmersiveExperience />
            ) : val?.id === 3 ? (
              <Interoperability />
            ) : val?.id === 4 ? (
              <HyperPersonalization />
            ) : val?.id === 5 ? (
              <DeepDiv />
            ) : (
              <DeploymentFriendly />
            )}
            <div className="flex flex-col gap-4">
              <h4
                className={`text-white ${textCenter ? 'text-center' : 'text-start'} text-lg-text`}
              >
                {val?.title}
              </h4>
              <p
                className={`text-text-p4 text-h3-text ${textCenter ? 'text-center' : ' text-start'}`}
              >
                {val?.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
