import * as React from 'react';

const SquadIcon: React.FC<React.SVGProps<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="52"
    fill="none"
    viewBox="0 0 52 52"
  >
    <g id="Frame 1410105051">
      <circle
        id="Ellipse 1269"
        cx="26"
        cy="26"
        r="25.5"
        fill="#fff"
        stroke="#185D74"
        strokeDasharray="2 2"
      ></circle>
      <g id="Group 1000004000" fill="#185D74">
        <path
          id="Vector"
          d="M18.858 25.997a2.43 2.43 0 0 0-2.428-2.429A2.433 2.433 0 0 0 14 25.996c0 1.34 1.09 2.43 2.428 2.43a2.43 2.43 0 0 0 2.429-2.43z"
        ></path>
        <path
          id="Vector_2"
          d="M25.659 18.858a2.43 2.43 0 0 0 2.428-2.428c0-1.339-1.09-2.43-2.428-2.43a2.433 2.433 0 0 0-2.43 2.428 2.43 2.43 0 0 0 2.43 2.429z"
        ></path>
        <path
          id="Vector_3"
          d="m31.273 34.411 3.124-3.125-2.821-5.29 2.82-5.278-3.123-3.117-5.29 2.82-5.278-2.82-3.116 3.119 2.82 5.289-2.82 5.278 3.116 3.126 5.29-2.82 5.278 2.82zm-9.114-12.238c.593.324 1.685.92 3.718 2.026l.107.059 3.84-2.092-2.03 3.724-.058.107 2.096 3.847c-.59-.323-1.683-.919-3.732-2.035l-.106-.058-3.838 2.09c.323-.59.919-1.684 2.033-3.729l.059-.106z"
        ></path>
        <path
          id="Vector_4"
          d="M35.57 28.426a2.43 2.43 0 0 0 0-4.858 2.43 2.43 0 0 0-2.428 2.429c0 1.338 1.09 2.43 2.428 2.43"
        ></path>
        <path
          id="Vector_5"
          d="M23.229 35.572a2.43 2.43 0 0 0 4.858 0 2.43 2.43 0 0 0-2.428-2.429 2.433 2.433 0 0 0-2.43 2.429"
        ></path>
      </g>
    </g>
  </svg>
);

export default SquadIcon;
