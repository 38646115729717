import { DefaultButton, Spinner } from '@fluentui/react';
import { FC } from 'react';

interface PrimaryButtonProps {
  text: string;
  handleClick?: () => void;
  expanded?: boolean;
  width?: string;
  loading?: boolean;
  type?: string;
}
const PrimaryButton: FC<PrimaryButtonProps> = ({
  handleClick,
  text,
  expanded = false,
  width,
  loading = false,
  type = 'button',
}) => {
  return (
    <DefaultButton
      onClick={() => handleClick?.()}
      style={{ width: width }}
      type={type}
      className={`border border-[#FF9900] secondary-font text-black hover:text-black bg-[#FF9900] hover:bg-[#CC7A00] hover:border-[#CC7A00]
      ${expanded ? 'px-[14px] py-6 text-h3-text' : 'px-3 text-h4-text py-4'}`}
    >
      {loading ? <Spinner /> : text}
    </DefaultButton>
  );
};

export default PrimaryButton;
