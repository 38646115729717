import axios from 'axios';

const API_BASE_URL = process.env['NX_STRAPI_BASE_URL'];
const API_TOKEN = process.env['NX_APP_STRAPI_API_TOKEN'];

const axiosInstance = axios.create({
  baseURL: 'https://strapi.salesworx.ai',
  headers: {
    // Authorization: `Bearer ${API_TOKEN}`,
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
