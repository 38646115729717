import {
  INavLink,
  INavLinkGroup,
  IRenderFunction,
  Link,
  Nav,
  Text,
  ThemeProvider,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { useAuth } from '@worx.squad/hbp-react';
import { isEmpty } from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppLogo,
  WorkingImage,
  getFileUrl,
  navStyles,
  useGenerateTheme,
  useGetOrganizationInfoByUserQuery,
  useListener,
} from '../..';
import UnreadCountBadge from '../Components/LiveOffice/UnreadCountBadge';

interface IAppLayoutProps {
  navigation: INavLinkGroup[];
  userName?: string;
  headerContent?: ReactNode;
}

export const AppLayout: React.FC<IAppLayoutProps> = ({
  children,
  navigation,
  headerContent,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { user } = useAuth();

  const [appVersion, setAppVersion] = useState('');
  const [activePage, setActivePage] = useState<string>(location.pathname);
  const [navigationState, setNavigationState] = useState(navigation);
  const [availableNavigationKeys, setAvailableNavigationKeys] =
    useState<string[]>();

  const [{ data: orgInfoRaw }] = useGetOrganizationInfoByUserQuery({
    variables: {
      userId: user?.id,
    },
    pause: !user?.id,
    requestPolicy: 'cache-and-network',
  });
  const organizationName =
    orgInfoRaw?.organization_user?.[0]?.organization?.organization_name || '';
  const logoUrl = getFileUrl(
    orgInfoRaw?.organization_user?.[0]?.organization?.storageFileByLogoFileId
      ?.file_url,
  );

  const electron = (window as any).electron;

  useEffect(() => {
    (async () => {
      if (electron) {
        const version = await electron.getAppVersion();
        setAppVersion(version);
      }
    })();
  }, [electron]);

  useEffect(() => {
    if (!navigation) return;
    setNavigationState(navigation);
    const keys: string[] = [];

    console.log('current active page:', activePage);

    // The function recursively finds the link urls from array of array of NavLink and pushes them to keys array above
    function getKeys(item: INavLink[], keysArray: string[]) {
      return item?.forEach((linkItem) => {
        if (!isEmpty(linkItem.url)) keysArray.push(linkItem.url);
        else if (linkItem.links) getKeys(linkItem.links, keysArray);
      });
    }

    navigation?.forEach((item) => {
      getKeys(item.links, keys);
    });

    setAvailableNavigationKeys(keys);
  }, [navigation]);

  useEffect(() => {
    // This find is used just to set a valid key and not set an invalid key.
    let foundMatchingKey: string | undefined;

    if (location.pathname.includes('/chat')) {
      foundMatchingKey = availableNavigationKeys?.find((str) =>
        str.includes('live-office'),
      );
    } else {
      foundMatchingKey = availableNavigationKeys?.find((str) => {
        return str.includes(location.pathname.split('/').slice(0, 4).join('/'));
      });
    }

    if (foundMatchingKey) {
      const navItem = navigationState[0].links.find((item) => {
        return item.links?.find((link) => {
          return link.url === foundMatchingKey;
        });
      });
      if (navItem)
        setNavigationState((prev) => {
          return [
            {
              links: prev[0].links.map((link) => {
                if (link.name === navItem?.name)
                  return { ...link, isExpanded: true };
                else return { ...link, isExpanded: false };
              }),
            },
          ];
        });

      setActivePage(foundMatchingKey);
    }
  }, [availableNavigationKeys, location.pathname]);

  const t = useGenerateTheme('#fff', '#fff', '#175E74');

  const mainContainerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    mainContainerRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onRenderLink: IRenderFunction<INavLink> = (link) => {
    if (!link) {
      return null;
    }

    if (link.name === 'Live Office') {
      return (
        <div className="flex flex-row gap-2 items-center">
          <Text variant="medium">{link.name}</Text>
          <div>
            <UnreadCountBadge />
          </div>
        </div>
      );
    }

    return (
      <Link href={link.url} className="ms-NavLink" aria-label={link.name}>
        {link.name}
      </Link>
    );
  };

  useListener('scroll-top', scrollToTop);
  return (
    <div className="flex h-screen relative bg-[#175E74]">
      <ThemeProvider theme={t}>
        <div
          className="h-full py-2 pl-3"
          style={{ minWidth: '9.4rem', width: '9.4rem' }}
        >
          <div className="flex h-full flex-col gap-6 relative">
            <div className="flex gap-2 items-center font-bold text-gray-400">
              <WorkingImage
                className="h-6 w-6 object-contain bg-gray-200 rounded-full"
                imageURL={logoUrl || ''}
              />
              <div className="w-[91px]">
                <TooltipHost
                  content={organizationName}
                  hidden={organizationName.length < 25}
                >
                  <Text
                    variant="medium"
                    className="text-transform: uppercase text-white font-semibold text-[12px] truncate-2-lines break-words"
                  >
                    {organizationName}
                  </Text>
                </TooltipHost>
              </div>
            </div>

            <div className="h-full flex flex-col justify-between">
              <div className="overflow-y-auto h-[calc(100vh-250px)]">
                <Nav
                  styles={navStyles}
                  ariaLabel="Resource Navigation bar"
                  groups={navigationState}
                  selectedKey={activePage}
                  onRenderLink={onRenderLink}
                  onLinkClick={(ev, item) => {
                    if (!item?.links) ev?.preventDefault();
                    if (item) {
                      if (item?.url !== '') {
                        navigate(item?.url);
                      } else {
                        setNavigationState((prev) => {
                          const newNav = prev[0].links.map((currentItem) => {
                            if (currentItem.name === item.name)
                              return currentItem;
                            else {
                              return {
                                ...currentItem,
                                isExpanded: false,
                              };
                            }
                          });
                          return [{ links: newNav }];
                        });
                      }
                    }
                  }}
                />
              </div>
              <div className="w-11 flex items-center gap-2">
                <AppLogo />
                <div className="flex flex-col">
                  <Text variant="smallPlus" className="font-bold text-gray-300">
                    Worx.Squad
                  </Text>
                  <Text className="font-semibold text-sm text-gray-400">
                    {appVersion && `v${appVersion}`}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
      <div className="bg-white flex flex-col grow rounded-[4px] m-2 ml-0 w-[calc(100%-17rem)]">
        <div className="flex align-middle justify-end items-center gap-2 shadow-md">
          {headerContent}
        </div>
        <div
          ref={mainContainerRef}
          className={`mainContainer overflow-y-auto overflow-x-hidden ${activePage === '/app/work/projects' ? 'pl-6 pb-0' : 'px-6 pb-3'}  pt-2 ${activePage === '/app/dashboard/employee' ? 'bg-[#f6f6f6]' : 'bg-white'}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
