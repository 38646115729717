import {
  ArrowLeft16Filled,
  ArrowRight16Filled,
  ArrowRightRegular,
  DocumentLockRegular,
} from '@fluentui/react-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';

interface WhitePaperSliderProps {
  slides: string[];
  onLastSlideAction?: () => void;
}

const WhitePaperSlider: FC<WhitePaperSliderProps> = ({
  slides,
  onLastSlideAction,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isLastSlide, setIsLastSlide] = useState(false);

  useEffect(() => {
    // Check if we're on the last slide
    setIsLastSlide(currentSlide === slides.length - 1);
  }, [currentSlide, slides.length]);

  const goToNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setDirection(1);
      setCurrentSlide(currentSlide + 1);
    }
  };

  const goToPrevSlide = () => {
    if (currentSlide > 0) {
      setDirection(-1);
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleExploreMore = () => {
    if (onLastSlideAction) {
      onLastSlideAction();
    }
  };

  const slideVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 500 : -500,
      opacity: 0,
      scale: 0.9,
    }),
    center: {
      x: 0,
      opacity: 1,
      scale: 1,
      zIndex: 20,
    },
    exit: (direction: number) => ({
      x: direction > 0 ? -500 : 500,
      opacity: 0,
      scale: 0.9,
      zIndex: 10,
    }),
  };

  return (
    <div className=" lg:max-w-[45%] w-full mx-auto relative bg-[#EDEDED] overflow-hidden rounded-lg lg:py-[74px] py-10">
      {/* Main slides container */}
      <div className="w-full lg:h-[600px] h-[400px] relative flex items-center justify-center">
        {/* Animating main slides */}
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={currentSlide}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            className="absolute h-full w-[61%] lg:min-w-[330px] z-20"
            style={{
              left: '22%',
              transform: 'translateX(-50%)',
            }}
          >
            <div className="relative h-full w-full">
              <img
                src={slides[currentSlide]}
                alt={`Slide ${currentSlide + 1}`}
                className="h-full w-full object-cover"
              />

              {/* Blur overlay on last slide */}
              {isLastSlide && (
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-800 bg-opacity-70 backdrop-blur-sm z-30 p-8 text-white text-center">
                  <div className="flex flex-col items-center mb-6">
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 }}
                      className="mb-2 "
                    >
                      <DocumentLockRegular fontSize={30} />
                    </motion.div>
                  </div>

                  <motion.h3
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                    className="text-2xl font-bold lg:mb-4 mb-2"
                  >
                    Want to explore the rest?
                  </motion.h3>

                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7 }}
                    className="lg:mb-6 mb-2 text-base"
                  >
                    Click Below to download the full whitepaper and unlock
                    exclusive insights.
                  </motion.p>

                  <motion.button
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.9 }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleExploreMore}
                    className="px-6 py-3  rounded-md flex items-center gap-2 transition-colors"
                  >
                    <span>Click Here</span>
                    <ArrowRightRegular color="#fff" fontSize={20} />
                  </motion.button>
                </div>
              )}
            </div>
          </motion.div>
        </AnimatePresence>

        {/* Previous slide (partially visible) */}
        {currentSlide > 0 && (
          <div
            className="absolute h-[90%] z-10 overflow-hidden"
            style={{
              left: '0',
              width: '21%',
              marginRight: '10px',
            }}
          >
            <img
              src={slides[currentSlide - 1]}
              alt="Previous slide"
              className="h-full w-full object-cover grayscale filter"
              style={{ filter: 'grayscale(50%)' }}
            />
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          </div>
        )}

        {/* Next slide (partially visible) */}
        {currentSlide < slides.length - 1 && (
          <div
            className="absolute h-[90%] z-10 overflow-hidden"
            style={{
              right: '0',
              width: '16%',
              marginLeft: '10px',
            }}
          >
            <img
              src={slides[currentSlide + 1]}
              alt="Next slide"
              className="h-full w-full object-cover grayscale filter"
              style={{ filter: 'grayscale(50%)' }}
            />
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          </div>
        )}
      </div>

      {/* Navigation buttons */}
      <div className="flex justify-center w-full pl-7">
        <div className="flex justify-between z-30 pt-6 w-[60%] items-center">
          <motion.button
            onClick={goToPrevSlide}
            disabled={currentSlide === 0}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className={`flex items-center secondary-font gap-1.5 text-h4-text font-medium ${currentSlide === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          >
            <ArrowLeft16Filled /> <span> Previous </span>
          </motion.button>

          <motion.button
            onClick={goToNextSlide}
            disabled={currentSlide === slides.length - 1}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className={`flex items-center secondary-font text-h4-text font-medium gap-1.5 ${currentSlide === slides.length - 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          >
            <span>Next</span> <ArrowRight16Filled />
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default WhitePaperSlider;
