import Accordion from './Accordion';
import CardHeader from './CardHeader';
import { productivityItemsTwo } from './Utils';
import KudosImg from './assets/KudosImg.png';
import ProductivityImg from './assets/ProductivityImg.png';
import WallOfMoments from './assets/WallOfMoments.png';

const FeatureProductivityTwo = () => {
  return (
    <section className="flex flex-col lg:gap-28 gap-10 lg:py-20 py-14 lg:pr-[108px] pr-4">
      <div className="flex justify-between lg:gap-14 gap-6 w-full items-center lg:flex-row flex-col">
        <div className="lg:w-[40%] w-full flex flex-col gap-8 justify-start">
          <CardHeader
            header="LIVE OFFICE"
            title="Foster collaboration and boost engagement"
            color="black"
          />
          <div className="w-full">
            <Accordion items={productivityItemsTwo} />
          </div>
        </div>{' '}
        <div className="lg:w-[45%] w-full">
          <img src={ProductivityImg} alt="ProductivityImg" />
        </div>
      </div>
      <div className="flex justify-between my-4 text-white flex-col lg:flex-row">
        <div className="lg:w-[60%] w-full">
          <h2 className={`text-h2-text font-medium text-[#101010] w-[70%]`}>
            A social space built for you and your organisation
          </h2>
          <div className="pt-16">
            <img src={KudosImg} alt="KudosImg" className="rounded-lg" />
          </div>
        </div>
        <div className="lg:w-[35%] w-full pt-3">
          <img
            src={WallOfMoments}
            alt="WallOfMoments"
            className="h-full rounded-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default FeatureProductivityTwo;
