import { FC } from 'react';
import NewBlogStarter from '../components/Blog/NewBlogStarter';
import { CommonLayout } from '../components/Layouts/CommonLayout';

const Blog: FC = () => {
  return (
    <CommonLayout calendlyWidget={false}>
      <div className="bg-primary-light">
        <NewBlogStarter />
      </div>
    </CommonLayout>
  );
};

export default Blog;
