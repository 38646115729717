import { MessageBarType } from '@fluentui/react';
import { FormType } from '@worx.squad/app-sdk/base-types';
import {
    Form,
    STextFormFieldStyleEmailNPhone,
    STextFormFieldStyleMobileView,
    TextField,
    useNotification,
} from '@worx.squad/shared-frontend';
import { motion } from 'framer-motion';
import { FC, RefObject, useState } from 'react';
import { useForm } from 'react-hook-form';
import { WhitePaper } from '../../types/types';
import { useContactUsMutation } from '../Home/home.generated';
import { splitName } from '../helpers/functions';
import AnimatedButton from './AnimatedButton';
import { fields } from './Utils';
import { WhitePaperSchema } from './WhitePaperSchema';

interface WhitePaperFormProps {
  formRef: RefObject<HTMLDivElement>;
  highlightForm: boolean;
  whitePaper: WhitePaper | null;
}

const WhitePaperForm: FC<WhitePaperFormProps> = ({
  formRef,
  highlightForm,
  whitePaper,
}) => {
  const [loader, setLoader] = useState(false);
  const [, contactUs] = useContactUsMutation();
  const { notification } = useNotification();
  const isMobile = window.innerWidth < 768;
  const tryUsForm = useForm({
    mode: 'all',
    resolver: WhitePaperSchema,
    defaultValues: {
      fullName: '',
      email: '',
      phoneNumber: '',
      designation: '',
    },
  });

  const { handleSubmit } = tryUsForm;

  // Function to trigger document download
  const handleDocumentDownload = () => {
    if (whitePaper?.whitepaperFile?.url) {
      const downloadLink = document.createElement('a');
      downloadLink.href = `https://strapi.salesworx.ai${whitePaper?.whitepaperFile?.url}`;
      downloadLink.download = '';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // Clean up
      document.body.removeChild(downloadLink);
    } else {
      notification({
        message: 'Document download link not available.',
        type: MessageBarType.error,
      });
    }
  };

  // form submission
  const onSubmit = async (data: any) => {
    setLoader(true);
    const { firstName, lastName } = splitName({ fullName: data?.fullName });
    try {
      const res = await contactUs({
        object: {
          formType: FormType?.WhitepaperType,
          firstName: firstName,
          lastName: lastName,
          email: data?.email,
          phone: data?.phoneNumber,
          description: data?.designation,
        },
      });
      if (res) {
        notification({
          message: 'Thank you for filling the form.',
          type: MessageBarType.success,
        });
        setLoader(false);
        handleDocumentDownload();
        tryUsForm.reset();
      } else {
        notification({
          message: res + '',
          type: MessageBarType.error,
        });
        setLoader(false);
      }
    } catch (e) {
      notification({
        message: e + '',
        type: MessageBarType.error,
      });
      setLoader(false);
    }
  };

  return (
    <div className="lg:w-[55%] w-full lg:max-h-[242px] h-fit relative">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <h3 className=" text-h3-text text-[#207C97]">Whitepaper</h3>
          <h2 className="text-h2-text">{whitePaper?.title}</h2>
        </div>
        <p className=" text-h3-text text-[#414651]">
          {whitePaper?.shortDescription}
        </p>
      </div>
      {/* form */}
      <motion.div
        ref={formRef}
        transition={{
          duration: 1.5,
          times: highlightForm ? [0, 0.2, 0.8] : [0, 0, 0],
          x: {
            duration: 0.5,
            times: [0, 0.1, 0.3, 0.5, 0.7, 1],
          },
        }}
        className="relative w-full h-full"
      >
        <div className="lg:px-6 pt-6 w-full relative">
          <h2 className="text-[#252B37] text-2xl font-semibold max-lg:mb-4">
            Get Full Access
          </h2>
          <Form
            className="flex flex-col w-full sm:w-full gap-5 max-h-full"
            formHook={tryUsForm}
            onSubmit={handleSubmit(onSubmit)}
          >
            {fields?.map(({ label, name, placeholder }) => (
              <div
                key={name}
                className="flex flex-col sm:flex-row gap-5 w-full relative"
              >
                <label className="text-[#6F7482] text-h3-text mt-[4px] w-full">
                  {label}
                  <TextField
                    formHook={tryUsForm}
                    name={name}
                    styles={{
                      ...(isMobile
                        ? STextFormFieldStyleMobileView
                        : STextFormFieldStyleEmailNPhone),
                      root: { width: '100%' },
                      fieldGroup: {
                        backgroundColor: '#F8FAFC',
                        height: '52px',
                        width: '100%',
                        borderRadius: '0px',
                        marginTop: '4px',
                        borderBottom: '2px solid #6D6E6F',
                      },
                    }}
                    placeholder={placeholder}
                    className="w-full placeholder:text-h3-text"
                    borderless
                  />
                </label>
              </div>
            ))}
            <div className="w-full mt-3 relative">
              <AnimatedButton
                loading={loader}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </Form>
        </div>
      </motion.div>
    </div>
  );
};

export default WhitePaperForm;
