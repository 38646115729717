import CocaCola from '../../../assets/CocaCola.png';
import TataSteel from '../../../assets/TataSteel.png';
import './AutoScrolling.scss';

const AutoScrolling = () => {
  const scrollData = [
    {
      image: CocaCola,
      value: '40%',
      subText: 'Reduction in project turnaround time.',
    },
    {
      image: TataSteel,
      value: '50%',
      subText: 'Increase in task completion rates.',
    },
    {
      image: TataSteel,
      value: '60%',
      subText: 'Tata steel intra project',
    },
  ];

  return (
    <div className="logos flex gap-8 h-full">
      {[0, 1].map((slideIndex) => (
        <div key={`slide-${slideIndex}`} className="logos-slide flex gap-10">
          {scrollData.map((item, index) => (
            <div
              key={`item-${slideIndex}-${index}`}
              className="w-[200px] h-[210px] border rounded-md text-black -rotate-90 flex flex-col justify-between"
            >
              <div className="p-4 flex flex-col h-full">
                <div className="h-12 mb-3 flex items-center">
                  <img
                    src={item.image}
                    className="object-contain w-32 h-12"
                    alt={item.subText || `Client ${index + 1}`}
                  />
                </div>
                <hr />
                <div className="flex flex-col justify-between flex-grow">
                  <div className="text-[36px] pt-4 pb-2">{item.value}</div>
                  <div className="break-words whitespace-normal text-[16px]">
                    {item.subText}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AutoScrolling;
