import { DefaultButton } from '@fluentui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkSvg from '../Home/assets/LinkSvg';

interface CommonButtonProps {
  highlight?: boolean;
  showLinkarrow?: boolean;
}

const CommonButton: FC<CommonButtonProps> = ({
  highlight = false,
  showLinkarrow = false,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-auto py-2">
      <DefaultButton
        onClick={() => navigate('/contact-us')}
        className={`border border-[#FF9900] w-full  text-[#1B1B1B] 
                    px-6 py-5 hover:text-[#1B1B1B] ${highlight ? 'bg-[#FF9900]' : 'transparent'} hover:bg-[#CC7A00] hover:border-[#CC7A00] rounded-sm`}
      >
        <span className="lg:flex gap-4 lg:justify-center lg:items-center font-semibold">
          <span className="lg:text-h3-text text-sm">Get started</span>
          {showLinkarrow && (
            <span>
              <LinkSvg black />
            </span>
          )}
        </span>
      </DefaultButton>
    </div>
  );
};

export default CommonButton;
