import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import VectorBg from '../../../assets/PartnerBGSvg.svg';
import LinkSvg from '../Home/assets/LinkSvg';
import PartnerImage1 from '../Home/assets/PartnerImage1.svg';
import PartnerImage2 from '../Home/assets/PartnerImage2.svg';
import PartnerImage3 from '../Home/assets/PartnerImage3.svg';
import JoinAsPartnerModal from './JoinAsPartnerModal';
const JoinAsPartner = () => {
  const [isModalOpenOne, { setTrue: showModalOne, setFalse: hideModalOne }] =
    useBoolean(false);
  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{
        background: `linear-gradient(180deg, #1A1A1A -31.4%, #012734 412.25%), url(${VectorBg})`,
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '400px',
      }}
    >
      <div className="flex lg:flex-row flex-col justify-center items-center lg:py-20 lg:px-[108px] px-6 py-6">
        <div className="flex flex-col gap-5 lg:w-[60%] w-full items-start">
          <div className="text-h2-text font-poppins text-white text-center">
            Join our partner program
          </div>
          <div className="text-[#CCC] text-h2-text-subtitle lg:w-[80%] w-full font-poppins">
            Join our network of experts helping organizations unlock their full
            productivity potential. As a consulting partner, you’ll gain
            exclusive access to resources, training, and a dedicated support
            team to drive real results for your clients with Worx Squad.
          </div>
          <div className="w-auto text-center flex justify-center items-center pt-3">
            <DefaultButton
              onClick={showModalOne}
              className="w-full border border-[#FF9900] bg-[#FF9900] hover:bg-[#CC7A00] hover:border-[#CC7A00] px-5 py-6 ease-in-out duration-300"
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem] text-primary-text font-semibold font-poppins">
                  Learn More
                </span>
                <span>
                  <LinkSvg black />
                </span>
              </span>
            </DefaultButton>
          </div>
          <JoinAsPartnerModal
            showModalOne={showModalOne}
            isModalOpenOne={isModalOpenOne}
            hideModalOne={hideModalOne}
          />
        </div>
        <div className="lg:w-[40%] w-full flex justify-center items-center gap-10">
          <div className="flex flex-col gap-10 justify-center items-center overflow-hidden">
            <img
              alt="alt_img1"
              className="lg:w-[150px] w-[138px] lg:h-[150px] h-[138px] rounded-full overflow-hidden"
              style={{
                filter: 'grayscale(100%)',
                opacity: '1',
                backgroundColor: '#D9D9D9',
                mixBlendMode: 'luminosity',
              }}
              src={PartnerImage1}
            />
            <img
              alt="alt_img2"
              style={{
                filter: 'grayscale(100%)',
                opacity: '1',
                backgroundColor: '#D9D9D9',
                mixBlendMode: 'luminosity',
              }}
              className="lg:w-[150px] w-[138px] lg:h-[150px] h-[138px] rounded-full  overflow-hidden"
              src={PartnerImage3}
            />
          </div>
          <div className="flex justify-center items-center overflow-hidden">
            <img
              alt="alt_img3"
              style={{
                filter: 'grayscale(100%)',
                opacity: '1',
                backgroundColor: '#D9D9D9',
                mixBlendMode: 'luminosity',
              }}
              className="lg:w-[150px] w-[138px] lg:h-[150px] h-[138px] rounded-full overflow-hidden"
              src={PartnerImage2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinAsPartner;
