/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Components } from 'react-markdown/lib/ast-to-react';

interface StrapiContentRendererProps {
  content: string;
}

const StrapiContentRenderer: React.FC<StrapiContentRendererProps> = ({
  content,
}) => {
  const components: Components = {
    h1: ({ node, ...props }) => (
      <h1
        className="text-h1-text-subtitle font-semibold mt-12 mb-2 text-[#252B37]"
        {...props}
      />
    ),
    h2: ({ node, ...props }) => <h2 className="text-lg-text mb-2" {...props} />,
    h3: ({ node, ...props }) => (
      <h3 className="text-h3-text-subtitle mb-2" {...props} />
    ),
    p: ({ node, ...props }) => (
      <p className="text-h3-text text-[#414651]" {...props} />
    ),
    ul: ({ node, ...props }) => (
      <ul className="list-disc pl-5 mb-2" {...props} />
    ),
    ol: ({ node, ...props }) => (
      <ol className="list-decimal pl-5 mb-2" {...props} />
    ),
    strong: ({ node, ...props }) => (
      <strong className="font-semibold" {...props} />
    ),
    blockquote: ({ node, ...props }) => (
      <blockquote
        className="border-l-4 border-gray-300 pl-4 italic my-4"
        {...props}
      />
    ),
    code: ({ node, inline, ...props }) =>
      inline ? (
        <code className="bg-gray-100 rounded px-1 py-0.5" {...props} />
      ) : (
        <pre className="bg-gray-100 p-4 rounded my-4 overflow-x-auto">
          <code {...props} />
        </pre>
      ),
    img: ({ src, alt, ...props }) => {
      // Check if src exists
      if (!src) return null;
      const imageSrc = src.startsWith('/uploads')
        ? `https://strapi.salesworx.ai${src}`
        : src;

      return (
        <img
          src={imageSrc}
          alt={alt || ''}
          className="w-full h-[302px] rounded my-4 object-fill mt-12"
          {...props}
        />
      );
    },
  };

  return (
    <div className="strapi-content-preview">
      <ReactMarkdown className="rich-text-content" components={components}>
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default StrapiContentRenderer;
