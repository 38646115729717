import axios from 'axios';
import { useEffect, useState } from 'react';
import { fetchWhitepaperBySlug } from '../../../api/whitePaperApi';
import { WhitePaper } from '../../../types/types';

export const useGetWhitepaperDetails = (slug: string | undefined) => {
  const [whitePaper, setWhitePaper] = useState<WhitePaper | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch blog data logic
  useEffect(() => {
    if (!slug) {
      setLoading(false);
      setError('No slug provided');
      return;
    }

    const controller = new AbortController();
    setLoading(true);
    setError(null);

    const fetchData = async () => {
      try {
        const response = await fetchWhitepaperBySlug(slug, {
          signal: controller.signal,
        });

        if (!response || !response.data) {
          throw new Error('No data received from server');
        }

        setWhitePaper(response.data[0] || null);

        if (!response.data[0]) {
          setError('Blog not found');
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('Request canceled:', err.message);
        } else if (axios.isAxiosError(err)) {
          if (err.response) {
            setError(
              `Server error: ${err.response.status} - ${err.response.data?.message || err.message}`,
            );
          } else if (err.request) {
            setError('No response from server. Please check your connection');
          } else {
            setError(`Request configuration error: ${err.message}`);
          }
        } else {
          setError(
            `Error: ${(err as Error)?.message || 'An unknown error occurred'}`,
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [slug]);

  return { whitePaper, loading, error };
};
