import { cxoInsightList } from './Utils';

const CxoInsight = () => {
  return (
    <div className="flex lg:gap-14 gap-6 items-center flex-col lg:flex-row">
      <div className="text-h2-text text-[#101010] font-medium">
        CXO Insights for Informed Decision Making{' '}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
        {cxoInsightList.map((item, index) => (
          <div
            className="flex flex-col gap-2 py-8 px-7 shadow-md rounded bg-white"
            key={index}
          >
            <div className=" text-2xl font-semibold mb-2">{item.title}</div>
            <div className=" text-h3-text text-tertiary-text">
              {item.content}
            </div>
            <div className="mt-4">{item.icon}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CxoInsight;
