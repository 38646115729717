import { FC } from 'react';

interface SubHeaderProps {
  title: string;
  description: string;
}

const SubHeader: FC<SubHeaderProps> = ({ title, description }) => {
  return (
    <div className="flex items-center justify-center text-center flex-col gap-2">
      <div className="text-xl font-semibold text-center uppercase text-white">
        {title}
      </div>
      <div className="text-h4-text text-[#CCC] text-center">{description}</div>
    </div>
  );
};

export default SubHeader;
