import Address from './Address';
import CalendlyCard from './CalendlyCard';
import { TryUs } from './TryUs';

const Contact = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col lg:flex-row lg:gap-16 gap-8 bg-background-dark-card lg:pt-[76px] pt-14  lg:pb-24 pb-14 lg:px-[108px] px-4 w-full min-h-screen">
        {/* Contact form */}
        <div className="lg:w-[40%] w-full flex flex-col">
          <TryUs />
        </div>

        {/* Vertical line desktops */}
        <div className="flex-col items-center justify-center lg:flex hidden">
          <div className="w-[1.5px] border-dashed border border-[#545454] flex-1" />
          <span className="my-2.5 text-primary-light text-h3-text font-medium">
            OR
          </span>
          <div className="w-[1.5px] border-dashed border border-[#545454] flex-1" />
        </div>

        {/* mobile/tabs horizontal line */}
        <div className="lg:hidden flex gap-2.5 items-center">
          <hr className="w-full h-[1px] border border-dashed border-[#545454]" />{' '}
          <span className="text-primary-light text-h3-text font-medium">
            OR
          </span>{' '}
          <hr className="w-full h-[1px] border border-dashed border-[#545454]" />{' '}
        </div>

        {/* Calendly area */}
        <div className="lg:w-[60%] w-full flex flex-col">
          <CalendlyCard />
        </div>
      </div>
      {/* address */}
      <Address />
    </div>
  );
};

export default Contact;
