import Employee_Report_Img from './Assets/Employee_report_img-1.png';
import HolisticBusinessImg from './Assets/Holistic_business.png';
import Holistic_Resource_Img1 from './Assets/Holistic_Resources_Img1.png';
import Integrated_Chat_Img from './Assets/integrated_chat.jpg';
import KeyMatricsImg from './Assets/KeyMatrics.png';
import PredictiveAnalysisImg from './Assets/PredictiveAnalysis.png';
import Project_Moniter_Img from './Assets/Project_Moniter.png';
import Real_Time_Tracking_Img from './Assets/REAL_TIME_TRACKING.png';
import Real_Time_Update_Img from './Assets/Real_time_update.jpg';
import Timesheet_Management_Img from './Assets/TIMESHEET_MANAGEMENT.png';
import Video_Voice_Call_Img from './Assets/Video_voice_call.jpg';
// AI INSIGHT AND PREDICTION DATA
export const RESULTCOUNTSAIINSIGHT = [
  {
    id: 1,
    title: '25%',

    description: 'Faster project completion and deadline adherence',
  },
  { id: 2, title: '30%', description: 'Reduction in approval related delays' },
  {
    id: 3,
    title: '20%',
    description: 'Increase in resource utilization efficiency',
  },
];

export const STEPSDATAAIINSIGHT = [
  {
    id: 1,
    title: 'ANALYZE',
  },
  {
    id: 2,
    title: 'PREDICT',
  },
  {
    id: 3,
    title: 'ACT',
  },
];

export const AIPREDICTION = {
  header: 'AI PREDICTIONS AND INSHIGHTS',
  title: 'Empower your decisions with AI-driven insights',
  description: `Harness the power of data to predict trends, optimize outcomes, and
                        stay ahead with Squad’s AI-driven insights.`,
};

export const AIPREDICTION_CXO_DATA = [
  {
    id: 1,
    title: 'Holistic Business Overview',
    description: `Provide CXOs with a comprehensive view of business operations, project
        health, and employee performance at a glance.`,
    image: HolisticBusinessImg,
    imgAbove: false,
  },
  {
    id: 2,
    title: 'Key Metrics at Fingertips',
    description: `Access critical KPIs such as revenue growth, project deviations, and resource utilization to drive strategic decisions.`,
    image: KeyMatricsImg,
    imgAbove: false,
  },
  {
    id: 3,
    title: 'Predictive Analytics for Forecasting',
    description: `Leverage AI-powered predictions to anticipate challenges and seize opportunities, ensuring sustained growth and profitability`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
];

export const PREDICTIVE_INSIGHT_DATA = [
  {
    id: 1,
    title: 'AI-Driven Predictions',
    description: `Harness the power of AI to forecast project outcomes, resource needs, and potential risks.`,
    image: HolisticBusinessImg,
    imgAbove: true,
  },
  {
    id: 2,
    title: 'Proactive Decision-Making',
    description: `Use predictive insights to make informed decisions before issues arise, ensuring smoother project execution`,
    image: HolisticBusinessImg,
    imgAbove: true,
  },
];

export const EMPLOYEE_REPORT_DATA = [
  {
    id: 1,
    title: 'Detailed Performance Reports',
    description:
      'Access all employee level metrics individually, from activity, to performance to productivity and more.',
    image: Employee_Report_Img,
  },
  {
    id: 2,
    title: 'Goal Alignment',
    description:
      'Ensure each employee’s objectives are aligned with organizational goals, enhancing personal growth and business impact.',
    image: Employee_Report_Img,
  },
  {
    id: 3,
    title: 'Continuous Feedback',
    description:
      'Facilitate regular performance reviews and feedback sessions, promoting continuous improvement and skill development.',
    image: Employee_Report_Img,
  },
];

export const EMPLOYEE_CAPACITY_MANAGEMENT = [
  {
    id: 1,
    title: 'Capacity Planning',
    description:
      'Monitor employee capacity to ensure balanced workloads and avoid overburdening your team.',
  },
  {
    id: 2,
    title: 'Resource Allocation',
    description:
      'Allocate tasks based on real-time capacity data, ensuring optimal resource utilization.',
  },
  {
    id: 3,
    title: 'Predictive Resource Needs',
    description:
      'Use predictive analytics to forecast future resource requirements and plan accordingly.',
  },
];

export const EMPLOYEE_PRODUCTIVITY_DATA = [
  {
    id: 1,

    title: 'Workload Distribution',
    description: ` Analyze task assignments and workloads to optimize productivity
                and prevent burnout.`,
  },
  {
    id: 2,
    title: 'Real-Time Activity Tracking',
    description:
      'Monitor active and non-billable hours to ensure efficient time management and project alignment.',
  },
  {
    id: 3,
    title: 'Proactive Adjustments',
    description:
      'Use data insights to identify bottlenecks and implement changes for smoother operations and increased efficiency.',
  },
];

export const CUSTOM_ANALYSIS_DATA = [
  {
    id: 1,
    title: 'Custom Dashboards',
    description:
      'Access intuitive dashboards for a comprehensive view of performance and trends.',
  },
  {
    id: 2,
    title: 'Detailed Reports',
    description:
      'Generate in-depth reports tailored to your specific needs and metrics.',
  },
];

// PROJECT_MANAGEMENT_DATA

export const STEPS_DATA_PROJECT_MANAGEMENT = [
  {
    id: 1,
    title: 'CREATE',
  },
  {
    id: 2,
    title: 'EXECUTE',
  },
  {
    id: 3,
    title: 'MONITOR',
  },
];

export const RESULT_COUNT_PROJECT_MANAGEMENT = [
  {
    id: 1,
    title: '25%',

    description: 'Faster project completion and deadline adherence',
  },
  { id: 2, title: '30%', description: 'Reduction in approval related delays' },
  {
    id: 3,
    title: '20%',
    description: 'Increase in resource utilization efficiency',
  },
];

export const PROJECT_MANAGEMENT = {
  header: 'PROJECT MANAGEMENT',
  title: 'Elevate your project mangement with Squad',
  description: `Streamline workflows, enhance collaboration, and deliver projects on time with our comprehensive project management tools.`,
};

export const PROJECT_MANAGEMENT_COMPREHENSIVE = [
  {
    id: 1,
    title: 'Task Management',
    description: `Break down projects into manageable tasks, assign responsibilities, and set deadlines to ensure clarity and accountability.`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
  {
    id: 2,
    title: 'Milestone Tracking',
    description: `Define key milestones and monitor progress to keep your project on track.`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
  {
    id: 3,
    title: 'Gantt Charts',
    description: `Visualize project timelines and dependencies for better planning and resource allocation.`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
];

export const TEAM_COLLABORATION = [
  {
    id: 1,
    title: 'Integrated Chat',
    description: `Communicate with team members directly within the project space to streamline discussions.`,
    image: Integrated_Chat_Img,
    imgAbove: true,
  },
  {
    id: 2,
    title: 'Real-Time Updates',
    description: `Stay informed with instant notifications and real-time updates on project developments.`,
    image: Real_Time_Update_Img,
    imgAbove: true,
  },
];

export const RESOURCE_MANAGEMENT_DATA = [
  {
    id: 1,
    title: 'Resource Scheduling',
    description:
      'Allocate resources effectively to ensure balanced workloads and prevent bottlenecks.',
    image: Employee_Report_Img,
  },
  {
    id: 2,
    title: 'Availability Tracking',
    description:
      'Monitor team members’ availability to plan tasks accordingly.',
    image: Employee_Report_Img,
  },
  {
    id: 3,
    title: 'Time Tracking',
    description:
      'Record time spent on tasks to analyze productivity and project costs.',
    image: Employee_Report_Img,
  },
];

export const CUSTOMIZABLE_WORKFLOW_DATA = [
  {
    id: 1,

    title: 'Workflow Automation',
    description: `Automate repetitive tasks to increase efficiency and reduce errors.`,
  },
  {
    id: 2,
    title: 'Custom Fields',
    description:
      'Add specific data fields to capture all necessary project information.',
  },
  {
    id: 3,
    title: 'Template Library',
    description:
      'Utilize pre-built templates or create your own to standardize processes.',
  },
];

export const ROBUST_REPORTING_DATA = [
  {
    id: 1,
    title: 'Dashboards',
    description:
      'Access real-time dashboards to monitor project health and performance.',
    image: Employee_Report_Img,
  },
  {
    id: 2,
    title: 'Custom Reports',
    description: 'Generate reports tailored to your specific metrics and KPIs.',
    image: Employee_Report_Img,
  },
  {
    id: 3,
    title: 'Progress Tracking',
    description:
      'Track task completion rates and identify areas needing attention.',
    image: Employee_Report_Img,
  },
];

export const INTEGRATION_CAPABILITIES_DATA = [
  {
    id: 1,
    title: 'Third-Party Integrations',
    description:
      'Integrate with popular tools like Slack, Google Drive, and more to enhance functionality.',
    image: Employee_Report_Img,
  },
  {
    id: 2,
    title: 'API Access',
    description:
      'Utilize our API to build custom integrations tailored to your needs.',
    image: Employee_Report_Img,
  },
  {
    id: 3,
    title: 'Data Import/Export',
    description: 'Easily migrate data to and from Squad to ensure continuity.',
    image: Employee_Report_Img,
  },
];

export const SECURITY_AND_COMPILANCE_DATA = [
  {
    id: 1,
    title: 'Data Encryption',
    description:
      'Ensure all your data is encrypted both in transit and at rest.',
  },
  {
    id: 2,
    title: 'Access Controls',
    description:
      'Define user roles and permissions to control access to sensitive information.',
  },
];

export const WHY_SQUAD = [
  {
    id: 1,
    title: 'Quick Task Updates',
    description:
      'We thrive on teamwork, embracing diverse perspectives to achieve shared success.',
  },
  {
    id: 2,
    title: 'Smart Notifications',
    description:
      'We break barriers and think beyond conventions to create meaningful solutions.',
  },
  {
    id: 3,
    title: 'Visual Analytics',
    description:
      'We celebrate diversity and foster an environment where everyone feels valued and heard.',
  },
];

// FINANCIAL MANAGEMENT DATA

export const STEPS_DATA_FINANCIAL_MANAGEMENT = [
  {
    id: 1,
    title: 'ESTIMATE',
  },
  {
    id: 2,
    title: 'TRACK',
  },
  {
    id: 3,
    title: 'OPTIMIZE',
  },
];

export const FINANCIAL_MANAGEMENT = {
  header: 'FINANCIAL MANAGEMENT',
  title: 'Take control of your project finances with Squad',
  description: `Streamline budgeting, track expenses, and optimize financial performance with our comprehensive financial management tools.`,
};

export const FINACE_MODELS_DATA = [
  {
    id: 1,
    title: 'Diverse Financial Models',
    description: `Choose from Fixed Cost, Time and Material, or Retainer models to best align with your project’s scope and requirements.`,
  },
  {
    id: 2,
    title: 'Customizable Setup',
    description:
      'Define financial parameters and project budgets with ease, ensuring accurate planning and cost control.',
  },
  {
    id: 3,
    title: 'Seamless Integration',
    description:
      'Integrate financial management directly into your project workflow for a cohesive experience.',
  },
];

export const REAL_TIME_MARGIN_TRACKING_DATA = [
  {
    id: 1,
    title: 'Dynamic Margin Insights',
    description:
      'Access real-time graphs comparing estimated and actual margins to track profitability at every stage.',
    image: Real_Time_Tracking_Img,
  },
  {
    id: 2,
    title: 'Actionable Alerts',
    description:
      'Receive alerts when margins shift from positive to at-risk or critical, enabling timely interventions.',
    image: Real_Time_Tracking_Img,
  },
];

export const FINANCIAL_PLANING_AND_TRACKING = [
  {
    id: 1,
    title: 'Planning and Estimation Tools',
    description: `Plan project estimates and margins with confidence, leveraging historical data and real-time inputs.`,
    image: Integrated_Chat_Img,
    imgAbove: false,
  },
  {
    id: 2,
    title: 'Continuous Financial Tracking',
    description: `Stay updated with real-time financial data to adapt to changes and maintain control.`,
    image: Real_Time_Update_Img,
    imgAbove: false,
  },
  {
    id: 3,
    title: 'Enhanced Decision-Making',
    description: `Utilize predictive insights and real-time tracking to make smarter financial decisions.`,
    image: Real_Time_Update_Img,
    imgAbove: false,
  },
];

export const TRANSPARENT_TRACKING_DATA = [
  {
    id: 1,
    title: 'Audit-Ready Logs',
    description:
      'Track every change made to the financial data with a detailed log table for complete transparency.',
  },
  {
    id: 2,
    title: 'Access-Based Security',
    description:
      'Restrict financial data access based on roles, ensuring sensitive information remains secure.',
  },
];

//  Resource Management Data

export const STEPS_DATA_RESOURCE_MANAGEMENT = [
  {
    id: 1,
    title: 'ALLOCATE',
  },
  {
    id: 2,
    title: 'OPTIMISE',
  },
  {
    id: 3,
    title: 'FORECAST',
  },
];

export const RESOURCE_MANAGEMENT = {
  header: 'RESOURCE MANAGEMENT',
  title: 'Optimize resource allocation effortlessly',
  description: `Allocate, track, and optimize resources effortlessly to ensure your team is always working at full potential.`,
};
export const HOLISTIC_RESOURCE_DATA = [
  {
    id: 1,
    title: 'Overall Utilization',
    description:
      'Track overall utilization percentages to understand how effectively your resources are being deployed.',
    image: Holistic_Resource_Img1,
  },
  {
    id: 2,
    title: 'Demand and Capacity Insights',
    description:
      'Monitor total demand in hours, along with billable and non-billable percentages, to optimize resource allocation.',
    image: Holistic_Resource_Img1,
  },
  {
    id: 3,
    title: 'High-Level Overview',
    description:
      'Get a quick snapshot of your team’s capacity and workload distribution for better resource planning.',
    image: Holistic_Resource_Img1,
  },
];

export const EMPLOYEE_PROJECT_ALLOCATION = [
  {
    id: 1,
    title: 'Employee Capacity Monitoring',
    description: `View individual employee capacity and identify any over-assigned team members on a weekly or monthly basis.`,
    image: PredictiveAnalysisImg,
    imgAbove: true,
  },
  {
    id: 2,
    title: 'Project-Centric View',
    description: `Analyze resource allocation by project to ensure balanced workloads across teams.`,
    image: PredictiveAnalysisImg,
    imgAbove: true,
  },
];

export const TALENT_FINDER_TOOL = [
  {
    id: 1,
    title: 'Skill-Based Search',
    description:
      'Identify the best-fit team members quickly to accelerate project progress and ensure skill alignment.',
  },
  {
    id: 2,
    title: 'Comprehensive Criteria',
    description:
      'Refine your search using additional filters like project details, allocation percentages, and more.',
  },
];

export const DYNAMIC_TASK_MANAGEMENT = [
  {
    id: 1,
    title: 'Interactive Scheduling',
    description: `Easily adjust timelines and task assignments to ensure optimal resource utilization.`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
  {
    id: 2,
    title: 'Real-Time Updates',
    description: `Make changes on the fly and see immediate updates across all project views.`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
  {
    id: 3,
    title: 'Streamlined Coordination',
    description: `Keep your team aligned with dynamic scheduling and task reassignment capabilities.`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
];

export const ENHANCED_RESOURCE_DATA = [
  {
    id: 1,
    title: 'Real-Time Resource Metrics',
    description: `Stay on top of your resource metrics with live data updates and actionable insights.`,
  },
  {
    id: 2,
    title: 'Informed Decision-Making',
    description:
      'Use data-driven insights to make smarter decisions, enhance productivity, and maximize resource efficiency.',
  },
  {
    id: 3,
    title: 'Customizable Views',
    description:
      'Switch between employee and project views for a tailored resource management experience.',
  },
];

// client management
export const CLIENT_MANAGEMENT = {
  header: 'CLIENT MANAGEMENT',
  title: 'Deliver exceptional client experiences',
  description: `Streamline communication, track project progress, and deliver exceptional service with our client management tools.`,
};

export const DEDICATED_CUSTOMER_PORTAL = [
  {
    id: 1,
    title: 'Exclusive Project Access',
    description: `Clients can access a dedicated web version of Squad’s project module, offering them a comprehensive view of their projects.`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
  {
    id: 2,
    title: 'Secure and User-Friendly Interface',
    description: `The portal offers a secure environment where clients can easily navigate and access project-related information, enhancing their experience and trust.`,
    image: PredictiveAnalysisImg,
    imgAbove: false,
  },
];

export const CONTROL_VISIBILITY = [
  {
    id: 1,
    title: 'Public vs private tasks',
    description:
      'Ensure all your data is encrypted both in transit and at rest.',
  },
  {
    id: 2,
    title: 'Access Controls',
    description:
      'Define user roles and permissions to control access to sensitive information.',
  },
];

export const COLLABORATE_WITH_TEAM = [
  {
    id: 1,
    title: 'Exclusive Project Access',
    description: `Clients can access a dedicated web version of Squad’s project module, offering them a comprehensive view of their projects.`,
    image: PredictiveAnalysisImg,
    imgAbove: true,
  },
  {
    id: 2,
    title: 'Secure and User-Friendly Interface',
    description: `The portal offers a secure environment where clients can easily navigate and access project-related information, enhancing their experience and trust.`,
    image: PredictiveAnalysisImg,
    imgAbove: true,
  },
];
export const PROJECT_MONITER = [
  {
    id: 1,
    title: 'Project Overview',
    description:
      'Provide a snapshot of the project’s status with the Overview tab, giving clients a clear understanding of timelines, deliverables, and overall progress.',
    image: Project_Moniter_Img,
  },
  {
    id: 2,
    title: 'Task Updates and Notifications',
    description:
      'With the dedicated customer portal, clients work hand in hand with the team and have access to most features available to a team member.',
    image: Project_Moniter_Img,
  },
];

export const CLIENT_FEEDBACK_MATTERS = [
  {
    id: 1,
    title: 'Milestone based feedback',
    description: `Automate repetitive tasks to increase efficiency and reduce errors.`,
  },
  {
    id: 2,
    title: 'Benchmarks',
    description:
      'Add specific data fields to capture all necessary project information.',
  },
  {
    id: 3,
    title: 'Template Library',
    description:
      'Utilize pre-built templates or create your own to standardize processes.',
  },
];

// employee management

export const STEPS_DATA_EMPLOYEE_MANAGEMENT = [
  {
    id: 1,
    title: 'EMPOWER',
  },
  {
    id: 2,
    title: 'ENGAGE',
  },
  {
    id: 3,
    title: 'ELEVATE',
  },
];

export const EMPLOYEE_MANAGEMENT = {
  header: 'EMPLOYEE MANAGEMENT',
  title: 'Empower your workforce with smart tools',
  description: `Manage schedules, performance, and development seamlessly to keep your team motivated and aligned.`,
};

export const EMPLOYEE_MANAGEMENT_TOOLS = [
  {
    id: 1,
    title: 'Employee Engagement',
    description: `Foster a connected workplace with features like the Wall of Moments and Wall of Fame, celebrating achievements and building a culture of recognition.`,
  },
  {
    id: 2,
    title: 'Learning & Development',
    description: `Empower your employees with personalized upskilling journeys, tailored assessments, and dynamic training programs to drive career growth.`,
  },
  {
    id: 3,
    title: 'Real-Time Analytics',
    description: `Leverage data-driven insights to make informed decisions, enhance productivity, and proactively engage with your workforce.`,
  },
  {
    id: 4,
    title: 'Timesheet & Leave Management',
    description: `Streamline time tracking and leave requests with intuitive tools, ensuring operational efficiency and transparency.`,
  },
  {
    id: 5,
    title: 'Capacity & Task Management',
    description: `Optimize resource allocation and workload distribution, ensuring balanced team capacity and preventing burnout.`,
  },
  {
    id: 6,
    title: 'Performance & Goal Setting',
    description: `Set clear objectives and provide regular feedback through structured performance reviews, helping employees reach their full potential.`,
  },
];

export const COLLABORATE_WITH_OFFICE = [
  {
    id: 1,
    title: 'Instant Messaging & Group Chats',
    description: `Stay connected with your team through instant messaging and group chats, fostering real-time communication`,
    image: Integrated_Chat_Img,
    imgAbove: true,
  },
  {
    id: 2,
    title: 'Video & Voice Calls',
    description: `Host virtual meetings effortlessly with built-in video and voice call functionality, ensuring smooth and efficient collaboration.`,
    image: Video_Voice_Call_Img,
    imgAbove: true,
  },
];

export const TIMESHEET_AND_LEAVE_DATA = [
  {
    id: 1,
    title: 'Timesheet Management',
    description:
      'Ensure precise time tracking with automated timesheets and approval mechanisms.',
    image: Timesheet_Management_Img,
  },
  {
    id: 2,
    title: 'Leave Management',
    description:
      'Simplify leave requests and approvals with a transparent system that keeps everyone informed and aligned.',
    image: Holistic_Resource_Img1,
  },
  {
    id: 3,
    title: 'Set Holiday Calendar',
    description:
      'Upload your organisations holiday calendar and let your employees know when to plan their next leave.',
    image: Holistic_Resource_Img1,
  },
];

export const CULTIVATE_ENGAGEMENT_MOMENTS_DATA = [
  {
    id: 1,
    title: 'Social space for employees',
    description: `Share key achievements and memorable moments to build a more connected, engaged workforce.`,
  },
  {
    id: 2,
    title: 'Recognize contributions with Kudos and Rewards',
    description: 'Recognize contributions with Kudos and Rewards',
  },
  {
    id: 3,
    title: 'System generated posts',
    description:
      'Squad also posts on your behalf when a holiday is near or a project is completed.',
  },
];

export const PERSONALIZED_LEARNING_DATA = [
  {
    id: 1,
    title: 'Learning Development Journeys',
    description:
      'Curate individualized development plans that align personal growth with organizational goals.',
    image: Employee_Report_Img,
  },
  {
    id: 2,
    title: 'Trainings',
    description:
      'Create custom trainings, assign to roles and mark mandatory as well as general ones.',
    image: Employee_Report_Img,
  },
  {
    id: 3,
    title: 'Skill Assessments',
    description:
      'Evaluate employee skills to identify growth areas and tailor development strategies.',
    image: Employee_Report_Img,
  },
];

export const DRIVE_PERFORMANCE_OBJECTIVE_DATA = [
  {
    id: 1,
    title: 'Weighted Goals',
    description: 'One goal different weightage for different roles.',
    image: Timesheet_Management_Img,
  },
  {
    id: 2,
    title: 'Performance Reviews',
    description:
      'Conduct performance reviews within squad and have a holistic employee performance view.',
    image: Holistic_Resource_Img1,
  },
  {
    id: 3,
    title: 'Score Sheets',
    description:
      'Generate combined score sheets, with KPI level scores and comments from peers and manager.',
    image: Holistic_Resource_Img1,
  },
];

export const SEAMLESS_INTEGRATION_ACCESS_CONTROL_DATA = [
  {
    id: 1,
    title: 'Audit-Ready Logs',
    description:
      'Track every change made to the financial data with a detailed log table for complete transparency.',
  },
  {
    id: 2,
    title: 'Access-Based Security',
    description:
      'Restrict financial data access based on roles, ensuring sensitive information remains secure.',
  },
];
