import { useState } from 'react';
import CommonButton from './CommonButton';
import CoreFeature from './CoreFeature';
import GradientBorderSectionTitle from './GradientBorderSectionTitle';
import PlanDetails from './PlanDetails';
import { calculatePrice } from './Utils';

export const Starter = () => {
  const [option, setOption] = useState<string>('Yearly');

  return (
    <div className="lg:px-[108px] px-4 py-14">
      <div className="w-full flex flex-col items-start justify-center text-primary-text font-medium font-poppins ">
        <div className="flex flex-col gap-6">
          <div className="w-fit">
            <GradientBorderSectionTitle title="PRICING" />
          </div>
          <div className="lg:text-h1-text text-h2-text">
            Caliber of big tools, cost of one
          </div>
        </div>
      </div>
      <div className="flex max-lg:mt-6">
        <div className="flex flex-col">
          <div className="flex">
            {' '}
            <div
              onClick={() => setOption('Monthly')}
              className={`py-2 px-4 cursor-pointer rounded-l-sm ${option === 'Monthly' ? 'bg-[#E0E0E0]  text-primary-text font-poppins' : 'bg-[#F5F5F5] text-[#757575]'}`}
            >
              Monthly
            </div>
            <div
              onClick={() => setOption('Yearly')}
              className={`  py-2 px-4 cursor-pointer ${option === 'Yearly' ? 'bg-[#E0E0E0]  text-primary-text font-poppins' : 'bg-[#F5F5F5] text-[#757575]'}`}
            >
              Yearly
            </div>
            <div
              onClick={() => setOption('Life Time')}
              className={` py-2 px-4 cursor-pointer rounded-r-sm ${option === 'Life Time' ? 'bg-[#E0E0E0]  text-primary-text font-poppins' : 'bg-[#F5F5F5] text-[#757575]'}`}
            >
              Life Time
            </div>
          </div>
          {option !== 'Monthly' && (
            <div className="text-[#FF9900] flex justify-start py-1 px-2 mt-2 bg-[#FF99001A]  rounded-sm w-fit">
              {option === 'Yearly' ? 'Save up to 10%' : 'Save up to 20%'}
            </div>
          )}
        </div>
      </div>
      <PlanDetails option={option} />

      <div className="w-full flex flex-col gap-1 items-start justify-center pt-[2rem] sm:pt-[16px]  text-primary-text font-poppins text-3xl text-[64px] sm:text-[72px] font-medium font-poppins leading-[3.5rem] sm:leading-[5rem] ">
        <div className="w-fit max-lg:pb-6 ">
          <GradientBorderSectionTitle title="ALL FEATURES" />
        </div>
      </div>
      <div className="flex gap-8 sticky top-20  z-10 bg-white lg:flex-row flex-col w-full">
        <div className="lg:w-[20%] w-full text-h2-text text-primary-text font-poppins">
          Core <br className="max-lg:hidden block" /> Platform Features
        </div>
        <div className=" flex lg:gap-8 gap-5 lg:w-[80%] w-full">
          <div className="w-1/3 text-center">
            <div className="text-base font-bold  text-primary-text font-poppins py-1 text-center">
              Experience Empowered
            </div>
            <div className="text-2xl font-semibold  text-primary-text font-poppins py-1 text-center">
              Free
            </div>
            <div className="text-h3-text text-[#404040] py-1 text-center">
              Free forever
            </div>
            <CommonButton />
          </div>
          <div className="w-1/3 text-center">
            <div className="text-base font-bold  text-primary-text font-poppins py-1">
              Productivity Plus
            </div>
            <div className="py-1">
              <span className="text-2xl font-semibold  text-primary-text font-poppins">
                {'$' + calculatePrice(8, option)}
              </span>
              <span className="text-[#404040] text-xl">/user </span>
            </div>
            <div className="text-[#404040] text-h3-text py-1 text-center">
              {option === 'Monthly'
                ? 'Billed Monthly'
                : option === 'Yearly'
                  ? 'Billed Yearly'
                  : 'Life Time'}
            </div>
            <CommonButton highlight />
          </div>
          <div className="w-1/3 text-center">
            <div className="text-base font-bold  text-primary-text font-poppins py-1">
              Growth Genius
            </div>
            <div className="py-1">
              <span className="text-2xl font-semibold  text-primary-text font-poppins">
                {'$' + calculatePrice(15, option)}
              </span>
              <span className="text-[#404040] text-xl">/user </span>
            </div>
            <div className="text-[#404040] text-h3-text text-center py-1">
              {option === 'Monthly'
                ? 'Billed Monthly'
                : option === 'Yearly'
                  ? 'Billed Yearly'
                  : 'Life Time'}
            </div>
            <CommonButton highlight />
          </div>
        </div>
      </div>
      <CoreFeature />
    </div>
  );
};
