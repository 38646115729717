import { ApiResponse, WhitePaper } from '../types/types';
import axiosInstance from './axiosInstance';

export const fetchWhitePapers = async (
  category = '',
  page = 1,
  { signal }: { signal?: AbortSignal } = {},
): Promise<ApiResponse<WhitePaper>> => {
  let url = `/api/worx-squad-white-papers?populate=*&pagination[page]=${page}&pagination[pageSize]=6`;
  try {
    if (category && category !== 'All') {
      const encodedCategory = encodeURIComponent(category);
      url += `&filters[worx_squad_white_paper_category][title][$containsi]=${encodedCategory}`;
    }
    const { data } = await axiosInstance.get(url, { signal });
    return data;
  } catch (err) {
    throw new Error(`Blogs API Error: ${err}`);
  }
};

// Fetch a single whitePaper by slug
export const fetchWhitepaperBySlug = async (
  slug: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<ApiResponse<WhitePaper>> => {
  try {
    const { data } = await axiosInstance.get(
      `/api/worx-squad-white-papers?filters[slug][$eq]=${slug}&populate=*`,
      { signal },
    );
    return data;
  } catch (error) {
    throw new Error(`WhitePaper API Error: ${error}`);
  }
};
