import { FC } from 'react';
import CalendlyWidget from '../CommonComponent/CalendlyWidget';
import ContactHeader from './ContactHeader';

const CalendlyCard: FC = () => {
  return (
    <div className="w-full flex flex-col">
      <ContactHeader
        title="Book a demo"
        description="Schedule a free demo with us."
      />

      <div className=" rounded">
        <CalendlyWidget />
      </div>
    </div>
  );
};

export default CalendlyCard;
