import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import ContactUs from '../components/CommonComponent/ContactUs';

import { CommonLayout } from '../components/Layouts/CommonLayout';
import { Starter } from '../components/SquadPricingPlans/Starter';

const Pricing = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  return (
    <CommonLayout>
      <div className="relative">
        <Starter />
        <ContactUs />
      </div>
    </CommonLayout>
  );
};

export default Pricing;
