import { FC } from 'react';
import { CommonDataType } from '../../../types/types';
import WhiteBgButton from '../../Button/WhiteBgButton';
import { BookADemo } from '../../Home/Utils';
import RobustCard from './RobustCard';

interface CustomAnalysisSectionProps {
  data: CommonDataType[];
  title: string;
  description: string;
  widthRight?: string;
  widthLeft?: string;
}

const CustomAnalysisSection: FC<CustomAnalysisSectionProps> = ({
  data,
  description,
  title,
  widthRight = '55%',
  widthLeft = '45%',
}) => {
  return (
    <div className="flex lg:px-[108px] lg:flex-row flex-col px-4 lg:py-20 py-14 lg:gap-14 gap-6 bg-background-light border border-t-primary">
      {/* left side */}
      <div className={`flex flex-col gap-6 lg:w-[${widthLeft}] w-full`}>
        <div className="flex flex-col gap-3">
          <h2 className=" text-h2-text text-primary-text-black">{title}</h2>
          <p className=" text-tertiary-text text-lg-text font-normal w-[90%]">
            {description}
          </p>
        </div>
        {/* button */}
        <WhiteBgButton
          border
          text="Get started"
          handleClick={BookADemo}
          expanded
        />
      </div>

      {/* right side */}
      <div
        className={`lg:w-[${widthRight}] w-full flex gap-6 lg:flex-row flex-col`}
      >
        {data?.map((value, index) => (
          <RobustCard value={value} index={index} data={data} />
        ))}
      </div>
    </div>
  );
};

export default CustomAnalysisSection;
