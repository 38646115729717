const WatchSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M3.73145 12.3421C3.73145 7.6477 7.53702 3.84212 12.2314 3.84212C16.9259 3.84212 20.7314 7.6477 20.7314 12.3421C20.7314 17.0365 16.9259 20.8421 12.2314 20.8421C7.53702 20.8421 3.73145 17.0365 3.73145 12.3421ZM12.2314 2.34212C6.7086 2.34212 2.23145 6.81927 2.23145 12.3421C2.23145 17.865 6.7086 22.3421 12.2314 22.3421C17.7543 22.3421 22.2314 17.865 22.2314 12.3421C22.2314 6.81927 17.7543 2.34212 12.2314 2.34212ZM12.2246 6.99039C12.1749 6.62445 11.8609 6.34212 11.4814 6.34212C11.0674 6.34212 10.7314 6.67812 10.7314 7.09212V13.0921L10.7383 13.1938C10.788 13.5598 11.1019 13.8421 11.4814 13.8421H15.4814L15.5832 13.8353C15.9491 13.7856 16.2314 13.4716 16.2314 13.0921C16.2314 12.6781 15.8954 12.3421 15.4814 12.3421H12.2314V7.09212L12.2246 6.99039Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};

export default WatchSVG;
