import {
  DefaultButton,
  MessageBarType,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { FormType } from '@worx.squad/app-sdk/base-types';
import {
  Form,
  STextFormFieldStyleEmailNPhone,
  STextFormFieldStyleMobileView,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SectionHeader from '../CommonComponent/SectionHeader';
import { useContactUsMutation } from '../Contact/home.generated';
import { figmaDemoSchema } from './FigmaDemoSchema';

const FigmaEmbed: React.FC = React.memo(() => {
  const [, contactUs] = useContactUsMutation();
  const { notification } = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({ email: '', phoneno: '' });
  const [isEmailSubmitted, setIsEmailSubmitted] = useState<boolean>(false);

  const isMobile = window.innerWidth < 768;
  const figmaDemoForm = useForm({
    mode: 'all',
    resolver: figmaDemoSchema,
    defaultValues: {
      email: '',
      phone_no: '',
    },
  });

  const { handleSubmit } = figmaDemoForm;

  // Handle form submission
  const handleEmailSubmit = useCallback(
    async (data: any) => {
      setLoader(true);
      try {
        await contactUs({
          object: {
            formType: FormType?.TourType,
            email: data?.email,
            phone: data?.phone_no,
          },
        });
        setIsEmailSubmitted(true);
      } catch (error) {
        notification({
          message: 'Submission failed',
          type: MessageBarType.error,
        });
      } finally {
        setLoader(false);
      }
    },
    [formData, contactUs, notification],
  );

  // Handle iframe loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // cleanup on component unMount
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="py-20 bg-background-dark lg:block hidden">
      <div className="pb-10 max-lg:px-4">
        <SectionHeader
          title="Experience Squad in action"
          description="Take a live tour to see how our productivity tools can transform your team’s workflow."
          variant="white"
        />
      </div>

      <div className="w-full h-[100vh] m-0 p-0 relative px-[108xp]">
        {/* Loading Spinner */}
        {isLoading && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white">
            <Spinner
              size={SpinnerSize.large}
              label="Loading Figma Prototype..."
            />
          </div>
        )}

        {/* Figma Embed */}
        <iframe
          className={`border-0 w-full h-[100vh] transition-all duration-300 ${isEmailSubmitted ? '' : 'bg-gray-300'}`}
          src="https://embed.figma.com/proto/fSkh9C5PPirehhhPiUYlaL/Main?page-id=0%3A1&node-id=4255-146947&t=hXx2wuERXyUYCtVA-0&scaling=scale-down&content-scaling=fixed&starting-point-node-id=4208%3A87618&share=1&embed-host=share&hide-ui=1"
          allowFullScreen
          title="Experience Squad in action"
        />

        {/* Form Overlay */}
        {!isEmailSubmitted && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-40">
            <div className="bg-white p-8 rounded shadow-lg flex flex-col gap-8 max-w-lg w-full">
              <h2 className="text-h2-text text-primary-text text-start font-medium">
                Explore Squad
              </h2>
              <Form
                className="flex flex-col w-full sm:w-full gap-8"
                formHook={figmaDemoForm}
                onSubmit={handleSubmit(handleEmailSubmit)}
              >
                <div className="flex flex-col sm:flex-row gap-5 w-full">
                  <label className=" text-tertiary-text font-poppins w-full">
                    Email Address
                    <TextField
                      formHook={figmaDemoForm}
                      name="email"
                      styles={
                        isMobile
                          ? STextFormFieldStyleMobileView
                          : STextFormFieldStyleEmailNPhone
                      }
                      placeholder="name@yourcompnay.com"
                      className="w-full"
                      borderless
                    />
                  </label>
                </div>
                <div className="flex flex-col sm:flex-row gap-5 w-full">
                  <label className=" text-tertiary-text font-poppins w-full">
                    Phone Number (Optional)
                  </label>
                  <TextField
                    formHook={figmaDemoForm}
                    name="phone_no"
                    styles={
                      isMobile
                        ? STextFormFieldStyleMobileView
                        : STextFormFieldStyleEmailNPhone
                    }
                    placeholder="1234567892"
                    className="w-full text-primary-text"
                    borderless
                  />
                </div>

                <div className="w-full mt-2.5">
                  <DefaultButton
                    className="bg-[#FF9900] w-full sm:w-auto border rounded-sm border-[#FF9900] text-primary-text hover:bg-[#CC7A00] px-6 py-5 ease-in-out duration-300 hover:text-primary-text"
                    type="submit"
                  >
                    {loader ? <Spinner /> : 'Unlock'}
                  </DefaultButton>
                </div>
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default FigmaEmbed;
