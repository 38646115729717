import { FC } from 'react';
import GradientBorderSectionTitle from '../SquadPricingPlans/GradientBorderSectionTitle';

interface CardHeaderProps {
  header: string;
  title: string;
  color: 'white' | 'black';
}

const CardHeader: FC<CardHeaderProps> = ({ header, title, color }) => {
  return (
    <div className="flex flex-col gap-5">
      <div className="w-fit">
        <GradientBorderSectionTitle title={header} />
      </div>
      <h2
        className={`text-h2-text font-medium  ${color === 'black' ? 'text-[#101010]' : 'text-[#FBFBFB]'}`}
      >
        {title}
      </h2>
    </div>
  );
};

export default CardHeader;
