import { FC } from 'react';

interface LinkedinIconProps {
  variant?: 'white' | 'black';
}

const Linkedin: FC<LinkedinIconProps> = ({ variant = 'black' }) => {
  return (
    <div>
      {variant === 'black' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_6090_15974)">
            <path
              d="M20.7273 0H3.27273C1.46525 0 0 1.46525 0 3.27273V20.7273C0 22.5347 1.46525 24 3.27273 24H20.7273C22.5347 24 24 22.5347 24 20.7273V3.27273C24 1.46525 22.5347 0 20.7273 0Z"
              fill="#252B37"
            />
            <path
              d="M8.6456 6.54545C8.6456 6.95001 8.52563 7.34547 8.30088 7.68185C8.07612 8.01822 7.75666 8.28039 7.3829 8.43521C7.00915 8.59002 6.59787 8.63053 6.20109 8.55161C5.80432 8.47268 5.43985 8.27787 5.15379 7.99181C4.86773 7.70575 4.67292 7.34128 4.59399 6.9445C4.51507 6.54772 4.55557 6.13645 4.71039 5.76269C4.8652 5.38894 5.12738 5.06948 5.46375 4.84472C5.80012 4.61996 6.19559 4.5 6.60014 4.5C7.14263 4.5 7.6629 4.7155 8.0465 5.0991C8.4301 5.4827 8.6456 6.00297 8.6456 6.54545Z"
              fill="white"
            />
            <path
              d="M8.18081 9.95454V18.9914C8.18117 19.058 8.16835 19.1241 8.14309 19.1858C8.11782 19.2475 8.08062 19.3036 8.0336 19.3509C7.98659 19.3981 7.9307 19.4356 7.86914 19.4612C7.80758 19.4868 7.74157 19.5 7.6749 19.5H5.51899C5.45233 19.5002 5.38628 19.4872 5.32466 19.4617C5.26303 19.4363 5.20704 19.399 5.1599 19.3518C5.11276 19.3047 5.0754 19.2487 5.04997 19.1871C5.02454 19.1254 5.01154 19.0594 5.01172 18.9927V9.95454C5.01172 9.82 5.06517 9.69097 5.1603 9.59584C5.25543 9.50071 5.38446 9.44727 5.51899 9.44727H7.6749C7.8092 9.44763 7.93788 9.50123 8.03272 9.59632C8.12755 9.69142 8.18081 9.82024 8.18081 9.95454Z"
              fill="white"
            />
            <path
              d="M19.4507 14.6587V19.0332C19.4509 19.0945 19.439 19.1553 19.4156 19.2119C19.3922 19.2686 19.3579 19.3201 19.3145 19.3634C19.2712 19.4068 19.2197 19.4411 19.1631 19.4645C19.1064 19.4878 19.0457 19.4998 18.9844 19.4996H16.6662C16.6049 19.4998 16.5442 19.4878 16.4875 19.4645C16.4308 19.4411 16.3794 19.4068 16.336 19.3634C16.2927 19.3201 16.2583 19.2686 16.235 19.2119C16.2116 19.1553 16.1997 19.0945 16.1998 19.0332V14.7937C16.1998 14.161 16.3853 12.0228 14.5457 12.0228C13.1207 12.0228 12.8303 13.486 12.773 14.1432V19.0332C12.773 19.1558 12.7248 19.2734 12.6388 19.3606C12.5528 19.4479 12.436 19.4978 12.3135 19.4996H10.0744C10.0132 19.4996 9.95262 19.4875 9.89611 19.4641C9.83961 19.4406 9.78829 19.4062 9.74509 19.3629C9.70189 19.3196 9.66767 19.2682 9.64438 19.2116C9.62109 19.155 9.6092 19.0944 9.60938 19.0332V9.9146C9.6092 9.85342 9.62109 9.79281 9.64438 9.73624C9.66767 9.67966 9.70189 9.62824 9.74509 9.58492C9.78829 9.54159 9.83961 9.50722 9.89611 9.48376C9.95262 9.46031 10.0132 9.44824 10.0744 9.44824H12.3135C12.4372 9.44824 12.5558 9.49737 12.6432 9.58483C12.7307 9.67229 12.7798 9.79091 12.7798 9.9146V10.7028C13.3089 9.90915 14.093 9.29688 15.7662 9.29688C19.4726 9.29688 19.4507 12.7578 19.4507 14.6587Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_6090_15974">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_6090_16049)">
            <path
              d="M20.7273 0H3.27273C1.46525 0 0 1.46525 0 3.27273V20.7273C0 22.5347 1.46525 24 3.27273 24H20.7273C22.5347 24 24 22.5347 24 20.7273V3.27273C24 1.46525 22.5347 0 20.7273 0Z"
              fill="white"
            />
            <path
              d="M8.6456 6.54545C8.6456 6.95001 8.52563 7.34547 8.30088 7.68185C8.07612 8.01822 7.75666 8.28039 7.3829 8.43521C7.00915 8.59002 6.59787 8.63053 6.20109 8.55161C5.80432 8.47268 5.43985 8.27787 5.15379 7.99181C4.86773 7.70575 4.67292 7.34128 4.59399 6.9445C4.51507 6.54772 4.55557 6.13645 4.71039 5.76269C4.8652 5.38894 5.12738 5.06948 5.46375 4.84472C5.80012 4.61996 6.19559 4.5 6.60014 4.5C7.14263 4.5 7.6629 4.7155 8.0465 5.0991C8.4301 5.4827 8.6456 6.00297 8.6456 6.54545Z"
              fill="#207C97"
            />
            <path
              d="M8.18081 9.95454V18.9914C8.18117 19.058 8.16835 19.1241 8.14309 19.1858C8.11782 19.2475 8.08062 19.3036 8.0336 19.3509C7.98659 19.3981 7.9307 19.4356 7.86914 19.4612C7.80758 19.4868 7.74157 19.5 7.6749 19.5H5.51899C5.45233 19.5002 5.38628 19.4872 5.32466 19.4617C5.26303 19.4363 5.20704 19.399 5.1599 19.3518C5.11276 19.3047 5.0754 19.2487 5.04997 19.1871C5.02454 19.1254 5.01154 19.0594 5.01172 18.9927V9.95454C5.01172 9.82 5.06517 9.69097 5.1603 9.59584C5.25543 9.50071 5.38446 9.44727 5.51899 9.44727H7.6749C7.8092 9.44763 7.93788 9.50123 8.03272 9.59632C8.12755 9.69142 8.18081 9.82024 8.18081 9.95454Z"
              fill="#207C97"
            />
            <path
              d="M19.4507 14.6587V19.0332C19.4509 19.0945 19.439 19.1553 19.4156 19.2119C19.3922 19.2686 19.3579 19.3201 19.3145 19.3634C19.2712 19.4068 19.2197 19.4411 19.1631 19.4645C19.1064 19.4878 19.0457 19.4998 18.9844 19.4996H16.6662C16.6049 19.4998 16.5442 19.4878 16.4875 19.4645C16.4308 19.4411 16.3794 19.4068 16.336 19.3634C16.2927 19.3201 16.2583 19.2686 16.235 19.2119C16.2116 19.1553 16.1997 19.0945 16.1998 19.0332V14.7937C16.1998 14.161 16.3853 12.0228 14.5457 12.0228C13.1207 12.0228 12.8303 13.486 12.773 14.1432V19.0332C12.773 19.1558 12.7248 19.2734 12.6388 19.3606C12.5528 19.4479 12.436 19.4978 12.3135 19.4996H10.0744C10.0132 19.4996 9.95262 19.4875 9.89611 19.4641C9.83961 19.4406 9.78829 19.4062 9.74509 19.3629C9.70189 19.3196 9.66767 19.2682 9.64438 19.2116C9.62109 19.155 9.6092 19.0944 9.60938 19.0332V9.9146C9.6092 9.85342 9.62109 9.79281 9.64438 9.73624C9.66767 9.67966 9.70189 9.62824 9.74509 9.58492C9.78829 9.54159 9.83961 9.50722 9.89611 9.48376C9.95262 9.46031 10.0132 9.44824 10.0744 9.44824H12.3135C12.4372 9.44824 12.5558 9.49737 12.6432 9.58483C12.7307 9.67229 12.7798 9.79091 12.7798 9.9146V10.7028C13.3089 9.90915 14.093 9.29688 15.7662 9.29688C19.4726 9.29688 19.4507 12.7578 19.4507 14.6587Z"
              fill="#207C97"
            />
          </g>
          <defs>
            <clipPath id="clip0_6090_16049">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default Linkedin;
