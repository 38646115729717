import ControlImg from './assets/ControlImg.png';
import InsightImg from './assets/InsightImg.png';
import ResourceMngImg from './assets/ResourceMngImg.png';
import CardHeader from './CardHeader';

const ResourceManagement = () => {
  return (
    <div className="lg:py-20 py-14 lg:pr-[108px] pr-4 flex flex-col lg:gap-20 gap-10">
      <div className="flex lg:gap-14 gap-6 w-full justify-between flex-col lg:flex-row">
        <div className="flex flex-col gap-4 lg:w-[40%] w-full">
          <CardHeader
            title=" Efficient resource management at your fingertips."
            header="RESOURCE MANAGEMENT"
            color="white"
          />
          <p className="text-lg-text font-normal text-[#FBFBFB]">
            Allocate and monitor workforce resources across projects.
          </p>
        </div>
        <div className="lg:w-[40%] w-full">
          <img
            src={ResourceMngImg}
            alt="ResourceMngImg"
            className=" rounded-sm"
          />
        </div>
      </div>
      <div className="flex justify-between w-full gap-9 max-h-[288px] flex-col lg:flex-row">
        <div className="lg:w-[65%] w-full">
          <img src={InsightImg} alt="InsightImg" className=" rounded" />
        </div>
        <div className="lg:w-[35%] w-full">
          <img src={ControlImg} alt="ControlImg" className=" rounded" />
        </div>
      </div>
    </div>
  );
};

export default ResourceManagement;
