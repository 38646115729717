import PrimaryButton from '../Button/PrimaryButton';
import HomePage from '../Home/assets/AnimationGif.gif';
import { BookADemo } from './Utils';

export const Starter = () => {
  return (
    <div className="">
      <div className="text-lg bg-[#141313] lg:px-[108px] lg:pb-20 px-4 max-lg:pt-14 pb-14">
        <div className="lg:h-[100dvh] items-center justify-center flex">
          <div className="pb-20 lg:flex lg:flex-row flex-col lg:gap-4 gap-6 justify-between">
            <div className="flex flex-col w-full xl:w-2/3 self-center  max-lg:gap-6">
              <h1 className="lg:text-h1-text text-h2-text font-poppins bg-text-gradient  bg-clip-text text-transparent">
                Reimagine Enterprise <br />
                Productivity
              </h1>
              <p className="text-xl-text bg-text-gradient  bg-clip-text text-transparent max-lg:w-[90%]">
                A unified digital workplace where Projects, Clients &{' '}
                <br className="lg:block hidden" />
                Employees work together
              </p>
              <div className="mt-12">
                <PrimaryButton
                  text="Book A Demo"
                  handleClick={BookADemo}
                  expanded
                />
              </div>
            </div>
            <div className="xl:block">
              <img
                src={HomePage}
                alt="HomePage"
                className="max-lg:mt-32 lg:w-[668px] w-full"
              />
            </div>
          </div>
        </div>
        <div className="w-full rounded-lg">
          <iframe
            className="w-full rounded-lg"
            height="480"
            src="https://www.youtube-nocookie.com/embed/NjTZsfbdMdk?rel=0&showinfo=0&controls=0&modestbranding=1&iv_load_policy=3&fs=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* <OurMission /> */}
    </div>
  );
};
