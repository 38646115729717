// components/Layout/Layout.tsx
import { useLayoutState } from '@worx.squad/shared-frontend';
import { FC, ReactNode, useLayoutEffect } from 'react';
import CalendlyWidget from '../CommonComponent/CalendlyWidget';
import { Footer } from '../CommonComponent/Footer';
import { Header } from '../CommonComponent/Header';
import BoostYourProductivity from '../Home/BoostYourProductivity';

interface CommonLayoutProps {
  children: ReactNode;
  layoutType?: 'normal' | 'app';
  calendlyWidget?: boolean;
  productivity?: boolean;
}

export const CommonLayout: FC<CommonLayoutProps> = ({
  children,
  layoutType = 'normal',
  calendlyWidget = true,
  productivity = true,
}) => {
  const [, setLayoutState] = useLayoutState();

  useLayoutEffect(() => {
    setLayoutState?.(layoutType);
  }, [setLayoutState, layoutType]);

  return (
    <div className="relative min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">{children}</main>
      {calendlyWidget && <CalendlyWidget />}
      {productivity && <BoostYourProductivity />}
      <Footer />
    </div>
  );
};
