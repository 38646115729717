import { FC, useState } from 'react';
import VectorSvg from './Assets/BgVector.svg';
import Linkedin from './Assets/Linkedin';
import Twitter from './Assets/Twitter';
import { shareOnLinkedIn, shareOnTwitter } from './Utils';
interface BlogAsideProps {
  sidebarTitles: {
    title: string;
    id: string;
  }[];
  blogTitle?: string;
  blogUrl?: string;
}

const BlogAside: FC<BlogAsideProps> = ({
  sidebarTitles,
  blogTitle = '',
  blogUrl = '',
}) => {
  const [activeItem, setActiveItem] = useState<string | null>(
    sidebarTitles?.[0]?.id,
  );

  // social share triggers
  const handleSocialClick = (social: string) => {
    if (social.trim() === 'twitter') {
      shareOnTwitter({ blogTitle, blogUrl });
    } else {
      shareOnLinkedIn({ blogUrl });
    }
  };

  // title scroll trigger
  const handleClick = (id: string) => {
    setActiveItem(id);
    const targetElement = document.getElementById(id);

    if (targetElement) {
      console.log('Scrolling to element with ID:', id);
      window.scrollTo({
        top: targetElement.offsetTop - 100,
        behavior: 'smooth',
      });
    } else {
      console.error('Target element not found:', id);
    }
  };

  return (
    <aside className="flex flex-col gap-10 self-start lg:w-[25%] w-full">
      <div
        className="p-5 bg-[#E9EAEB] flex flex-col gap-2.5 rounded-sm "
        style={{
          backgroundImage: `url(${VectorSvg})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <h4 className="text-h3-text-subtitle text-[#252B37] secondary-font">
          Share with your community!
        </h4>
        <div className="flex items-center gap-5">
          <div
            onClick={() => handleSocialClick('linkedin')}
            className="cursor-pointer"
          >
            <Linkedin />
          </div>
          <div
            onClick={() => handleSocialClick('twitter')}
            className="cursor-pointer"
          >
            <Twitter />
          </div>
        </div>
      </div>

      {/* In this article */}
      {sidebarTitles?.length > 0 && (
        <div className="flex-col gap-5 lg:flex hidden">
          <h4 className="text-h1-text-subtitle font-semibold secondary-font">
            In this article
          </h4>
          <ul className="space-y-3 text-gray-600">
            {sidebarTitles.map((val) => (
              <li
                key={val.id}
                className={`cursor-pointer border-l-[3px] text-h3-text pl-4 transition-colors ${
                  activeItem === val.id
                    ? 'border-[#207C97] text-[#207C97] text-h3-text-subtitle font-bold'
                    : 'border-transparent text-gray-700'
                }`}
                onClick={() => handleClick(val.id)}
              >
                {val.title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </aside>
  );
};

export default BlogAside;
