import { FC } from 'react';
import { CommonCardDataType } from '../../../types/types';
import CXOCards from './CXOCards';

interface CXOCommonSectionProps {
  data: CommonCardDataType[];
  title: string;
  description: string;
  background: string;
  hideImg?: boolean;
}

const CXOCommonSection: FC<CXOCommonSectionProps> = ({
  data,
  title,
  description,
  background,
  hideImg = false,
}) => {
  return (
    <div
      className={` ${background === 'white' ? 'bg-white' : 'bg-background-light'}  border-b border-b-primary lg:py-20 py-14 lg:px-[108px] px-4 flex flex-col gap-14`}
    >
      {/* heading */}
      <div className="flex flex-col gap-3 items-center justify-center">
        <h2 className="text-h2-text text-primary-text-black lg:text-center">
          {title}
        </h2>
        <p className="text-tertiary-text text-lg-text font-normal">
          {description}
        </p>
      </div>
      {/* cards section */}
      <div
        className={`${data?.length > 3 ? 'grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-6 w-full' : 'flex lg:flex-row gap-6 flex-col'}`}
      >
        {data?.map((val) => (
          <CXOCards
            data={val}
            key={val?.id}
            length={data?.length}
            hideImg={hideImg}
          />
        ))}
      </div>
    </div>
  );
};

export default CXOCommonSection;
