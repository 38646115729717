import axios from 'axios';
import { useEffect, useState } from 'react';
import { fetchBlogBySlug } from '../../../api/blogApi';
import { Blog } from '../../../types/types';

export const useGetBlogDetails = (slug: string | undefined) => {
  const [blog, setBlog] = useState<Blog | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sidebarTitles, setSidebarTitles] = useState<
    { title: string; id: string }[]
  >([]);

  // Fetch blog data logic
  useEffect(() => {
    if (!slug) {
      setLoading(false);
      setError('No slug provided');
      return;
    }

    const controller = new AbortController();
    setLoading(true);
    setError(null);

    const fetchData = async () => {
      try {
        const response = await fetchBlogBySlug(slug, {
          signal: controller.signal,
        });

        if (!response || !response.data) {
          throw new Error('No data received from server');
        }

        setBlog(response.data[0] || null);

        if (!response.data[0]) {
          setError('Blog not found');
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('Request canceled:', err.message);
        } else if (axios.isAxiosError(err)) {
          if (err.response) {
            setError(
              `Server error: ${err.response.status} - ${err.response.data?.message || err.message}`,
            );
          } else if (err.request) {
            setError('No response from server. Please check your connection');
          } else {
            setError(`Request configuration error: ${err.message}`);
          }
        } else {
          setError(
            `Error: ${(err as Error)?.message || 'An unknown error occurred'}`,
          );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [slug]);

  // Extract headings for sidebar with error handling
  useEffect(() => {
    if (!blog?.description?.trim()) return;

    try {
      // Use requestAnimationFrame to ensure DOM is ready
      requestAnimationFrame(() => {
        try {
          const contentContainer = document.querySelector('.blog-content-area');
          if (!contentContainer) {
            console.error('Could not find blog content container');
            return;
          }

          const headingElements =
            contentContainer.querySelectorAll('h1, h2, h3');
          if (headingElements.length === 0) {
            console.warn('No headings found in content');
            return;
          }

          const extractedTitles = Array.from(headingElements).map(
            (heading, index) => {
              const headingId = `blog-heading-${index}`;
              heading.setAttribute('id', headingId);
              return { title: heading.textContent || '', id: headingId };
            },
          );

          setSidebarTitles(extractedTitles);
        } catch (domErr) {
          console.error('Error processing DOM elements:', domErr);
        }
      });
    } catch (animFrameErr) {
      console.error('Error in requestAnimationFrame:', animFrameErr);
    }
  }, [blog?.description]);

  return { blog, loading, error, sidebarTitles };
};
