import { ArrowRight20Filled } from '@fluentui/react-icons';
import { FC } from 'react';
import { Blog, WhitePaper } from '../../types/types';
import { TextTrim, formatDate } from '../helpers/functions';

interface BlogCardProps {
  blog: Blog | WhitePaper;
}

const BlogCard: FC<BlogCardProps> = ({ blog }) => {
  return (
    <div className="p-4 rounded flex flex-col gap-6 cursor-pointer bg-white hover:shadow-[0_0_10px_rgba(0,0,0,0.1)] ease-in-out duration-500">
      <div className="relative h-72 rounded-sm overflow-hidden">
        {/* Blog Image */}
        <img
          src={`https://strapi.salesworx.ai${blog?.thumbnailImg?.formats?.small?.url}`}
          alt={blog.title}
          className="w-full h-full object-cover rounded-sm"
        />
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-blog-card-gradient"></div>
        {/* Centered title */}
        <h2
          className="absolute inset-0 flex items-center justify-center
                         text-white text-lg-text secondary-font px-7 py-4 text-start w-[50%]"
        >
          {blog.title}
        </h2>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <h4 className="text-h3-text font-medium text-tertiary-text">
            {TextTrim({ text: blog?.author?.name, Textlength: 40 })} •{' '}
            {formatDate({ blogDate: blog?.createdAt })}
          </h4>
          <p className="text-h3-text text-text-p4 h-12">
            {TextTrim({ text: blog?.shortDescription, Textlength: 130 })}
          </p>
        </div>
        {/* read more */}
        <div className="text-h3-text text-primary-accent flex gap-2 items-center">
          <span>View Details</span>
          <ArrowRight20Filled />
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
