import { FC } from 'react';
import { CommonLayout } from '../components/Layouts/CommonLayout';
import Starter from '../components/WhitePaper/Starter';

const WhitePaper: FC = () => {
  return (
    <CommonLayout calendlyWidget={false}>
      <div className="bg-primary-light">
        <Starter />
      </div>
    </CommonLayout>
  );
};

export default WhitePaper;
