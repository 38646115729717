// resource pages links

import {
  CalculatorRegular,
  CreditCardPersonRegular,
  EmojiSparkleRegular,
  PeopleRegular,
  TaskListSquareLtrRegular,
} from '@fluentui/react-icons';
import FlashSpark from './FlashSpark';

export const ResourcePages = [
  {
    title: 'Blog',
    description: 'The latest industry news, updates and info.',
    url: '/blog',
    icon: <FlashSpark />,
  },
  {
    title: 'White Papers',
    description: 'Learn how our customers are making big changes.',
    url: '/whitepaper',
    icon: <FlashSpark />,
  },
];

//   products pages links

export const ProductPages = [
  {
    title: 'AI Predictions & Insights',
    description:
      'Unlock the potential of your data with AI-driven predictions.',
    url: '/ai-prediction-and-insight',
    icon: <FlashSpark />,
  },
  {
    title: 'Project Management',
    description: 'Streamline your projects from start to finish.',
    url: '/project-management',
    icon: <TaskListSquareLtrRegular fontSize={20} />,
  },
  {
    title: 'Financial Management',
    description:
      'Manage budgets and expenses efficiently to keep projects on track.',
    url: '/financial-management',
    icon: <CalculatorRegular fontSize={20} />,
  },
  {
    title: 'Resource Management',
    description:
      'Optimize your resource allocation with real-time tracking and forecasting.',
    url: '/resource-management',
    icon: <PeopleRegular fontSize={20} />,
  },
  {
    title: 'Client Management',
    description:
      'Streamline client interactions, feedback and ensure project satisfaction.',
    url: '/client-management',
    icon: <EmojiSparkleRegular fontSize={20} />,
  },
  {
    title: 'Employee Management',
    description:
      'Track employee performance, leave, timesheet and development seamlessly.',
    url: '/employee-management',
    icon: <CreditCardPersonRegular fontSize={20} />,
  },
];

// footer links

export const FooterData = [
  {
    title: 'PRODUCTS',
    links: [
      {
        title: 'AI Predictions',
        link: '/ai-prediction-and-insight',
      },
      {
        title: 'Project Management',
        link: '/project-management',
      },
      {
        title: 'Resource Management',
        link: '/resource-management',
      },
      {
        title: 'Financial Management',
        link: '/financial-management  ',
      },
      {
        title: 'Client Management',
        link: '/client-management',
      },
      {
        title: 'Employee Management',
        link: '/employee-management',
      },
    ],
  },
  {
    title: 'USE CASES',
    links: [
      {
        title: 'IT Services',
        link: '#',
      },
      {
        title: 'Marketing Services',
        link: '#',
      },
      {
        title: 'Fintech',
        link: '#',
      },
      {
        title: 'SAAS',
        link: '#',
      },
      {
        title: 'Consulting Services',
        link: '#',
      },
      {
        title: 'Construction Services',
        link: '#',
      },
      {
        title: 'Legal  & Accounting',
        link: '#',
      },
    ],
  },
  {
    title: 'COMPANY',
    links: [
      {
        title: 'About Us',
        link: '#',
      },
      {
        title: 'Careers',
        link: '#',
      },
      {
        title: 'Blog',
        link: '/blog',
      },
      {
        title: 'Become a Partner',
        link: '#',
      },
      {
        title: 'FAQs',
        link: '#FAQ',
      },
    ],
  },
  {
    title: 'QUICK LINKS',
    links: [
      {
        title: 'Pricing',
        link: '/pricing',
      },
      {
        title: 'Contact us',
        link: '/contact-us',
      },
      {
        title: 'Download App',
        link: '#',
      },
    ],
  },
];
