import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { AccordionItemProps, AccordionProps } from '../../types/types';

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  content,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="border-b border-gray-200">
      <div
        className="flex justify-between items-center py-4 px-2 cursor-pointer"
        onClick={onToggle}
      >
        <h3
          className={`${isOpen ? 'text-primary-text text-lg-text' : ' font-normal text-tertiary-text text-lg-text'} `}
        >
          {title}
        </h3>
        <motion.span
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          className={`${isOpen ? 'text-primary-text' : 'text-[#CCC]'} font-medium text-lg-text`}
        >
          {isOpen ? '-' : '+'}
        </motion.span>
      </div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className=" text-h3-text text-primary-text px-2 pb-2">
              {content}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Accordion: React.FC<AccordionProps> = ({ items, customGap }) => {
  const [openItems, setOpenItems] = useState<string[]>([items[0].id]);

  const handleToggle = (id: string) => {
    setOpenItems((prev) =>
      prev.includes(id)
        ? prev.filter((itemId) => itemId !== id)
        : [...prev, id],
    );
  };

  return (
    <div className="mx-auto bg-transparent">
      {items.map((item) => (
        <div className={`flex flex-col ${customGap ? customGap : 'gap-4'}`}>
          <AccordionItem
            key={item.id}
            title={item.title}
            content={item.content}
            isOpen={openItems.includes(item.id)}
            onToggle={() => handleToggle(item.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default Accordion;
