import { FC } from 'react';
import { CommonDataType } from '../../../types/types';
import WhiteBgButton from '../../Button/WhiteBgButton';
import SectionHeader from '../../CommonComponent/SectionHeader';
import { BookADemo } from '../../Home/Utils';
import AIBGIMAGE from '../../Home/assets/AIBGImage.jpg';
import AI_FINDER_TOOL_IMG from '../Assets/Finder_Tool.png';
import RobustCard from './RobustCard';
interface CapacitySectionProps {
  data: CommonDataType[];
  title: string;
  description: string;
  isImage?: boolean;
}

const CapacitySection: FC<CapacitySectionProps> = ({
  data,
  title,
  description,
  isImage = false,
}) => {
  return (
    <div
      className={`lg:py-20 lg:px-[108px] py-14 px-4 ${isImage && 'flex gap-14 items-start lg:flex-row flex-col'} w-full h-full`}
      style={{
        background: `linear-gradient(180deg, rgba(26, 26, 26, 0.95) -31.4%, rgba(15, 124, 161, 0.95) 412.25%), url(${AIBGIMAGE})`,
        backgroundColor: 'lightgray',
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '400px',
      }}
    >
      <div className={`${isImage && 'lg:w-[48%] w-full'} w-full h-full`}>
        <SectionHeader
          title={title}
          description={description}
          variant="white"
          whiteDescription
          textCenter={!isImage}
          mobileTextCenter
        />
        <div
          className={`${!isImage && 'lg:px-6'} py-14 flex lg:flex-row flex-col gap-6  w-full`}
        >
          {data?.map((value, index) => (
            <RobustCard value={value} index={index} data={data} />
          ))}
        </div>
        {/* button */}
        <div
          className={`flex ${!isImage ? 'items-center justify-center' : 'max-lg:items-center max-lg:justify-center'}`}
        >
          <WhiteBgButton text="Get started" handleClick={BookADemo} expanded />
        </div>
      </div>
      {isImage && (
        <div className="lg:w-[52%] w-full h-fit">
          <img
            src={AI_FINDER_TOOL_IMG}
            alt={`${title}_img`}
            className=" w-full h-[550px]"
          />
        </div>
      )}
    </div>
  );
};

export default CapacitySection;
