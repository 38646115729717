import {
  IMessageBarProps,
  IMessageBarStyles,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { ReactNode } from 'react';

// Import filled icons
import {
  CheckmarkCircle20Filled,
  ErrorCircle20Filled,
  Info20Filled,
  Warning20Filled,
} from '@fluentui/react-icons';

export interface NotificationProps extends IMessageBarProps {
  children?: ReactNode;
  onDismiss: () => void;
}

export const Notification = ({
  children,
  onDismiss,
  messageBarType,
  ...props
}: NotificationProps) => {
  const iconClass = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
  });

  const getIcon = () => {
    switch (messageBarType) {
      case MessageBarType.success:
        return (
          <CheckmarkCircle20Filled className={iconClass} color="#2e7d32" />
        );
      case MessageBarType.error:
        return <ErrorCircle20Filled className={iconClass} color="#d32f2f" />;
      case MessageBarType.warning:
      case MessageBarType.severeWarning:
        return <Warning20Filled className={iconClass} color="#ed6c02" />;
      case MessageBarType.info:
        return <Info20Filled className={iconClass} color="#0288d1" />;
      default:
        return null;
    }
  };

  const getMessageBarStyles = (): IMessageBarStyles => {
    return {
      root: {
        width: 'auto',
        position: 'fixed',
        top: 20,
        border: '8px',
        right: '50%',
        transform: 'translateX(50%)',
        textAlign: 'center',
        zIndex: 9999999999,
        boxShadow:
          '0 6px 12px -2px rgba(0, 0, 0, 0.3), 0 3px 7px -3px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
      },
      text: {
        fontSize: 16,
      },
      content: {
        alignItems: 'center',
      },
      iconContainer: {
        display: 'none', // Hide the default icon
      },
    };
  };

  return (
    <MessageBar
      className="shadow-md"
      styles={getMessageBarStyles()}
      {...props}
      onDismiss={onDismiss}
      messageBarType={messageBarType}
    >
      {getIcon()}
      {children}
    </MessageBar>
  );
};
