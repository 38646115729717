import AIINSIGHTIMAGE from './Assets/AIInsight.jpg';
import CapacitySection from './CommonCompoenents/CapacitySection';
import CommonHeaderProducts from './CommonCompoenents/CommonHeaderProducts';
import CustomAnalysisSection from './CommonCompoenents/CustomAnalysisSection';
import CXOCommonSection from './CommonCompoenents/CXOCommonSection';
import ProductivitySection from './CommonCompoenents/ProductivitySection';
import SidebarWithImage from './CommonCompoenents/SidebarWithImage';
import {
  AIPREDICTION,
  AIPREDICTION_CXO_DATA,
  CUSTOM_ANALYSIS_DATA,
  EMPLOYEE_CAPACITY_MANAGEMENT,
  EMPLOYEE_PRODUCTIVITY_DATA,
  EMPLOYEE_REPORT_DATA,
  PREDICTIVE_INSIGHT_DATA,
  RESULTCOUNTSAIINSIGHT,
  STEPSDATAAIINSIGHT,
} from './utils';

const AIPredictionAndInsights = () => {
  return (
    <div>
      <CommonHeaderProducts
        StepsData={STEPSDATAAIINSIGHT}
        ResultCounts={RESULTCOUNTSAIINSIGHT}
        Header={AIPREDICTION}
        image={AIINSIGHTIMAGE}
      />
      <CXOCommonSection
        data={AIPREDICTION_CXO_DATA}
        title="Strategic insights for CXOs"
        description="Empower leadership with data-driven insights."
        background="light-white"
      />
      <CXOCommonSection
        data={PREDICTIVE_INSIGHT_DATA}
        title="Predictive insights to drive proactive actions"
        description="Transform data into forward-thinking strategies."
        background="white"
      />

      <SidebarWithImage
        title="Employee-level report cards"
        description="Empower employees with personalized performance insights."
        data={EMPLOYEE_REPORT_DATA}
      />
      <CapacitySection
        data={EMPLOYEE_CAPACITY_MANAGEMENT}
        title="Manage employee capacity effectively"
        description="Optimize workload distribution and resource planning."
      />
      <ProductivitySection
        data={EMPLOYEE_PRODUCTIVITY_DATA}
        title={'Boost productivity with data-driven insights'}
        description={'Turn data into actionable productivity enhancements.'}
      />
      <CustomAnalysisSection
        data={CUSTOM_ANALYSIS_DATA}
        title="Robust reporting and custom analytics"
        description="Make data-driven decisions with ease."
      />
    </div>
  );
};

export default AIPredictionAndInsights;
