import { CheckmarkCircleRegular } from '@fluentui/react-icons';
import { useBoolean } from 'ahooks';
import { FC } from 'react';
import {
  AiPredictionHeader,
  ResultCountDataType,
  stepsDataType,
} from '../../../types/types';
import PrimaryButton from '../../Button/PrimaryButton';
import SecondaryButton from '../../Button/SecondaryButton';
import DownloadApp from '../../Home/DownloadApp';
import { BookADemo } from '../../Home/Utils';
import GradientBorderSectionTitle from '../../SquadPricingPlans/GradientBorderSectionTitle';

interface CommonHeaderProductsProps {
  StepsData?: stepsDataType[];
  ResultCounts: ResultCountDataType[];
  Header: AiPredictionHeader;
  image: string;
}

const CommonHeaderProducts: FC<CommonHeaderProductsProps> = ({
  StepsData,
  ResultCounts,
  Header,
  image,
}) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  return (
    <section className=" bg-background-dark lg:py-20 py-14 lg:px-[108px] px-4 flex flex-col gap-20">
      {/* first */}
      <div className="flex flex-col gap-8 items-center justify-center">
        <div className="flex flex-col gap-6 items-center justify-center">
          <div className="w-fit">
            <GradientBorderSectionTitle title={Header?.header} />
          </div>
          <h1 className="text-white lg:text-h1-text text-h2-text text-center lg:w-[70%] w-full">
            {Header?.title}
          </h1>
          <p className="text-lg-text font-normal text-text-p4 lg:w-[60%] w-full text-center">
            {Header?.description}
          </p>
        </div>

        {/* buttons */}
        <div className="flex items-center justify-center gap-6 w-full lg:flex-row flex-col">
          <div>
            <SecondaryButton
              text={'Download Squad for free'}
              handleClick={showModal}
              expanded
            />
            <DownloadApp
              showModal={showModal}
              isModalOpen={isModalOpen}
              hideModal={hideModal}
            />
          </div>
          <PrimaryButton text="Book A Demo" handleClick={BookADemo} expanded />
        </div>
      </div>

      {/* second */}
      <div className="">
        {/* <img src={LeftSideImage} alt='left_side_img' className=' absolute' /> */}

        <div className="flex flex-col gap-12">
          {/* steps */}
          {StepsData && (
            <div className="flex gap-2 items-center justify-center">
              {StepsData?.map((val) => (
                <div className="flex gap-2 items-center" key={val?.id}>
                  <CheckmarkCircleRegular color="#185D74" fontSize={24} />
                  <p className=" text-white text-lg-text font-medium capitalize px-2">
                    {val?.title}
                  </p>
                </div>
              ))}
            </div>
          )}
          {/* image */}
          <div className="rounded w-full">
            <img src={image} alt="AI_insight_img" className="rounded w-full" />
          </div>
        </div>
      </div>

      {/* third */}
      <div className="flex lg:gap-14 gap-10 w-full lg:flex-row flex-col">
        <h2 className="lg:w-[25%] w-full text-white text-h2-text">
          Results you can count on
        </h2>
        <div className="flex lg:w-[75%] w-full max-lg:gap-5">
          {ResultCounts?.map((val) => (
            <div
              className="flex flex-col gap-3 lg:px-8  py-3 w-1/3"
              key={val?.id}
            >
              <h4 className=" text-h2-text text-text-p4">{val?.title}</h4>
              <p className="text-text-p4 text-h3-text">{val?.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CommonHeaderProducts;
