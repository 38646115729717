import { DefaultButton } from '@fluentui/react';
import { FC } from 'react';

interface WhiteBgButtonProps {
  text: string;
  handleClick: () => void;
  expanded?: boolean;
  border?: boolean;
}

const WhiteBgButton: FC<WhiteBgButtonProps> = ({
  text,
  handleClick,
  expanded = false,
  border = true,
}) => {
  return (
    <DefaultButton
      text={text}
      onClick={() => handleClick()}
      className={`border  rounded-sm ${expanded ? 'px-6 py-6 text-h3-text ' : 'px-4 py-4 text-h4-text'} font-medium bg-white w-fit  ${border && 'border-tertiary-text'} hover:bg-[#FF9900]
               ease-in-out duration-500 text-primary-text hover:text-primary-text`}
    ></DefaultButton>
  );
};

export default WhiteBgButton;
