import { StackItem } from '@fluentui/react';
import { useBoolean } from 'ahooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DownloadApp from '../Home/DownloadApp';
import SquadLogo from '../Home/assets/SquadWhiteLogo.svg';
import { FooterData } from './utils';
export function Footer() {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const navigate = useNavigate();
  const url = useLocation();
  const date = new Date();
  return (
    <div className="bg-background-dark lg:px-[108px] px-12">
      <StackItem className="flex justify-between lg:flex-row flex-col gap-14 pb-9 pt-[52px]  text-h4-text-subtitle text-[#CCCCCC]">
        <div className="lg:w-[10%] w-full">
          <img src={SquadLogo} alt="squad-white-logo" />
        </div>
        <div className="lg:w-[90%] w-full">
          <div className="flex lg:flex-row  flex-col gap-16 w-full">
            {FooterData?.map((val) => (
              <div className="lg:w-[25%] w-full">
                <h4 className="text-h4-text font-semibold text-seperator capitalize">
                  {val?.title}
                </h4>
                <hr
                  color="#4F4F4F"
                  className="opacity-20 my-4 max-sm:w-[50%]"
                />
                <div className="flex flex-col gap-6">
                  {val?.links?.map((link, index) => (
                    <div>
                      {link?.title === 'Download App' ? (
                        <div>
                          <button
                            className="relative text-h4-text text-text-p4 cursor-pointer w-fit hover:text-[#FF9900]
                   after:absolute after:left-0 after:bottom-[-2px] after:w-0 after:h-[2px] after:bg-[#FF9900] 
                   hover:after:w-full after:transition-all after:duration-300 after:ease-in-out"
                            onClick={showModal}
                          >
                            {link.title}
                          </button>
                          <DownloadApp
                            showModal={showModal}
                            isModalOpen={isModalOpen}
                            hideModal={hideModal}
                          />
                        </div>
                      ) : (
                        <Link
                          to={link?.link}
                          key={index}
                          className="relative text-h4-text text-text-p4 cursor-pointer w-fit hover:text-[#FF9900]
                   after:absolute after:left-0 after:bottom-[-2px] after:w-0 after:h-[2px] after:bg-[#FF9900] 
                   hover:after:w-full after:transition-all after:duration-300 after:ease-in-out"
                        >
                          {link.title}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </StackItem>
      <StackItem className="py-10" style={{ borderTop: '1px solid #4F4F4F' }}>
        <div className="flex w-full justify-between flex-col lg:flex-row max-lg:gap-10">
          <div className="lg:w-[13%] w-full text-text-p4 font-poppins">
            Privacy Policy
          </div>

          <div className="flex lg:justify-between lg:w-[87%] w-full flex-col lg:flex-row max-lg:gap-10">
            <div className="flex gap-6 w-full">
              <Link
                className="h-[25px] w-[25px] cursor-pointer"
                to="https://www.facebook.com/Worxwide"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Worxwide"
              >
                <img src="../../../assets/Facebook.png" alt="facebook" />
              </Link>
              <Link
                className="h-[25px] w-[25px] cursor-pointer"
                to="https://www.instagram.com/worxwide/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Worxwide"
              >
                <img src="../../../assets/Instagram.png" alt="instagram" />
              </Link>
              <Link
                className="h-[25px] w-[25px] cursor-pointer"
                to="https://www.linkedin.com/company/worxwide/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Worxwide"
              >
                <img src="../../../assets/LinkedIn.png" alt="linkedIn" />
              </Link>
              <Link
                className="h-[25px] w-[25px] cursor-pointer"
                to="https://twitter.com/worxwide"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Worxwide"
              >
                <img src="../../../assets/Twiter.png" alt="twiter" />
              </Link>
            </div>

            <StackItem className="text-text-p4">
              <span className="text-white font-poppins">©</span>{' '}
              {date?.getFullYear()} Worxwide
            </StackItem>
          </div>
        </div>
      </StackItem>
    </div>
  );
}
