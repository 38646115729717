import { motion } from 'framer-motion';
import { FC } from 'react';
import SecondaryButton from '../../Button/SecondaryButton';
import DownloadApp from '../../Home/DownloadApp';
import { ResourcePages } from '../utils';
import { CommonCard } from './CommonCard';

interface ResourceNavigationProps {
  setResourcesDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: () => void;
  hideModal: () => void;
  isModalOpen: boolean;
}

const ResourceNavigation: FC<ResourceNavigationProps> = ({
  setResourcesDropdown,
  showModal,
  hideModal,
  isModalOpen,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -200 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 200 }}
      transition={{ duration: 0.6, ease: 'easeInOut' }}
      className="top-[65px] absolute left-[calc(65vw-36rem)] right-4 mt-6 mx-4 ml-0 shadow-lg bg-white
                   w-[60%]"
      // onMouseEnter={() => setResourcesDropdown(true)}
      // onMouseLeave={() => setResourcesDropdown(false)}
    >
      <div className="flex justify-between">
        <div className="pr-12 py-8 pl-8 w-[50%]">
          <div className="flex gap-8 flex-col">
            {ResourcePages?.map((page) => <CommonCard page={page} />)}
          </div>
        </div>
        <div className="pl-16 pr-8 py-8 bg-gray-50 w-[50%]">
          <div className="p-3 rounded-lg flex flex-col gap-4 w-full">
            <div className="p-3 bg-primary-brand text-white h-[146px]">
              <div className="flex flex-col gap-1 pt-11">
                <p className=" text-xs font-medium secondary-text">Part 1</p>
                <p className="text-h3-text-subtitle font-medium pb-2">
                  How to setup <br /> Squad
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <h4 className="text-h3-text-subtitle text-[#252B37]">
                How to setup
              </h4>
              <div className="text-h4-text text-secondary-text pb-7">
                Jump right in — get an overview of the basics and get started on
                building straight away.
              </div>
              <div>
                <SecondaryButton
                  text={'Download App'}
                  handleClick={showModal}
                  expanded={true}
                  color="black"
                />
                <DownloadApp
                  showModal={showModal}
                  isModalOpen={isModalOpen}
                  hideModal={hideModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ResourceNavigation;
