import AssessmentImg from './assets/AssessmentImg.png';
import LearningImg from './assets/LearningImg.png';
import SkillImg from './assets/SkillImg.png';
import SkillMatrix from './assets/SkillMatrix.png';
import CardHeader from './CardHeader';

const UpSkillAndAssessment = () => {
  return (
    <section className=" lg:pr-[108Px] pr-4 lg:py-20 py-14 flex flex-col lg:gap-20 gap-10">
      <div className="flex  justify-between w-full lg:flex-row flex-col">
        <div className="lg:w-[30%] w-full flex flex-col gap-8">
          <CardHeader
            title=" Continual upskilling to boost productivity"
            header="EMPLOYEE MANAGEMENT"
            color="black"
          />
          <p className="text-lg-text font-normal text-secondary-text">
            Custom assessments, custom trainings, skill forecasting and skill
            reporting
          </p>
        </div>

        <div className="lg:w-[50%] w-full self-end">
          <img
            src={SkillMatrix}
            alt="SkillMatrix"
            className="h-[335px] rounded-lg"
          />
        </div>
      </div>

      {/* bottom images */}
      <div className="flex justify-between gap-9  flex-col lg:flex-row">
        <div className="lg:w-1/3 w-full bg-white rounded-lg">
          <img src={LearningImg} alt="LearningImg" className="w-full h-full" />
        </div>
        <div className="lg:w-1/3 w-full bg-white rounded-lg">
          <img src={SkillImg} alt="SkillImg" className="w-full h-full" />
        </div>
        <div className="lg:w-1/3 w-full rounded-lg">
          <img
            src={AssessmentImg}
            alt="AssessmentImg"
            className="w-full h-full"
          />
        </div>
      </div>
    </section>
  );
};

export default UpSkillAndAssessment;
