import { ChevronDownFilled } from '@fluentui/react-icons';
import { motion } from 'framer-motion';
import { useState } from 'react';
import SectionHeader from '../CommonComponent/SectionHeader';
import { item } from './Utils';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="lg:py-20 lg:px-[108px] py-14 px-4">
      <div className="flex flex-col items-center gap-12">
        <SectionHeader
          title="Frequently Asked Questions"
          description="Find the answers to common questions and learn how Worx Squad can transform your business operations"
          textCenter
          mobileTextCenter
        />
        <div className="w-full max-w-3xl">
          {item?.map((item, index) => (
            <div key={index} className="">
              <button
                className="w-full flex justify-between items-start py-4 text-left text-h3-text"
                onClick={() => toggleFAQ(index)}
              >
                <span
                  className={`${openIndex === index ? ' font-bold' : 'font-medium'} text-[#101010] `}
                >
                  {item.title}
                </span>
                <motion.div
                  animate={{ rotate: openIndex === index ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ChevronDownFilled fontSize={24} />
                </motion.div>
              </button>
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={
                  openIndex === index
                    ? { height: 'auto', opacity: 1 }
                    : { height: 0, opacity: 0 }
                }
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                className="overflow-hidden flex flex-col gap-4"
              >
                {/* Animated Line */}
                <motion.div
                  initial={{ width: 0 }}
                  animate={
                    openIndex === index ? { width: '100%' } : { width: 0 }
                  }
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  className="bg-primary-accent h-[1.5px]"
                />
                <p className="pb-4 text-base">{item.content}</p>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
