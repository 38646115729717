import { useNavigate, useParams } from 'react-router-dom';
import BlogViewShare from './BlogViewShare';
import BottomShareArea from './BottomShareArea';
import HeroSection from './HeroSection';
import BlogAside from './NewBlogAside';
import StrapiContentRenderer from './StrapiContentRenderer';
import { useGetBlogDetails } from './hooks/useGetBlogDeatils';

const BlogDetailContent = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  // Custom hook to fetch data
  const { blog, loading, error, sidebarTitles } = useGetBlogDetails(slug);

  // Return null if loading because root-level loader is already added
  if (loading) return null;

  // Show error in wrong slug or if error occurs while fetching
  if (error || (!blog && !loading))
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="text-center p-6 max-w-md bg-white rounded-lg shadow-sm">
          <div className="text-red-500 text-lg-text mb-2">
            No Content Found. Refresh or Retry Again
          </div>
          <p className="text-gray-700 mb-4">{error}</p>
          <button
            onClick={() => navigate('/blog')}
            className="px-4 py-2 bg-primary-accent text-white rounded-md hover:bg-primary-accent/90 transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );

  return (
    <section className="lg:px-[108px] px-4">
      {/* SEO Meta Tags */}

      {/* Hero Section */}
      <div className="flex flex-col w-full gap-9 lg:pb-20 pb-14">
        <HeroSection
          bannerMedia={`https://strapi.salesworx.ai${blog?.coverImg?.url}`}
          bannerTitle={blog?.title ?? ''}
          ReadMinutes={`${blog?.readTimeInMinutes ?? '0'}`}
          date={blog?.createdAt ?? ''}
        />
        <div className="flex lg:justify-between lg:flex-row flex-col w-full">
          {/* Aside */}
          <BlogAside
            sidebarTitles={sidebarTitles}
            blogTitle={blog?.title}
            blogUrl={`https://worxsquad.com/blog/${blog?.slug}`}
          />

          {/* Blog content area */}
          <div className="flex flex-col lg:w-[65%] w-full">
            <BlogViewShare />
            <div className="blog-content-area">
              <StrapiContentRenderer content={blog?.description ?? ''} />
            </div>
            <BottomShareArea
              blogTitle={blog?.title}
              blogUrl={`https://worxsquad.com/blog/${blog?.slug}`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailContent;
