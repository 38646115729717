import { WhySquad } from '../CommonComponent/WhySquad';
import { AIPoweredProjectManagement } from '../Home/Utils';
import Project_Management_Img from './Assets/Project_management.jpg';
import CXOCommonSection from './CommonCompoenents/CXOCommonSection';
import CapacitySection from './CommonCompoenents/CapacitySection';
import CommonHeaderProducts from './CommonCompoenents/CommonHeaderProducts';
import CustomAnalysisSection from './CommonCompoenents/CustomAnalysisSection';
import IntegrationCapability from './CommonCompoenents/IntegrationCapability';
import ProductivitySection from './CommonCompoenents/ProductivitySection';
import SidebarWithImage from './CommonCompoenents/SidebarWithImage';
import {
  CUSTOMIZABLE_WORKFLOW_DATA,
  INTEGRATION_CAPABILITIES_DATA,
  PROJECT_MANAGEMENT,
  PROJECT_MANAGEMENT_COMPREHENSIVE,
  RESOURCE_MANAGEMENT_DATA,
  RESULT_COUNT_PROJECT_MANAGEMENT,
  ROBUST_REPORTING_DATA,
  SECURITY_AND_COMPILANCE_DATA,
  STEPS_DATA_PROJECT_MANAGEMENT,
  TEAM_COLLABORATION,
  WHY_SQUAD,
} from './utils';

const ProjectManagementProduct = () => {
  return (
    <div>
      <CommonHeaderProducts
        StepsData={STEPS_DATA_PROJECT_MANAGEMENT}
        ResultCounts={RESULT_COUNT_PROJECT_MANAGEMENT}
        Header={PROJECT_MANAGEMENT}
        image={Project_Management_Img}
      />
      <CXOCommonSection
        data={PROJECT_MANAGEMENT_COMPREHENSIVE}
        title="Comprehensive project planning"
        description="Plan, organise, and execute projects with precision"
        background="light-white"
      />
      <CXOCommonSection
        data={TEAM_COLLABORATION}
        title="Seamless team collboration"
        description="Enhance team communication and collaboration"
        background="white"
      />
      <SidebarWithImage
        title="Advanced resource management"
        description="Optimise resource allocation and utilization"
        data={RESOURCE_MANAGEMENT_DATA}
      />
      <ProductivitySection
        data={CUSTOMIZABLE_WORKFLOW_DATA}
        title={'Customisable workflows'}
        description={'Adapt Squad to fit your unique project processes.'}
      />
      <CapacitySection
        data={AIPoweredProjectManagement}
        title="AI-Powered Project Management"
        description="Harness the Power of AI for Smarter Project Management"
      />
      <SidebarWithImage
        title="Robust reporting and analytics"
        description="Gain insights to drive informed decisions."
        data={ROBUST_REPORTING_DATA}
      />
      <IntegrationCapability
        data={INTEGRATION_CAPABILITIES_DATA}
        title={'Integration Capabilities'}
        description={
          'Connect Squad with your existing tools for a seamless workflow.'
        }
      />
      <CustomAnalysisSection
        data={SECURITY_AND_COMPILANCE_DATA}
        title="Security and Compliance"
        description="Protect your data with enterprise-grade security measures"
      />
      <WhySquad
        title="Why Squad?"
        description="Discover additional tools that make project management faster, smarter, and simpler."
        textCenter={false}
        data={WHY_SQUAD}
      />
    </div>
  );
};

export default ProjectManagementProduct;
